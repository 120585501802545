import React from 'react'
import { push } from 'connected-react-router'
import _ from 'lodash'
import { Path } from 'path-parser'
import Media from 'react-media'

import Dashboard from './containers/home'

import UploadLeadset from './containers/department/leadset'
import Departments from './containers/department/list'
import LeadsetData from './containers/department/leadsetData'
import AddNumbers from './containers/numbers/CompanyNo/addNumber'
// import Leadset from './containers/app/leadset'

import Numbers from './containers/numbers/list'
import NumbersList from './containers/numbers/CompanyNo/listNumbers'
import DIDNumbers from './containers/numbers/DIDNumber/listDidNumbers'

import Users from './containers/users/list'
import AddUsers from './containers/users/add'

import Department from './containers/department/deptList'
import DeptLeadset from './containers/department/deptLeadset'
import AddDepartment from './containers/department/add'

import Disposition from './containers/department/disposition/list'
import WorkingHours from './containers/department/WorkingHours'
// import AddDisposition from './containers/department/disposition/add'

import CallHistory from './containers/callHistory/list'
import PowerDailer from './containers/powerDailer/list'
import VoiceBroadcast from './containers/voiceBroadcast/list'
import CallScript from './containers/callScript/list'
import Contacts from './containers/contacts/list'

import Deptname from './containers/department/deptname'

import Settings from './containers/settings/list'
import YourPlan from './containers/settings/YourPlan'
import PriceList from './containers/settings/PriceList'
import IVR from './containers/settings/Ivr'
import Dnc from './containers/contacts/DNC/Dnc'
import CallQueue from './containers/settings/CallQueue'
import RestrictedContacts from './containers/contacts/restrictedcontacts/RestrictedContacts'
import RestrictedAreas from './containers/settings/RestrictedAreas'
import BillingContacts from './containers/settings/BillingContacts'
import BlockedCountries from './containers/settings/BlockedCountries'
import Holidays from './containers/settings/Holidays'
import Upgradeplan from './containers/settings/upgradePlan'
import ChangePassword from './containers/settings/changePassword'
import Profile from './containers/settings/Profile'
import PlanDetails from './containers/settings/YourPlanDetails'

import Reports from './containers/reports/list'
import ReportsList from './containers/reports/report'
// import Report from './containers/report'
import ScheduledCalls from './containers/scheduledCalls/list'
import History from './containers/callHistory/history'
import AgentSummary from './containers/reports/agentscallSummary'
import DailyCallSummary from './containers/reports/dailyCalls'
import Recordings from './containers/reports/recording'
import DispositionReport from './containers/reports/callerDisposition'
import PaymentHistory from './containers/reports/paymentsSummery'
import CallerJobTime from './containers/reports/callerJobTime'
import RealTimeScreen from './containers/reports/realTimeScreen'
import DIDCDR from './containers/reports/didCdr'

import { AddCreditPlan } from './containers/settings/YourPlan'

import Plans from './containers/plans'



import { ReactComponent as Number } from './assets/Number.svg'
import { ReactComponent as HomeIcon } from './assets/homeIcon.svg'
import { ReactComponent as User } from './assets/user.svg'
import { ReactComponent as Group } from './assets/Department-Icon.svg'
import { ReactComponent as Activity } from './assets/activity.svg'
import { ReactComponent as ReportsSvg } from './assets/reports.svg'
// import { ReactComponent as Phone } from './assets/sscheduled.svg'
import { ReactComponent as Script } from './assets/script.svg'
import { ReactComponent as SettingsSvg } from './assets/settings.svg'
import { ReactComponent as ContactsSvg } from './assets/contacts.svg'
import { ReactComponent as Phone } from './assets/sscheduled.svg'



import {
  FieldTimeOutlined, AudioOutlined, ProfileOutlined, MenuUnfoldOutlined, CreditCardOutlined, MenuFoldOutlined, DatabaseOutlined, CloudUploadOutlined, CloseCircleOutlined, ReconciliationOutlined, PhoneOutlined, DollarOutlined, ContactsOutlined, WalletOutlined, QrcodeOutlined, TagOutlined, HomeOutlined, EyeInvisibleOutlined, LayoutOutlined, LogoutOutlined, SettingOutlined, UserOutlined, SoundOutlined, BarChartOutlined, LineChartOutlined,ContainerOutlined
} from '@ant-design/icons';

const width = window.innerWidth;

let menu = [];

const menu1 = [


  {
    'path': '/dashboard',
    'name': 'Dashboard',
    'title': "Dashboard",
    'icon': <HomeIcon fill="black" />,
    'key': 'home',
    'component': <Dashboard />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/numbers',
    'name': 'Numbers',
    'title': "Numbers",
    'icon': <Number />,
    'key': 'Numbers',
    'component': <Numbers />,
    'authority': [
      'user'
    ],

  },
  {
    'path': '/addNumbers',
    'name': 'Add Numbers',
    'title': "Add Numbers",
    'dontShowOnMenu': true,
    'icon': <Number />,
    'key': 'addNumbers',
    'component': <AddNumbers />,
    'authority': [
      'user'
    ]
  },
  // {
  //   'path': '/adddid',
  //   'name': 'Add DID',
  //   'title': "Add DID",
  //   'icon': <Number />,
  //   "dontShowOnMenu": true,
  //   'key': 'adddid',
  //   'component': <AddDID />,
  //   'authority': [
  //     'user'
  //   ]
  // },
  // {
  //   'path': '/editDID/:id',
  //   'name': 'Edit DID',
  //   'title': "Edit DID",
  //   'icon': <Group />,
  //   "dontShowOnMenu": true,
  //   'key': 'editDID',
  //   'component': <AddDID />,
  //   'authority': [
  //     'user'
  //   ]
  // },
  {
    'path': '/department',
    'name': 'Department',
    'title': "Department",
    'icon': <Group />,
    'key': 'Department',
    'component': <Department />,
    'authority': [
      'user'
    ],
  },
  {
    'path': '/adddepartment',
    'name': 'Add Department',
    'title': "Add Department",
    'icon': <Group />,
    "dontShowOnMenu": true,
    'key': 'addDepartment',
    'component': <AddDepartment />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/editdepartment/:id/',
    'name': 'Edit Department',
    'title': "Edit Department",
    'icon': <Group />,
    "dontShowOnMenu": true,
    'key': 'editDepartment',
    'component': <AddDepartment />,
    'authority': [
      'user'
    ]
  },
  // {
  //   'path': '/disposition',
  //   'name': 'Disposition',
  //   'title': "Disposition",
  //   'icon': <Group />,
  //   'key': 'Disposition',
  //   'component': <Disposition />,
  //   'authority': [
  //     'user'
  //   ],
  // },
  // {
  //   'path': '/adddisposition',
  //   'name': 'Add Disposition',
  //   'title': "Add Disposition",
  //   'icon': <Group />,
  //   "dontShowOnMenu": true,
  //   'key': 'adddisposition',
  //   'component': <AddDisposition />,
  //   'authority': [
  //     'user'
  //   ]
  // },
  // {
  //   'path': '/editdisposition/:id',
  //   'name': 'Edit Disposition',
  //   'title': "Edit Disposition",
  //   'icon': <Group />,
  //   "dontShowOnMenu": true,
  //   'key': 'editdisposition',
  //   'component': <AddDisposition />,
  //   'authority': [
  //     'user'
  //   ]
  // },
  {
    'path': '/users',
    'name': 'Users',
    'title': "Users",
    'icon': <User />,
    'key': 'Users',
    'component': <Users />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/addUser',
    'name': 'Add User',
    'title': "Add User",
    'icon': <Group />,
    "dontShowOnMenu": true,
    'key': 'addUser',
    'component': <AddUsers />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/editUser/:id',
    'name': 'Edit User',
    'title': "Edit User",
    'icon': <Group />,
    "dontShowOnMenu": true,
    'key': 'editUser',
    'component': <AddUsers />,
    'authority': [
      'user'
    ]
  },


  // {
  //   'path': '/powerDailer',
  //   'name': 'Power Dailer',
  //   'title':"Power Dailer Campaigns",
  //   'icon': <Thunderbolt />,
  //   'key': 'PowerDailer',
  //   'component': <PowerDailer />,
  //   'authority': [
  //     'user'
  //   ]
  // },
  // {
  //   'path': '/voiceBroadcast',
  //   'name': 'Voice Broadcast',
  //   'title':"Voice Broadcast Campaigns",
  //   'icon': <Voice />,
  //   'key': 'VoiceBroadcast',
  //   'component': <VoiceBroadcast />,
  //   'authority': [
  //     'user'
  //   ]
  // },
  // {
  //   'path': '/callScript',
  //   'name': 'Call Script',
  //   'title': "Call Script",
  //   'icon': <Script />,
  //   'key': 'CallScript',
  //   'component': <CallScript />,
  //   'authority': [
  //     'user'
  //   ]
  // },
  {
    'path': '/Contacts',
    'name': 'Contacts',
    'title': "Contacts",
    'icon': <ContactsSvg />,
    'key': 'Contacts',
    'component': <Contacts />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/reports',
    'name': 'Reports',
    'title': "Reports",
    'icon': <ReportsSvg />,
    'key': 'Reports',
    'component': <Reports />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/settings',
    'name': 'Settings',
    'title': "Settings",
    'icon': <SettingsSvg />,
    'key': 'Settings',
    'component': <Settings />,
    'authority': [
      'user'
    ]
  },

  // {
  //   'path': '/scheduledCalls',
  //   'name': 'Scheduled Calls',
  //   'title': "Scheduled Calls",
  //   'icon': <Phone />,
  //   'key': 'ScheduledCalls',
  //   'component': <ScheduledCalls />,
  //   'authority': [
  //     'user'
  //   ]
  // },


  {
    'path': '/Plans',
    'name': 'Call Plans',
    'title': "Call Plans",
    "dontShowOnMenu": true,
    'icon': <Script />,
    'key': 'Plans',
    'component': <Plans />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/callHistory',
    'name': 'Call History',
    'title': " Reports",
    "dontShowOnMenu": true,
    'icon': <Activity />,
    'key': 'CallHistory',
    'component': <CallHistory />,
    'authority': [
      'user'
    ]
  },

  {

    'path': '/DeptLeadset',
    'name': 'DeptLeadset',
    'title': <Deptname />,
    "dontShowOnMenu": true,
    'icon': '',
    'key': 'deptLeadSet',
    'component': <DeptLeadset />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/addCredit',
    'name': 'Add Credit',
    'title': "Add Credit",
    'icon': <Group />,
    "dontShowOnMenu": true,
    'key': 'addCredit',
    'component': <AddCreditPlan />,
    'authority': [
      'user'
    ]
  },

  // {
  //   'path': '/Leadset',
  //   'name': 'Leadset',
  //   'title': "Leadset",
  //   'icon': '',
  //   'key': 'Leadset',
  //   'component': <Leadset />,
  //   'authority': [
  //     'user'
  //   ]
  // },
];



const menu2 = [


  {
    'path': '/dashboard',
    'name': 'Dashboard',
    'title': "Dashboard",
    'icon': <HomeIcon fill="black" />,
    'key': 'home',
    'component': <Dashboard />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/numbers',
    'name': 'Numbers',
    'title': "Numbers",
    'icon': <Number />,
    'key': 'Numbers',
    'authority': [
      'user'
    ],

    children: [
      {
        'path': '/numbers',
        'name': 'Numbers',
        'title': "Numbers",
        'icon': <ProfileOutlined style={{ fontSize: 20 }} />,
        'key': 'Numbers',
        'component': <NumbersList />,
        'authority': [
          'user'
        ]
      },
      {
        'path': '/externalDID',
        'name': 'External DID',
        'title': "External DID",
        'icon': <CreditCardOutlined style={{ fontSize: 20 }} />,
        "dontShowOnMenu": true,
        'key': 'extDID',
        'component': <DIDNumbers />,
        'authority': [
          'user'
        ]
      },
    ]

  },
  {
    'path': '/addNumbers',
    'name': 'Add Numbers',
    'title': "Add Numbers",
    'dontShowOnMenu': true,
    'icon': <Number />,
    'key': 'addNumbers',
    'component': <AddNumbers />,
    'authority': [
      'user'
    ]
  },
  // {
  //   'path': '/addDID',
  //   'name': 'Add DID',
  //   'title': "Add DID",
  //   'icon': <Group />,
  //   "dontShowOnMenu": true,
  //   'key': 'addDID',
  //   'component': <AddDID />,
  //   'authority': [
  //     'user'
  //   ]
  // },
  // {
  //   'path': '/editDID/:id',
  //   'name': 'Edit DID',
  //   'title': "Edit DID",
  //   'icon': <Group />,
  //   "dontShowOnMenu": true,
  //   'key': 'editDID',
  //   'component': <AddDID />,
  //   'authority': [
  //     'user'
  //   ]
  // },
  {
    'path': '/department',
    'name': 'Department',
    'title': "Department",
    'icon': <Group />,
    'key': 'Department',

    'authority': [
      'user'
    ],

    children: [
      {
        'path': '/department',
        'name': 'Department',
        'title': "Department",
        'icon': <CreditCardOutlined style={{ fontSize: 20 }} />,
        'key': 'Department',
        'component': <Departments />,
        'authority': [
          'user'
        ],
      },

      {
        'path': '/uploadLeadset/',
        'name': 'Upload Leadset',
        'title': "Upload Leadset",
        'icon': <CloudUploadOutlined style={{ fontSize: 20 }} />,
        'key': 'uploadLeadset',
        'component': <UploadLeadset />,
        'authority': [
          'user'
        ],
      },

      {
        'path': '/Leadset',
        'name': 'Leadset',
        'title': "Leadset",
        'icon': <DatabaseOutlined style={{ fontSize: 20 }} />,
        'key': 'Leadset',
        'component': <LeadsetData />,
        'authority': [
          'user'
        ],
      },

      {
        'path': '/disposition',
        'name': 'Disposition',
        'title': "Disposition",
        'icon': <Group />,
        'key': 'Disposition',
        'component': <Disposition />,
        'authority': [
          'user'
        ],
      },
      {
        'path': '/workingHours',
        'name': 'Working Hours',
        'title': "Working Hours",
        'icon': <FieldTimeOutlined />,
        'key': 'workingHours',
        'component': <WorkingHours />,
        'authority': [
          'user'
        ],
      },
    ]
  },


  {
    'path': '/adddepartment',
    'name': 'Add Department',
    'title': "Add Department",
    'icon': <Group />,
    "dontShowOnMenu": true,
    'key': 'addDepartment',
    'component': <AddDepartment />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/editdepartment/:id/:deptname',
    'name': 'Edit Department',
    'title': "Edit Department",
    'icon': <Group />,
    "dontShowOnMenu": true,
    'key': 'editDepartment',
    'component': <AddDepartment />,
    'authority': [
      'user'
    ]
  },
  // {
  //   'path': '/adddisposition',
  //   'name': 'Add Disposition',
  //   'title': "Add Disposition",
  //   'icon': <Group />,
  //   "dontShowOnMenu": true,
  //   'key': 'adddisposition',
  //   'component': <AddDisposition />,
  //   'authority': [
  //     'user'
  //   ]
  // },
  // {
  //   'path': '/editdisposition/:id',
  //   'name': 'Edit Disposition',
  //   'title': "Edit Disposition",
  //   'icon': <Group />,
  //   "dontShowOnMenu": true,
  //   'key': 'editdisposition',
  //   'component': <AddDisposition />,
  //   'authority': [
  //     'user'
  //   ]
  // },
  {
    'path': '/users',
    'name': 'Users',
    'title': "Users",
    'icon': <User />,
    'key': 'Users',
    'component': <Users />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/addUser',
    'name': 'Add User',
    'title': "Add User",
    'icon': <Group />,
    "dontShowOnMenu": true,
    'key': 'addUser',
    'component': <AddUsers />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/editUser/:id',
    'name': 'Edit User',
    'title': "Edit User",
    'icon': <Group />,
    "dontShowOnMenu": true,
    'key': 'editUser',
    'component': <AddUsers />,
    'authority': [
      'user'
    ]
  },


  // {
  //   'path': '/powerDailer',
  //   'name': 'Power Dailer',
  //   'title':"Power Dailer Campaigns",
  //   'icon': <Thunderbolt />,
  //   'key': 'PowerDailer',
  //   'component': <PowerDailer />,
  //   'authority': [
  //     'user'
  //   ]
  // },
  // {
  //   'path': '/voiceBroadcast',
  //   'name': 'Voice Broadcast',
  //   'title':"Voice Broadcast Campaigns",
  //   'icon': <Voice />,
  //   'key': 'VoiceBroadcast',
  //   'component': <VoiceBroadcast />,
  //   'authority': [
  //     'user'
  //   ]
  // },
  // {
  //   'path': '/callScript',
  //   'name': 'Call Script',
  //   'title': "Call Script",
  //   'icon': <Script />,
  //   'key': 'CallScript',
  //   'component': <CallScript />,
  //   'authority': [
  //     'user'
  //   ]
  // },
  {
    'path': '/Contacts',
    'name': 'Contacts',
    'title': "Contacts",
    'icon': <ContactsSvg />,
    'key': 'Contacts',
    'component': <Contacts />,
    'authority': [
      'user'
    ]
  },

  {
    'path': '/reports',
    'name': 'Reports',
    'title': "Reports",
    'icon': <ReportsSvg />,
    'key': 'Reports',

    'authority': [
      'user'
    ],
    children: [

      {
        'path': '/reports',
        'name': 'Reports',
        'title': "Reports",
        'icon': <ReportsSvg style={{ fontSize: 20, paddingRight: 10, width: 30 }} />,
        'key': 'Reports',
        'component': <ReportsList />,
        'authority': [
          'user'
        ]
      },
      {
        'path': '/callHistoryList',
        'name': 'Call History',
        'title': "Call History",
        'icon': <Phone style={{ fontSize: 20, paddingRight: 10, width: 30 }} />,
        'key': 'callHistory',
        'component': <History />,
        'authority': [
          'user'
        ]
      },
      {
        'path': '/AgentSummary',
        'name': 'Agent Call Summary',
        'title': "Agent Call Summary",
        'icon': <LineChartOutlined style={{ fontSize: 20 }} />,
        'key': 'AgentSummary',
        'component': <AgentSummary />,
        'authority': [
          'user'
        ]
      },
      {
        'path': '/DailyCallSummary',
        'name': 'Daily Call Summary',
        'title': "Daily Call Summary",
        'icon': <BarChartOutlined style={{ fontSize: 20 }} />,
        'key': 'DailyCallSummary',
        'component': <DailyCallSummary />,
        'authority': [
          'user'
        ]
      },

      {
        'path': '/Recordings',
        'name': 'Recordings',
        'title': "Recordings",
        'icon': <SoundOutlined style={{ fontSize: 20 }} />,
        'key': 'Recordings',
        'component': <Recordings />,
        'authority': [
          'user'
        ]
      },
      {
        'path': '/ScheduledCalls',
        'name': 'Scheduled Calls',
        'title': "Scheduled Calls",
        'icon': <Activity style={{ fontSize: 20, paddingRight: 10, width: 30 }} />,
        'key': 'ScheduledCalls',
        'component': <ScheduledCalls />,
        'authority': [
          'user'
        ]
      },
      {
        'path': '/DispositionReport',
        'name': 'Disposition Report',
        'title': "Disposition Report",
        'icon': <ContainerOutlined style={{ fontSize: 20, paddingRight: 10, width: 30 }} />,
        'key': 'DispositionReport',
        'component': <DispositionReport />,
        'authority': [
          'user'
        ]
      },
      {
        'path': '/PaymentHistory',
        'name': 'Payment History',
        'title': "Payment History",
        'icon': <DollarOutlined style={{ fontSize: 20, paddingRight: 10, width: 30 }} />,
        'key': 'Payment History',
        'component': <PaymentHistory />,
        'authority': [
          'user'
        ]
      },
      {
        'path': '/CallerLoginLogOutReport',
        'name': 'Caller Login LogOut Report',
        'title': "Caller Login LogOut Report",
        'icon': <FieldTimeOutlined style={{ fontSize: 20 }} />,
        'key': 'CallerLoginLogOutReport',
        'component': <CallerJobTime />,
        'authority': [
          'user'
        ]
      },
      {
        'path': '/RealTimeScreen',
        'name': 'Real Time Screen',
        'title': "Real Time Screen",
        'icon': <FieldTimeOutlined style={{ fontSize: 20 }} />,
        'key': 'RealTimeScreen',
        'component': <RealTimeScreen />,
        'authority': [
          'user'
        ]
      },
      {
        'path': '/didcdr',
        'name': 'DID CDR',
        'title': "DID CDR",
        'icon': <FieldTimeOutlined style={{ fontSize: 20 }} />,
        'key': 'didcdr',
        'component': <DIDCDR />,
        'authority': [
          'user'
        ]
      },
    ]
  },

  {
    'path': '/settings',
    'name': 'Settings',
    'title': "Settings",
    'icon': <SettingsSvg />,
    'key': 'Settings',

    'authority': [
      'user'
    ],
    children: [
      {
        'path': '/YourPlan',
        'name': 'Your Plan',
        'title': "Your Plan",
        'icon': <ProfileOutlined style={{ fontSize: 20 }} />,
        'key': 'YourPlan',
        'component': <YourPlan />,
        'authority': [
          'user'
        ]
      },
      {
        'path': '/PlanDetails',
        'name': 'Plan Details',
        'title': "Plan Details",
        'icon': <CreditCardOutlined style={{ fontSize: 20 }} />,
        'key': 'PlanDetails',
        'component': <PlanDetails />,
        'authority': [
          'user'
        ]
      },
      {
        'path': '/BillingContacts',
        'name': 'Billing Contacts',
        'title': "Billing Contacts",
        'icon': <WalletOutlined style={{ fontSize: 20 }} />,
        'key': 'BillingContacts',
        'component': <BillingContacts />,
        'authority': [
          'user'
        ]
      },
      {
        'path': '/CallQueue',
        'name': 'Call Priority Queue',
        'title': "Call Priority Queue",
        'icon': <PhoneOutlined style={{ fontSize: 20 }} />,
        'key': 'CallQueue',
        'component': <CallQueue />,
        'authority': [
          'user'
        ]
      },
      {
        'path': '/PriceList',
        'name': 'Call Cost',
        'title': "Call Cost",
        'icon': <DollarOutlined style={{ fontSize: 20 }} />,
        'key': 'PriceList',
        'component': <PriceList />,
        'authority': [
          'user'
        ]
      },
      {
        'path': '/Dnc',
        'name': 'Dnc',
        'title': "Dnc",
        'icon': <EyeInvisibleOutlined style={{ fontSize: 20 }} />
        ,
        'key': 'Dnc',
        'component': <Dnc />,
        'authority': [
          'user'
        ]
      },
      {
        'path': '/Holidays',
        'name': 'Holidays',
        'title': "Holidays",
        'icon': <HomeOutlined style={{ fontSize: 20 }} />,
        'key': 'Holidays',
        'component': <Holidays />,
        'authority': [
          'user'
        ]
      },
      {
        'path': '/BlockedCountries',
        'name': 'Blocked Countries',
        'title': "Blocked Countries",
        'icon': <CloseCircleOutlined style={{ fontSize: 20 }} />,
        'key': 'BlockedCountries',
        'component': <BlockedCountries />,
        'authority': [
          'user'
        ]
      },
      {
        'path': '/IVR',
        'name': 'IVR',
        'title': "IVR",
        'icon': <TagOutlined style={{ fontSize: 20 }} />,
        'key': 'IVR',
        'component': <IVR />,
        'authority': [
          'user'
        ]
      },
      {
        'path': '/RestrictedContacts',
        'name': ' Restricted Contacts',
        'title': "Restricted Contacts",
        'icon': <ContactsOutlined style={{ fontSize: 20 }} />,
        'key': 'RestrictedContacts',
        'component': <RestrictedContacts />,
        'authority': [
          'user'
        ]
      },
      {
        'path': '/RestrictedAreas',
        'name': 'Restricted Areas',
        'title': "Restricted Areas",
        'icon': <QrcodeOutlined style={{ fontSize: 20 }} />,
        'key': 'RestrictedAreas',
        'component': <RestrictedAreas />,
        'authority': [
          'user'
        ]
      },
      {
        'path': '/Profile',
        'name': 'Profile',
        'title': "Profile",
        'icon': <UserOutlined style={{ fontSize: 20 }} />,
        'key': 'Profile',
        'component': <Profile />,
        'authority': [
          'user'
        ]
      },
      {
        'path': '/ChangePassword',
        'name': 'Change Password',
        'title': "Change Password",
        'icon': <SettingOutlined style={{ fontSize: 20 }} />,
        'key': 'ChangePassword',
        'component': <ChangePassword />,
        'authority': [
          'user'
        ]
      },

    ]
  },

  {
    'path': '/Plans',
    'name': 'Call Plans',
    'title': "Call Plans",
    "dontShowOnMenu": true,
    'icon': <Script />,
    'key': 'Plans',
    'component': <Plans />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/callHistory',
    'name': 'Call History',
    'title': " Reports",
    "dontShowOnMenu": true,
    'icon': <Activity />,
    'key': 'CallHistory',
    'component': <CallHistory />,
    'authority': [
      'user'
    ]
  },
  {

    'path': '/DeptLeadset',
    'name': 'DeptLeadset',
    'title': <Deptname />,
    "dontShowOnMenu": true,
    'icon': '',
    'key': 'deptLeadSet',
    'component': <DeptLeadset />,
    'authority': [
      'user'
    ]
  },

  {
    'path': '/addCredit',
    'name': 'Add Credit',
    'title': "Add Credit",
    'icon': <Group />,
    "dontShowOnMenu": true,
    'key': 'addCredit',
    'component': <AddCreditPlan />,
    'authority': [
      'user'
    ]
  },


  // {
  //   'path': '/Leadset',
  //   'name': 'Leadset',
  //   'title': "Leadset",
  //   'icon': '',
  //   'key': 'Leadset',
  //   'component': <Leadset />,
  //   'authority': [
  //     'user'
  //   ]
  // },
];

// utils for path


width > 768 ? menu = menu1 : menu = menu2;


{/* <Media queries={{ small: { maxWidth: 768 } }}>
  {matches =>
    matches.small ?
      console.log('hello') : console.log('Bye')
  }
</Media> */}



export const getUrlPushWrapper = (keyString, query) => {
  return push(getUrlPath(keyString, query))
}

export const getUrlPath = (keyString, params) => {

  if (!params) params = {}

  let keyArr = keyString.split('.')

  let val = _.find(menu, p => p.key === keyArr[0])

  if (!val) {
    return `/`
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, p => p.key === keyArr[1])
  }

  if (!val) {
    return `/`
  }

  let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&')

  return `${val.path}?${queryString}`
}

export const getPushPathWrapper = (keyString, params = {}) => {

  let obj = getUrlObject(keyString)

  if (obj) {
    const path = new Path(obj.path)
    return push(path.build(params))
  }

  return 'error'
}

export const getUrlParams = (keyString, route) => {

  let obj = getUrlObject(keyString)
  if (obj) {
    const path = new Path(obj.path)

    return path.test(route)
  }

  return { error: true }
}

export const getUrlObject = (keyString) => {

  let keyArr = keyString.split('.')

  let val = _.find(menu, p => p.key === keyArr[0])

  if (!val) {
    return `/`
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, p => p.key === keyArr[1])
  }

  if (!val) {
    return `/`
  }

  return val
}

export default menu;