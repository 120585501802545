import React, { useState, useEffect } from 'react'
import './styles.less'
import {
    Form,
    Select,
    notification,
    Button,
    Input,
    Upload,
    Modal
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { MaxFileSize } from '../../settings';
import { useDispatch } from 'react-redux';
import Request from '../../request';
import { push } from 'connected-react-router';
import { ReactComponent as DialMantra } from '../../assets/dialMantra.svg'
import { ReactComponent as Drag } from '../../assets/drag.svg'
import _ from 'lodash'
import { useParams } from 'react-router';
import async from 'async'
import { getPushPathWrapper } from '../../routes';
import { ImageFileFormat } from '../../settings';
import { } from 'antd';
import './styles.less'
import FormItem from '../../components/FormItem'

const { Option } = Select;


const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

const Home = () => {
    const [loading, setLoader] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm()
    const requiredRule = [{ required: true, message: 'Please input field' }]
    const requiredRule1 = [{ required: true, message: 'Please input field', min: 0 }]

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onFinish = async (valData) => {
        setLoader(true)
        let { user, token, success, message, data } = await Request.addBillingContacts(valData)
        // console.log(success, data, message)
        setLoader(false)
        if (success) {
            notification.success({
                message: message
            })
            form.resetFields()
            handleCancel()

        } else {
            notification.error({
                message: message || "Failed",
                description: JSON.stringify(data)?.replace('[', '')?.replace('{', '')?.replace(']', '')?.replace('}', '')
            })
        }
    }

    return (<div className="">
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }} className="flex jcfe aic">
            <Button type="primary" className="buttonPrimaryStyle" loading={loading} onClick={() => showModal()}>Add Billing Contacts</Button>

        </div>
        <Modal title="Add Billing Contacts" destroyOnClose width={700} height={600} footer={null} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
            <Form
                {...layout}
                form={form}
                layout='vertical'
                onFinish={onFinish}
            >
                <FormItem
                    label={'Name'}
                    name="name"
                    rules={requiredRule}
                />
                <FormItem
                    label={'Company Name'}
                    name="companyname"
                    rules={requiredRule}
                />
                <FormItem
                    label={'Email'}
                    name="email"
                    inputType={'EMAIL'}
                    rules={requiredRule}
                />
                <FormItem
                    label={'Phone'}
                    name="phone"
                    maxLength={11}
                    width={200}
                    rules={requiredRule}
                    inputType={'NUMBER'}
                    controls={false}
                    min={1}
                />
                <FormItem
                    label={'Address'}
                    name="address"
                // rules={requiredRule}
                // inputType={'NUMBER'}
                />
                <FormItem
                    label={'Set As Default'}
                    name="active"
                    inputType={'SWITCH'}
                />


                <div className="marginTop">
                    <FormItem
                        inputType="BUTTON"
                        tailLayout={tailLayout}
                        type="primary"
                        buttonStyle={{ width: 200, marginTop: 20 }}
                        loading={loading}
                        title="SUBMIT"
                        htmlType="submit" />
                </div>
            </Form>

        </Modal>
    </div>
    );
}
const Login = () => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [loader, setLoader] = useState(false)
    const [userFormData, setUser] = useState({})
    const [formData, setFormData] = useState({})
    const [id, setStandardId] = useState([])
    const [proof, setAddressProof] = useState([])
    const [countries, setCountries] = useState([])
    const [formFileData, setData] = useState({})
    const [token, setToken] = useState('')

    const [visible, setVisible] = useState('')



    const onFinish = async () => {
        if ((!formData?.type_1) || (!formData?.type_2)) {
            notification.error({
                message: "Please select Id & Address Proof"
            })
            return
        }
        if ((!formFileData?.doc_1) || (!formFileData?.doc_2)) {
            notification.error({
                message: "Please Upload Id & Address Proof"
            })
            return
        }
        const MAINFORM = new FormData();

        MAINFORM.append('doc_1', formFileData?.doc_1?.originFileObj)
        MAINFORM.append('doc_2', formFileData?.doc_2?.originFileObj)
        MAINFORM.append('type_1', formData?.type_1)
        MAINFORM.append('type_2', formData?.type_2)
        MAINFORM.append('reuploaddoc', 'reuploaddoc')

        setLoader(true)
        let { user, success, message, data } = await Request.UploadDocument(MAINFORM, token)
        if (success) {
            notification.success({
                message: message || "success",
                description: 'Successfully Registered Please Login To Continue'
            })
            // dispatch(getPushPathWrapper('home'))
            setTimeout(() => {

                window.location.href = '/'
            }, 3000)
        }
        else {
            notification.error({
                message: message || "Failed",
                description: JSON.stringify(data)?.replace('[', '')?.replace('{', '')?.replace(']', '')?.replace('}', '')

            })
        }
        setLoader(false)
    }

    function onChange(key, value) {
        setFormData((prev) => ({ ...prev, [key]: value }))
    }
    const getFunction = async () => {
        let { user, token, success, data } = await Request.getCountries()
        if (success) {
            let setOnlyCountries = []
            async.forEach(data, (value, cb) => {
                if (!value?.default_blocked) {
                    setOnlyCountries.push(value)
                }
                cb()
            }, () => {
                setCountries(() => setOnlyCountries)
            })
        } else {
            setCountries(() => [])

        }
    }
    const apiFunction = async (AuthToken) => {
        // Request.getProfileFromToken(AuthToken).then(async ({ error, data: profileData, message }) => {
        //     if (error) {
        //         setLoader(true)
        //         let keyValue = {}
        //         _.forEach(countries, val => {
        //             if (val.countrycode == profileData?.auth?.country) {
        //                 keyValue = val
        //             }
        //         })
        setLoader(true)
        let { user, success, message, data } = await Request.allDocumentTypeWithToken({ reuploaddoc: 'reuploaddoc' }, AuthToken)
        setLoader(false)
        if (success) {
            let idd = []
            let add = []
            async.forEach(data, (value, cb) => {
                if (value?.acceptedas == 'id') {
                    idd.push(value)
                }
                else if (value?.acceptedas == 'add') {
                    add.push(value)

                }
                else if (value?.acceptedas == 'both') {
                    idd.push(value)
                    add.push(value)
                }
                cb()
            }, () => {
                setAddressProof(add)
                setStandardId(idd)
            })
        }

    }

    // })
    //     }
    useEffect(() => {
        setVisible(true)
        getFunction()
    }, [])
    const normFile = (e) => {
        // console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };
    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };
    const onChangeFile = (info, key) => {
        const nextState = {};
        switch (info.file.status) {
            case "uploading":
                nextState.selectedFile = info.file;
                nextState.id = [info.file];
                setData((prev) => ({ ...prev, [key]: info.file }))
                break;
            case "done":
                nextState.selectedFile = info.file;
                nextState.id = [info.file];
                setData((prev) => ({ ...prev, [key]: info.file }))
                break;

            default:
                // error or removed
                nextState.selectedFile = null;
                nextState.id = [];
                setData((prev) => ({ ...prev, [key]: null }))
        }
    };
    const requiredRule = [{ required: true, message: 'Please input field' }]

    const onLoginFinish = async (valData) => {
        setLoader(true)
        let { user, token, success, message: loginMessage, data } = await Request.login(valData)
        if (success) {
            setToken(() => data.auth_token)
            setVisible(() => false)
            apiFunction(data.auth_token)
        }
        else {
            notification.error({
                message: loginMessage || "Failed"
            })
        }
        setLoader(false)
    }
    const beforeUpload = (file) => {
        const isLt2M = file.size / 1024 / 1024 < MaxFileSize;
        // console.log(isLt2M, file.size,)
        if (!isLt2M) {
            notification.warning({
                message: `File must smaller than ${MaxFileSize}MB!`
            })
            return false;
        }
        return true
    };
    return (
        <div className="registration">
            <div className="color">
            </div>
            <div className="dispayContent">
                <div className="">
                    <DialMantra width="20vw" height="16vh" />
                </div>
                <div className="">
                    <div className="subregistration">
                        Please Reupload Documents For Verification
                    </div>
                </div>
                <div className="drag">
                    <Drag />
                    <div>Upload your files here</div>
                    <div>File size must be less than 2 MB <span style={{ color: 'red' }}>*</span></div>
                </div>
                <div className="drag">
                    <div>
                        <Form name="validate_other" >
                            <div className="marginLargetop">Please upload these documents</div>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                <div style={{ marginRight: '2rem', display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                    <Select
                                        showSearch
                                        style={{ width: 200 }}
                                        placeholder="Select ID Proof"
                                        optionFilterProp="children"
                                        onChange={(val) => onChange('type_1', val)}
                                        options={_.map(id, val => { return ({ label: val?.name, value: val?.id }) })}
                                    // filterOption={(input, option) =>
                                    //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    // }
                                    />:
                                    <div style={{ width: '30%', marginTop: '14px', marginLeft: '2rem' }}>
                                        <Form.Item
                                            name="StandardID"
                                            // label="Standard ID"
                                            valuePropName="fileList"
                                            getValueFromEvent={normFile}
                                            extra=""
                                        >
                                            <Upload name="logo" onChange={(p) => onChangeFile(p, 'doc_1')}
                                                fileList={[formFileData?.doc_1]} customRequest={dummyRequest} accept={ImageFileFormat} maxCount={1} beforeUpload={beforeUpload}>
                                                <Button icon={<UploadOutlined />}>Click to upload</Button>
                                            </Upload>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div style={{ marginRight: '2rem', display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                    <Select
                                        showSearch
                                        style={{ width: 200 }}
                                        placeholder="Select Address Proof"
                                        optionFilterProp="children"
                                        onChange={(val) => onChange('type_2', val)}
                                        options={_.map(proof, val => { return ({ label: val?.name, value: val?.id }) })}
                                    // filterOption={(input, option) =>
                                    //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    // }
                                    />:
                                    <div style={{ width: '30%', marginTop: '14px', marginLeft: '2rem' }}>
                                        <Form.Item
                                            name="addressproofs"
                                            // label="Address Proofs"
                                            valuePropName="fileList"
                                            getValueFromEvent={normFile}
                                            extra=""
                                        >
                                            <Upload name="logo" onChange={(p) => onChangeFile(p, 'doc_2')}
                                                accept={ImageFileFormat} fileList={[formFileData?.doc_2]} customRequest={dummyRequest} maxCount={1} beforeUpload={beforeUpload}>
                                                <Button icon={<UploadOutlined />}>Click to upload</Button>
                                            </Upload>
                                        </Form.Item>
                                    </div>
                                </div>
                                <Home />
                            </div>
                            <div style={{ justifyContent: "center", display: "flex", margin: "1rem" }} >
                                <Button loading={loader} onClick={() => onFinish()} color="#FF6600" style={{ width: "200px", borderRadius: '4px', backgroundColor: "#ff6600", color: "#fff" }}>Submit</Button>
                            </div>

                        </Form>
                    </div>
                </div>
            </div>

            <Modal
                visible={visible}
                title={'Please Login To Continue'}
                header={null}
                closable={false}
                //   onOk={this.handleOk}
                //   onCancel={this.handleCancel}
                footer={null}
            >
                <Form className='form' form={form} onFinish={onLoginFinish} size='large' layout='vertical'>
                    <div className='inputWidth'>
                        <Form.Item name='email' rules={requiredRule}>
                            <Input type="email" placeholder='Enter Email ID' />
                        </Form.Item>
                        <Form.Item name='password' rules={requiredRule}>
                            <Input maxLength={16} type="password" placeholder='Enter Password' />
                        </Form.Item>
                    </div>
                    <Form.Item >
                        <Button size='large' loading={loader} htmlType='submit' type='primary' style={{ width: '90%', marginTop: '3rem' }}>Sign in</Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>

    );
}

export default Login