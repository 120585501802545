const initialState = {
    sessionCall: null,
    endpoint: null,
    destination: null,
    inProgress: null,
    isConfirmed: null,
    incomingCall: null,
    incomingConfirmed: null,
    terminated: null,
    failed: null,
    registrationFailed: null,
    unRegistered: null,
    registered: null,
    destinationFromContacts: null,
}

export default (state = initialState, action) => {
    switch (action.type) {

        case 'SET_ENDPOINT':
            return {
                ...state,
                endpoint: action.payload,
            }
        case 'SET_CALL':
            return {
                ...state,
                sessionCall: action.payload,
            }
        case 'SET_NUMBER':
            return {
                ...state,
                destination: action.payload,
            }
        case 'SET_NUMBER_FROM_CONTACTS':
            return {
                ...state,
                destinationFromContacts: action.payload,
            }
        case 'IN_PROGRESS':
            return {
                ...state,
                inProgress: action.payload,
            }
        case 'IS_CONFIRMED':
            return {
                ...state,
                isConfirmed: action.payload,
            }
        case 'INCOMING':
            return {
                ...state,
                incomingCall: action.payload,
            }
        case 'INCOMING_CONFIRMED':
            return {
                ...state,
                incomingConfirmed: action.payload,
            }
        case 'TERMINATE':
            return {
                ...state,
                terminated: action.payload,
            }
        case 'FAILED':
            return {
                ...state,
                failed: action.payload,
            }
        case 'REGIS_FAILED':
            return {
                ...state,
                registrationFailed: action.payload,
            }
        case 'UNREGISTERED':
            return {
                ...state,
                unRegistered: action.payload,
            }
        case 'REGISTERED':
            return {
                ...state,
                registered: action.payload,
            }
        default:
            return state
    }
}