import { lazy, useEffect, useState } from 'react';
import { List, Collapse, Switch, Drawer, Modal, Radio, Image, notification, Space, Spin, Input, InputNumber, Button, Form, Card } from 'antd';
import './styles.less'
import { AudioOutlined, DeleteColumnOutlined, SearchOutlined, DeleteOutlined, DeleteTwoTone, EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import FormItem from '../../components/FormItem'
import { useDispatch, useSelector } from 'react-redux';
import { getPushPathWrapper } from '../../routes';
import { push } from 'connected-react-router';
import Request from '../../request'
import { confirmBox, confirmBox1 } from '../../utils';
import _ from 'lodash'
import { apiUrl } from '../../settings'
import { auto } from 'async';
import HomeView from '../../containers/settings/list';
const LIST = [{}, {}, {}, {}, {}, {}]

export const AddCreditPlan = () => {
    const dispatch = useDispatch()
    const [dataSource, setDataSource] = useState({})
    const [isModalVisible, setIsModalVisible] = useState(true);
    const [isModalVisible1, setIsModalVisible1] = useState(false);
    const [loader, setLoader] = useState(false)
    const [creditCount, setCreditCount] = useState(parseInt(20))
    const [form] = Form.useForm()

    const { currentUser, showPlan } = useSelector((state) => ({
        currentUser: state.global.currentUser,
        showPlan: state.global.showPlan
    }))

    const apiFunction = async () => {
        setLoader(true)
        let { data, success } = await Request.getProfile()
        setLoader(false)
        if (success) {
            setDataSource(() => data)
            console.log(dataSource,"dataSource dataSource")
        } else {
            setDataSource(() => { })

            // notification.error({
            //   message: message || "Failed"
            // })
        }
    }

    useEffect(() => {
        apiFunction()
    }, [currentUser])

    const UpdateCredit = async () => {
        setLoader(true)
        let { user, token, success, message, data } = await Request.addCreditAmount({ amount: parseInt(creditCount) })
        setLoader(false)
        if (success) {
            dispatch({
                type: 'SET_CURRENT_USER',
                payload: { ...data }
            })
            setIsModalVisible(false)
            setIsModalVisible1(false)
            notification.success({
                message: message || "success"
            })
        } else {

            notification.error({
                message: message || "Failed"
            })
        }
    }
    const widthMobile = window.innerWidth;

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        widthMobile > 768 ? dispatch(push('/settings')) : dispatch(push('/YourPlan'))
    };

    const handleOk1 = () => {
        setIsModalVisible1(false);
    };

    const handleCancel1 = () => {
        setIsModalVisible1(false);
        widthMobile > 768 ? dispatch(push('/settings')) : dispatch(push('/YourPlan'))
    };

    return (<>
        {widthMobile > 768 ? <HomeView /> : <ProductList />}
        <Modal title={<b>Add Credit To Your Account { }</b>} visible={isModalVisible} footer={null} onOk={handleOk} onCancel={handleCancel}>
            <div className="flex aic jcc" style={{ width: 400 }}>
                <div style={{ marginRight: 10 }}>Credit to be added ($) </div>
                <InputNumber type="number" min={20} onChange={(e) => setCreditCount(e)} value={creditCount} style={{ width: 118 }} />
            </div>
            <div className="flex aic marginTop jcc" style={{ width: auto, justifyContent: 'center' }}>
                <Button type="primary" onClick={() => {
                    setIsModalVisible1(true)
                }}>Add Credit</Button>
            </div>
        </Modal>
        <Modal title={<b>Confirm</b>} visible={isModalVisible1} footer={null} onOk={handleOk1} onCancel={handleCancel1}>
            <div className="flex">
                <div><InfoCircleOutlined style={{ fontSize: '25px', color: '#f9f340' }} /></div>
                <div style={{ marginLeft: 10, fontSize: 16, fontWeight: 500 }}>
                    Do you want to proceed with adding credit of ${creditCount} ?
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
                <div className="flex aic marginTop jcc" style={{ padding: 2, marginRight: 10 }}>
                    <Button type="secondary" onClick={() => setIsModalVisible1(false)}>Cancel</Button>
                </div>

                <div className="flex aic marginTop jcc" style={{ padding: 2 }}>
                    <Button type="primary" onClick={() => UpdateCredit()}>Confirm</Button>
                </div>
            </div>
        </Modal>
    </>
    )
}


const ProductList = (props) => {
    const dispatch = useDispatch()
    const [dataSource, setDataSource] = useState({})
    const [dataSource1, setDataSource1] = useState({})
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisible1, setIsModalVisible1] = useState(false);
    const [isModalVisible2, setIsModalVisible2] = useState(false);
    const [isModalVisible3, setIsModalVisible3] = useState(false);
    const [InputExtention, setInputExtention] = useState();
    const [visible, setVisible] = useState(false);
    const [visible1, setVisible1] = useState(false);
    const [renewurl, setRenewurl] = useState('');
    const [loader, setLoader] = useState(false)
    const [addOn, setAddOn] = useState({})
    const [addOnValue, setAddOnValue] = useState({})
    const [planSummaryDetails, setPlanSummaryDetails] = useState({});
    const [peroid, setPeroid] = useState('')

    const { currentUser, showPlan } = useSelector((state) => ({
        currentUser: state.global.currentUser,
        showPlan: state.global.showPlan
    }))

    const apiFunction = async () => {
        setLoader(true)
        let { data, success } = await Request.getProfile()
        setLoader(false)
        if (success) {
            setDataSource(() => data)
            setPeroid(data?.plantype)
            console.log(dataSource,"dataSource dataSource2")
        } else {
            setDataSource(() => { })

            // notification.error({
            //   message: message || "Failed"
            // })
        }
    }
    useEffect(() => {
        apiFunction()
    }, [currentUser])
    const UpdateExt = async () => {
        setLoader(true)
        let { user, token, success, message, data } = await Request.selectPlan({ selectedcallercount: parseInt(InputExtention) })
        setLoader(false)
        if (success) {
            // console.log("in plan <<<<<<<<<<<<<<<<<<<<<<<<<<<<<")
            dispatch({
                type: 'SET_CURRENT_USER',
                payload: { ...data }
            })
            setIsModalVisible(false)
            setIsModalVisible1(false)
            setIsModalVisible2(false)
            notification.success({
                message: message || "success"
            })
        } else {

            notification.error({
                message: message || "Failed"
            })
        }
    }
    const UpdatePlan = async () => {
        setLoader(true)
        let { user, token, success, message, data } = await Request.selectPlan({ plantype: peroid })
        setLoader(false)
        if (success) {
            // console.log("in your plans <<<<<<<<<<<<<<<<<<<<<<<<<<<<<")
            dispatch({
                type: 'SET_CURRENT_USER',
                payload: { ...data }
            })
            notification.success({
                message: message || "success"
            })
        } else {

            notification.error({
                message: message || "Failed",
                description: JSON.stringify(data)?.replace('[', '')?.replace('{', '')?.replace(']', '')?.replace('}', '')

            })
        }
    }

    const selectPlan = async (type) => {
        setLoader(true)
        let { user, token, success, message, data } = await Request.getSummary({ selectedcallercount: parseInt(InputExtention) })
        setLoader(false)
        if (success) {
            setPlanSummaryDetails(data)

            console.log(planSummaryDetails,"detail plan")
            // setPlantype(type)
            setIsModalVisible1(true)
        }
        else {

            notification.error({
                message: message || "Failed"
            })
        }

    }

    const RenewPlan = async () => {
        setLoader(true)
        let { user, token, success, message, data } = await Request.RenewPlan()
        setLoader(false)
        if (success) {
            setRenewurl(data)
        } else {
            setRenewurl(() => { })
        }
    }

    const getAddOn = async () => {
        setLoader(true)
        let { user, token, success, message, data } = await Request.getAddOns()
        setLoader(false)
        if (success) {
            setAddOn(data)
        } else {
            setAddOn(() => { })
        }
    }

    const listSubAddOns = async () => {
        setLoader(true)
        let { user, token, success, message, data } = await Request.listSubAddOn()
        setLoader(false)
        if (success) {
            setDataSource1(() => data)
        } else {
            setDataSource1(() => { })
        }
    }

    const subscribeAddOn = async () => {
        confirmBox(dispatch, {
            message: 'Do You Wish To Proceed To The Payment Page',
            onOk: async () => {
                setLoader(true)
                let { user, token, success, message, data } = await Request.subscribeAddOn({ id: addOnValue?.id })
                setLoader(false)
                if (success) {
                    notification.success({
                        message: message || "Subscribed Successfully"
                    })
                    // setAddOnValue({})
                    listSubAddOns()
                    setVisible(false)
                    setVisible1(false)
                } else {
                    notification.error({
                        message: message || "Failed",
                        description: JSON.stringify(data)?.replace('[', '')?.replace('{', '')?.replace(']', '')?.replace('}', '')

                    })
                }
            }
        })
    }

    const unsubscribeAddOn = (id) => {
        confirmBox1(dispatch, {
            message: 'Are You Sure You Want To Unsubscribe Addon',
            onOk: async () => {
                setLoader(true)
                let { user, token, success, message, data } = await Request.unsubscribeAddOn(id)
                setLoader(false)
                if (success) {
                    notification.success({
                        message: message || "Subscription Removed Successfully"
                    })
                    setAddOnValue({})
                    apiFunction()
                    listSubAddOns()
                } else {
                    notification.error({
                        message: message || "Failed",
                        description: JSON.stringify(data)?.replace('[', '')?.replace('{', '')?.replace(']', '')?.replace('}', '')

                    })
                }
            }
        })
    }

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const handleOk1 = () => {
        setIsModalVisible1(true);
    };

    const handleCancel1 = () => {
        setIsModalVisible1(false);
    };

    const handleOk2 = () => {
        setIsModalVisible2(true);
    };

    const handleCancel2 = () => {
        setIsModalVisible2(false);
    };

    const handleOk3 = () => {
        setIsModalVisible3(true);
    };

    const handleCancel3 = () => {
        setIsModalVisible3(false);
    };

    const onClose = () => {
        setVisible(false);
    };

    const onClose1 = () => {
        setVisible1(false);
    };
    const addonCard = {
        backgroundColor: "white",
        padding: "20px",
        textAlign: "left",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        height: "auto",
        border: 'none'
    };
    const addonCard1 = {
        backgroundColor: "#293275",
        textAlign: "left",
        height: "fit-content",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 0px 2px 0px",
        display: 'flex',
        border: 'none',
        borderRadius: '30px',
        minWidth: '10em',
        margin: '0.2em 1% 1% 1%',
        justifyContent: 'space-between',
        width: 'fit-content',
        alignItems: 'center'
    };
    const addonCardP = {
        fontWeight: '12px',
    };

    // const addonValue = (val) => {

    // }
    const widthMobile = window.innerWidth;


    useEffect(() => {
        apiFunction()
        getAddOn()
        listSubAddOns()
    }, [])

    return (

        <div className="settingListWrapper" style={{ width: '100%' }}>
            <div className="" style={{ overflow: 'auto' }}>
                <div className="titleStyle">Your Plan</div>
                <List
                    className='SettingListElem'
                    // loading={loading}
                    dataSource={LIST}

                >
                    <List.Item className='settingSdapList' >
                        <List.Item.Meta
                            title={<div className="flex">
                                <div className="Circle marginRightLarge">
                                </div>
                                <div>
                                    <div className="InnerStyleList">${dataSource?.balance?.balance}</div>
                                    <div className="InnerStyleSubHeading" style={{ display: "flex", alignItems: "center" }}>
                                        <div style={{ marginRight: 10 }}>
                                            Available Credit
                                        </div>
                                        {dataSource?.status?.name == 'no_payment' || currentUser?.plan == null ? <Button type="primary" disabled onClick={() => dispatch(push('/addCredit'))}>Add Credit</Button>
                                            : <Button type="primary" onClick={() => dispatch(push('/addCredit'))}>Add Credit</Button>}
                                    </div>
                                </div>
                            </div>
                            }
                        />
                    </List.Item>
                    <List.Item className='settingSdapList' >
                        <List.Item.Meta
                            title={<div className="flex">
                                <div className="Circle marginRightLarge">
                                </div>
                                <div>
                                    <div className="InnerStyleList">{dataSource?.plan?.planname}</div>
                                    <div className="InnerStyleSubHeading" style={{ display: "flex", alignItems: "center" }}>
                                        <div style={{ marginRight: 10, paddingRight: 30 }}>
                                            Current Plan
                                        </div>
                                        {dataSource?.status?.name == 'no_payment' ? <Button type="primary" disabled onClick={() => dispatch(getPushPathWrapper('Plans'))}>Change Plan</Button>
                                            : <Button type="primary" onClick={() => dispatch(getPushPathWrapper('Plans'))}>Change Plan</Button>}
                                    </div>
                                </div>
                            </div>
                            }
                        />
                    </List.Item>
                    <List.Item className='settingSdapList overflowScroll' >
                        <List.Item.Meta

                            title={<div className="flex">
                                {/* <div className="Circle marginRightLarge">
                                </div> */}
                                <div className="flex maxExten" style={{ display: 'flex' }}>
                                    {/* <div className="borderPlan marginRight3">
                                        <div className="InnerStyleListNumber"> {dataSource?.balance?.callrate} ¢</div>
                                        <div className="InnerStyleSubHeading">Call Rate</div>
                                    </div> */}
                                    <div className="borderPlan marginRight3">
                                        <div className="InnerStyleListNumber" > {dataSource?.balance?.freeminutes}</div>
                                        <div className="InnerStyleSubHeading" style={{ textAlign: "center" }}>Free Minutes Per User</div>
                                    </div>

                                    <div className="borderPlan marginRight3">
                                        <div className="InnerStyleListNumber">{dataSource?.balance?.maximumallowedusers}</div>
                                        <div className="InnerStyleSubHeading" style={{ textAlign: "center" }}>Maximum Extensions Allowed</div>
                                    </div>
                                    <div className="borderPlan marginRight3">
                                        <div className="InnerStyleListNumber"> {dataSource?.balance?.selectedcallercount}</div>
                                        <div className="InnerStyleSubHeading" style={{ textAlign: "center" }}>Subscribed Extensions</div>
                                    </div>
                                    {(dataSource?.status?.name == 'no_payment' || dataSource?.server == null) ? <Button disabled type="primary" loading={loader} onClick={() => {
                                        showModal()
                                        setInputExtention(dataSource?.balance?.selectedcallercount)
                                    }}>Change Extentions Subscription</Button> :
                                        <Button type="primary" loading={loader} onClick={() => {
                                            showModal()
                                            setInputExtention(dataSource?.balance?.selectedcallercount)
                                        }}>Change Extentions Subscription</Button>}
                                </div>
                            </div>

                            }
                        />
                    </List.Item>
                    <Modal title={<b>Current Number Of Extensions : {dataSource?.balance?.selectedcallercount}</b>} visible={isModalVisible} footer={null} onOk={handleOk} onCancel={handleCancel}>
                        <div className="flex aic jcc" style={{ width: 400 }}>
                            <div style={{ marginRight: 10 }}>Change Count </div>
                            <InputNumber type="number" min={1} value={InputExtention} onChange={(e) => setInputExtention(e)} style={{ width: 118 }} max={dataSource?.balance?.maximumallowedusers} />
                        </div>
                        <div className="flex aic marginTop jcc" style={{ width: auto, justifyContent: 'center' }}>
                            <Button type="primary" onClick={() => {
                                // handleOk1()
                                selectPlan()
                            }}>Update Subscription</Button>
                        </div>
                    </Modal>
                    <Modal title={<b>Subscription Change</b>} visible={isModalVisible1} width={800} footer={null} onOk={handleOk1} onCancel={handleCancel1}>
                        <div className="flex ">
                            <div className="marginRight3" style={{ fontSize: 18 }}>
                                New Subscription Cost: {planSummaryDetails?.new_cost * planSummaryDetails?.selectedcallercount}
                            </div>
                            <div style={{ fontSize: 18 }}>
                                Old Subscription Cost: {planSummaryDetails?.old_cost * planSummaryDetails?.old_caller_count}
                            </div>
                        </div>
                        {/* <FilterTable pagination={false} bordered dataSource={planSummaryDetails?.features} size="small" columns={columns} loading={loading} /> */}
                        <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
                            <div className="flex aic marginTop jcc" style={{ padding: 2, marginRight: 10 }}>
                                <Button type="secondary" onClick={() => setIsModalVisible1(false)}>Cancel</Button>
                            </div>

                            <div className="flex aic marginTop jcc" style={{ padding: 2 }}>
                                <Button type="primary" onClick={() => {
                                    setIsModalVisible2(true)
                                    setIsModalVisible1(false)
                                }}>Confirm Changes</Button>
                            </div>
                        </div>

                    </Modal>
                    {planSummaryDetails?.upgrade == false ?
                        <Modal title={<b>Confirm</b>} visible={isModalVisible2} footer={null} onOk={handleOk2} onCancel={handleCancel2}>
                            <div className="flex">
                                <div><InfoCircleOutlined style={{ fontSize: '25px', color: '#f9f340' }} /></div>
                                <div style={{ marginLeft: 10, fontSize: 16, fontWeight: 500 }}>
                                    Proceed to downgrade Subscription
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
                                <div className="flex aic marginTop jcc" style={{ padding: 2, marginRight: 10 }}>
                                    <Button type="secondary" onClick={() => setIsModalVisible2(false)}>Cancel</Button>
                                </div>

                                <div className="flex aic marginTop jcc" style={{ padding: 2 }}>
                                    <Button type="primary" onClick={() => UpdateExt()}>Confirm</Button>
                                </div>
                            </div>
                        </Modal>
                        :
                        <Modal title={<b>Confirm</b>} visible={isModalVisible2} footer={null} onOk={handleOk2} onCancel={handleCancel2}>
                            <div className="flex">
                                <div><InfoCircleOutlined style={{ fontSize: '25px', color: '#f9f340' }} /></div>
                                <div style={{ marginLeft: 10, fontSize: 16, fontWeight: 500 }}>
                                    Proceed to Upgrade Subscription
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
                                <div className="flex aic marginTop jcc" style={{ padding: 2, marginRight: 10 }}>
                                    <Button type="secondary" onClick={() => setIsModalVisible2(false)}>Cancel</Button>
                                </div>

                                <div className="flex aic marginTop jcc" style={{ padding: 2 }}>
                                    <Button type="primary" onClick={() => UpdateExt()}>Confirm</Button>
                                </div>
                            </div>
                        </Modal>
                    }
                    <List.Item className='settingSdapList' >
                        <List.Item.Meta

                            title={<div className="flex ">

                                <div className="InnerStyleList marginRightLarge" >Plan Expiry :</div>
                                <div className="InnerStyleSubHeading">{dataSource?.balance?.planexpiry}</div>
                                {dataSource?.status?.name == 'no_payment' ? <Button type="primary" style={{ marginLeft: 10 }} loading={loader} onClick={() => {
                                    setIsModalVisible3(true)
                                    RenewPlan()
                                }}>Renew Plan</Button> : <></>}
                                {/* <Form.Item name="radio-group" style={{ paddingTop: "25px" }}> */}
                                {/* <Radio.Group size={'large'} defaultValue={'a'}>
                                        <Radio size="large" value="a">Basic</Radio>
                                        <Radio value="b">Bronze</Radio>
                                        <Radio value="c">Silver</Radio>
                                        <Radio value="d">Platinum</Radio>
                                    </Radio.Group> */}
                                {/* </Form.Item> */}
                            </div>
                            }
                        />
                    </List.Item>
                    <Modal title={<b>Renew Plan</b>} visible={isModalVisible3} width={550} footer={null} onOk={handleOk3} onCancel={handleCancel3}>
                        <div className="flex ">
                            <div className="marginRight3" style={{ fontSize: 18 }}>
                                You are about to renew your current plan : {<b>{dataSource?.plan?.planname}</b>}{<br />}
                                Amount To Be Paid for renewal : {<b>${renewurl?.amount}</b>}
                            </div>
                        </div>
                        {/* <FilterTable pagination={false} bordered dataSource={planSummaryDetails?.features} size="small" columns={columns} loading={loading} /> */}
                        <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
                            <div className="flex aic marginTop jcc" style={{ padding: 2, marginRight: 10 }}>
                                <Button type="secondary" onClick={() => setIsModalVisible3(false)}>Cancel</Button>
                            </div>

                            <div className="flex aic marginTop jcc" style={{ padding: 2 }}>
                                <Button type="primary" onClick={() => { window.location.href = `${apiUrl}${renewurl?.url}` }}>Confirm</Button>
                            </div>
                        </div>

                    </Modal>
                    <List.Item style={{ borderBottom: '0px solid #DFDFDF', padding: '24px 10px 30px 0px' }} >
                        <List.Item.Meta
                            title={<div className="flex " style={{ alignItems: "center" }}>
                                <div className="InnerStyleList marginRightLarge" >Subscribed Addons :</div>
                                <div className="InnerStyleSubHeading">
                                    {dataSource?.status?.name == 'no_payment' || !currentUser?.plan?.addons ?
                                        dataSource?.balance?.cli ?
                                            <Button type="primary" disabled loading={loader} style={{ marginRight: '10px' }} onClick={() => { setVisible(true) }}>Select Addons</Button>
                                            : 'Unavailable'
                                        : dataSource?.balance?.cli ?
                                            <Button type="primary" loading={loader} style={{ marginRight: '10px' }} onClick={() => { setVisible(true) }}>Select Addons</Button>
                                            : 'Unavailable'}
                                </div>

                                {/* <Form.Item name="radio-group" style={{ paddingTop: "25px" }}> */}
                                {/* <Radio.Group size={'large'} defaultValue={'a'}>
                                        <Radio size="large" value="a">Basic</Radio>
                                        <Radio value="b">Bronze</Radio>
                                        <Radio value="c">Silver</Radio>
                                        <Radio value="d">Platinum</Radio>
                                    </Radio.Group> */}
                                {/* </Form.Item> */}
                            </div>
                            }
                        />
                    </List.Item>
                    <List.Item style={{ borderBottom: '1px solid #DFDFDF', padding: '10px 0 0 0' }} >
                        <List.Item.Meta
                            title={<div className="flex " style={{ alignItems: "center" }}>
                                {/* <div className="InnerStyleList marginRightLarge" style={{ width: '13em' }}>Subscribed Addons :</div> */}
                                <div loading={loader} style={{ display: 'flex', flexWrap: 'wrap', width: '100%', height: 'max-content', maxHeight: '12em', overflowX: 'scroll' }}>
                                    {_.map(dataSource1, val => {
                                        return <div class="cards" style={addonCard1}>
                                            <div class="cards" style={{ padding: '10px', width: '100%' }}>
                                                <div style={{ display: 'flex', width: 'inherit', justifyContent: 'space-between' }}><p style={{ fontSize: '14px', margin: 'auto', fontWeight: 100, color: '#ffffff' }}>{val?.extra_detail?.addonsname}</p></div>
                                            </div>
                                            {dataSource?.status?.name == 'no_payment' ? <Button loading={loader} disabled style={{ border: '1px', color: '#ffffff', background: 'transparent', borderLeft: '1px solid white' }} onClick={() => { unsubscribeAddOn(val?.id); }}>X</Button> :
                                                <Button loading={loader} style={{ border: '1px', color: '#ffffff', background: 'transparent', borderLeft: '1px solid white' }} onClick={() => { unsubscribeAddOn(val?.id); }}>X</Button>}
                                        </div>
                                    })}
                                </div>
                            </div>
                            }
                        />
                    </List.Item>

                    <Drawer
                        title="Addons"
                        placement="right"
                        closable={true}
                        className='drawerCross'
                        width={widthMobile < 768 ? 400 : 700}
                        onClose={() => setVisible(false)}
                        visible={visible}
                    >
                        <div loading={loader} style={{ display: 'grid', gridTemplateColumns: 'auto 49%', gridColumnGap: '10px', gridRowGap: '20px' }}>
                            {_.map(addOn, val => {
                                return <>{val?.status && <Button loading={loader} class="cards" onClick={() => { setAddOnValue(val); setVisible1(true) }} style={addonCard}>
                                    <div style={{ display: 'flex', width: 'inherit', whiteSpace: 'pre-wrap', justifyContent: 'space-between' }}><p style={{ fontSize: '15px', fontWeight: 600, color: '#293275' }}>Name </p><p style={{ fontWeight: 100, maxWidth: '10em', textAlign: 'right', color: 'black' }}>{val?.name}</p></div>
                                    <div style={{ display: 'flex', width: 'inherit', whiteSpace: 'pre-wrap', justifyContent: 'space-between' }}><p style={{ fontSize: '15px', fontWeight: 600, color: '#293275' }}>Description </p><p style={{ fontWeight: 100, maxWidth: '10em', textAlign: 'right', color: 'black' }}>{val?.description}</p></div>
                                    <div style={{ display: 'flex', width: 'inherit', whiteSpace: 'pre-wrap', justifyContent: 'space-between' }}><p style={{ marginBottom: '0', fontSize: '15px', fontWeight: 600, color: '#293275' }}>Monthly Price </p><p style={{ marginBottom: '0', fontWeight: 600, textAlign: 'right', maxWidth: '10em', color: 'black' }}>$ {val?.monthprice}</p></div>
                                </Button>}</>
                            })}
                        </div>
                        <Drawer
                            title="Selected Addon"
                            placement="right"
                            closable={true}
                            className='drawerCross'
                            width={widthMobile < 768 ? 350 : 600}
                            onClose={() => setVisible1(false)}
                            visible={visible1}
                        >
                            <div style={{ fontSize: '18px', padding: '10px', marginBottom: '2em', textAlign: 'center' }}>Do you wish to proceed with the selected addon</div>
                            <div loading={loader} style={{ height: 'calc(100vh - 230px)', display: 'flex', alignItems: 'center', border: 'none', flexDirection: 'column', padding: '20px 3vw', width: '100%' }}>
                                <div style={{ display: 'flex', width: 'inherit', whiteSpace: 'pre-wrap', justifyContent: 'space-between' }}><p style={{ fontSize: '17px', fontWeight: 600, color: '#293275' }}>Name </p><p style={{ fontWeight: 100, maxWidth: '10em', textAlign: 'right', color: 'black' }}>{addOnValue?.name}</p></div>
                                <div style={{ display: 'flex', width: 'inherit', whiteSpace: 'pre-wrap', justifyContent: 'space-between' }}><p style={{ fontSize: '17px', fontWeight: 600, color: '#293275' }}>Description </p><p style={{ fontWeight: 100, maxWidth: '10em', textAlign: 'right', color: 'black' }}>{addOnValue?.description}</p></div>
                                <div style={{ display: 'flex', width: 'inherit', whiteSpace: 'pre-wrap', justifyContent: 'space-between' }}><p style={{ fontSize: '17px', fontWeight: 600, color: '#293275' }}>Monthly Price </p><p style={{ fontWeight: 600, maxWidth: '10em', textAlign: 'right', color: 'black' }}>$ {addOnValue?.monthprice}</p></div>
                            </div>
                            <Button type="primary" onClick={() => subscribeAddOn()} style={{ height: '3.5em', width: '100%' }}>Proceed</Button>
                        </Drawer>
                    </Drawer>
                    <List.Item className='settingSdapList' >
                        <List.Item.Meta

                            title={<div className="flex " style={{ alignItems: "center" }}>

                                <div className="InnerStyleList marginRightLarge"  >Plan Description :</div>
                                <div className="InnerStyleSubHeading">{dataSource?.plan?.plandescription}</div>
                                {/* <Form.Item name="radio-group" style={{ paddingTop: "25px" }}> */}
                                {/* <Radio.Group size={'large'} defaultValue={'a'}>
                                        <Radio size="large" value="a">Basic</Radio>
                                        <Radio value="b">Bronze</Radio>
                                        <Radio value="c">Silver</Radio>
                                        <Radio value="d">Platinum</Radio>
                                    </Radio.Group> */}
                                {/* </Form.Item> */}
                            </div>
                            }
                        />
                    </List.Item>
                    <List.Item className='settingSdapList overflowScroll' >
                        <List.Item.Meta

                            title={<div className="flex subCycle" style={{ alignItems: "center" }}>
                                <div className="InnerStyleList marginRightLarge" >Plan Subscription Cycle :</div>
                                {/* <Form.Item name="radio-group" > */}
                                <Radio.Group size="large" defaultValue={''} onChange={(e) => setPeroid(e?.target?.value)} value={peroid} buttonStyle="solid">
                                    <Radio value="month">Monthly</Radio>
                                    <Radio value="annual">Annualy</Radio>
                                </Radio.Group>
                                {dataSource?.status?.name == 'no_payment' ? <Button disabled type="primary" loading={loader} onClick={() => UpdatePlan()}>Update Plan</Button>
                                    : <Button type="primary" loading={loader} onClick={() => UpdatePlan()}>Update Plan</Button>}

                                {/* </Form.Item> */}
                            </div>
                            }
                        />
                    </List.Item>
                </List>
            </div>
        </div >
    );
}

export default ProductList;