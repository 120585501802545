import { useState, lazy, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './styles.less'
import Request from '../../request'
import _ from 'lodash';
import moment from 'moment'
import Upgradeplan from '../settings/upgradePlan'
import { CreateAccount } from '../constant/utils';

const Calltype = lazy(() => import('./callTypeChart'))
const LiveCallsTable = lazy(() => import('./liveCallsTable'))

const HomeView = (props) => {
  const dispatch = useDispatch()
  const [state, setState] = useState({})
  const [graphCallTypeState, setGraphCallTypeState] = useState({})
  const [loading, setLoader] = useState(false)
  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser
  }))


  const apiFunction = async () => {
    setLoader(true)
    let { user, token, success, message, data } = await Request.dashboard()
    setLoader(false)
    if (success) {
      setState(data)
    } else {
    }
  }
  const apiFunctionForGraph = async () => {
    setLoader(true)
    let { user, token, success, message, data } = await Request.graph()
    setLoader(false)
    if (success) {
      setFirstGraphData(data)
      // setSecondGraphData(data)
    } else {
    }
  }

  useEffect(() => {
    checkFunctionForPlans()
  }, [])
  const checkFunctionForPlans = async () => {
    let { data, success } = await Request.getProfile()
    if (success) {
      dispatch({
        type: 'SET_CURRENT_USER',
        payload: { ...data }
      })
    }
  }

  const setFirstGraphData = (data) => {
    let mainARR = []
    let callsCallTypeLabel = []
    let incoming = []
    let outgoing = []
    let missed = []

    _.forEach(data?.calltype, (call, key) => {
      callsCallTypeLabel.push(moment.utc(key, 'YYYY-MM-DD').format('DD-MM'))

      incoming.push(call?.incoming)
      outgoing.push(call?.outgoing)
      missed.push(call?.missed)
    })
    mainARR.push({ data: outgoing, name: 'Outgoing Calls', color: "#293275" })
    mainARR.push({ data: incoming, name: 'Incoming Calls', color: "#04932f" })
    mainARR.push({ data: missed, name: 'Missed Calls', color: "#ff2b2b" })

    setGraphCallTypeState({ label: callsCallTypeLabel, data: mainARR, ...data })

  }
  // const setSecondGraphData = (data) => {
  //   let callsLabel = []
  //   let callsData = []
  //   _.forEach(data?.calls, call => {
  //     callsLabel.push(moment.utc(call?.date, 'YYYY-MM-DD').format('DD-MM'))
  //     callsData.push(call?.count)
  //   })
  //   setGraphState({ label: callsLabel, data: callsData })
  // }
  useEffect(() => {
    apiFunction()
    apiFunctionForGraph()
  }, [])

  // let elemWidth = document.getElementById('box').offsetWidth;  
  // console.log(elemWidth,"elemWidth")

  const container = async () => {
    let { data, success } = await Request.checkEndpointStatus()
    CreateAccount(currentUser, data, success, dispatch)
  }

  useEffect(() => {
    window.onbeforeunload = function (e) {
      //   if (sessionCall && endpoint) {
      //     sessionStorage.setItem("sessionCall", sessionCall);
      //   }
      sessionStorage.setItem("reloading", "true");
      document.location.reload();
      //   e.returnValue = " your call will terminate "
    }

  }, []);
  useEffect(() => {
    window.onload = function () {
      container();
      var reloading = sessionStorage.getItem("reloading");
      //   var sessionCall1 = sessionStorage.getItem("sessionCall");
      if (reloading) {
        // if (sessionCall1) {
        //   sessionCall?.terminate();
        // }
        // sessionStorage.removeItem("sessionCall");
        sessionStorage.removeItem("reloading");
        container();
      }
    }
  }, []);

  if (!currentUser?.balance?.dashboard) {
    return <Upgradeplan />
  }

  return (<div>
    <div className="flex flexDirec jcsb" >
      <div className="flex flexDirec1 widt100" style={{ width: '50.5%' }}>
        <div className="callingFirst widt768" style={{ width: '22%' }}>
          <div className="callingParent marginTop">
            <div className="callingNumber">
              {graphCallTypeState?.total}
            </div>
            <div className="callingLabel">
              Calls this Month
            </div>
          </div>
          <div className="callingParent marginTop">
            <div className="callingSubLabel">
              {graphCallTypeState?.outgoing} : Outgoing Calls
            </div>

            <div className="callingSubLabel">
              {graphCallTypeState?.incoming} : Incoming Calls
            </div>
            <div className="callingSubLabel">
              {graphCallTypeState?.missed} : Missed Calls
            </div>
          </div>
        </div>
        <div className="widt100" style={{ width: '76%' }}>
          <Calltype labels={graphCallTypeState?.label} data={graphCallTypeState?.data} />

        </div>
      </div>
      <div className="widt100 marTop" style={{ width: '49.5%' }}>
        <div className="flex jcsb">
          <div className="marginRight summaryCard" style={{ backgroundColor: '#FA424A' }}>
            <div className="dashUserSummary">{state?.totalfreeminutes}</div>
            <div className="dashSubValueSummary">Minutes Left</div>
          </div>
          <div className="summaryCard" style={{ backgroundColor: '#008FFB' }}>
            <div className="dashUserSummary">{state?.avghandletime || 0}</div>
            <div className="dashSubValueSummary">Avg Call Time</div>
          </div>
        </div>
        <div className="flex jcsb marginTop">
          <div className="marginRight summaryCard" style={{ backgroundColor: '#FDAD2A' }}>
            <div className="dashUserSummary">{state?.livecalls}</div>
            <div className="dashSubValueSummary">Live Calls</div>
          </div>
          <div className="summaryCard" style={{ backgroundColor: '#4DC564' }}>
            <div className="dashUserSummary">{state?.queue || 0}</div>
            <div className="dashSubValueSummary">Calls in Queue</div>
          </div>
        </div>
      </div >
    </div > 
    <LiveCallsTable  />
  </div >
  )
}

export default HomeView;