import { useState, useEffect } from 'react';
import { Card, DatePicker, Tooltip, Image, Select, message, Table, Tabs, Button, Input, Tag, notification } from 'antd';
import { useSelector } from 'react-redux';
import './styles.less'
import { AudioOutlined } from '@ant-design/icons';
import FilterTable from '../../components/Table'
import Request from '../../request'
import { DateFormat } from '../../settings'
import _ from 'lodash'
import moment from 'moment'

const { RangePicker } = DatePicker;

const HomeView = (props) => {

    const { currentUser } = useSelector((state) => ({
        currentUser: state.global.currentUser
    }))
    const [dataSource, setDataSource] = useState([])
    const [loading, setLoader] = useState(false)
    const [filters, setFilters] = useState({})


    const apiFunction = async (filter) => {
        setLoader(true)
        let { user, token, success, message, data } = await Request.customerListDispositions({ startDate: filter?.date[0], endDate: filter?.date[1] })
        setLoader(false)
        if (success && data.length) {
            setDataSource(data)
        } else {
            setDataSource([])

            // notification.error({
            //   message: message || "Failed"
            // })
        }
    }

    useEffect(() => {
        setFilters({ date: [moment().subtract('days', 7), moment()] })
        apiFunction({ date: [moment().subtract('days', 7), moment()] })
    }, [])

    const columns = [
        {
            title: 'Phone Number',
            dataIndex: 'phone',
            key: 'phone'
        },
        {
            title: 'Caller',
            dataIndex: 'caller',
            key: 'caller',
            render: (val, r) => {
                return <>{r?.caller?.auth?.firstname ? r?.caller?.auth?.firstname : ""} {r?.caller?.auth?.lastname ? r?.caller?.auth?.lastname : ""}</>
            }
        },
        {
            title: 'Disposition',
            dataIndex: 'dispositionid',
            key: 'dispositionid',
            render: (val, r) => {
                return <>{r?.dispositionid}</>
            }
        },
        {
            title: 'Time',
            dataIndex: 'createdon',
            key: 'createdon',
            render: (val, r) => {
                return <>{moment.utc(val).format('DD-MM-YYYY')} &nbsp;&nbsp; {moment.utc(val).format('LTS')}</>
            }
        },
    ];

    const downloadXls = async (filter) => {
        if (filter) {
            setLoader(true)
            const data = await Request.downloadDispositionReport({ startDate: filter[0], endDate: filter[1], download: "download" });
            setLoader(false)
        }
    }

    const emailMe = async (filter) => {
        setLoader(true)
        let { data, message, success } = await Request.emailmedisposition({ startDate: filter[0], endDate: filter[1], email: "email" })
        if (success) {
            notification.success({
                message: message || 'Email sent successfully'
            })
        } else {
            notification.error({
                message: message || 'Failed'
            })
        }
        setLoader(false)
    }

    return <div>
        <h2>Detailed Disposition Report</h2>
        <div className="flex marginRight jcsb ">

            <div className="flex marginRight ">
                <div className="marginRight">
                    <RangePicker onChange={(v) => setFilters((prev) => ({ ...prev, date: v }))} value={filters?.date} />
                </div>
                <Button type="primary" className="buttonPrimaryStyle" loading={loading} onClick={() => apiFunction(filters)}>
                    Search
                </Button>
            </div>
            <Button type="secondary" className="buttonPrimaryStyle" loading={loading} onClick={() => downloadXls(filters.date)}>
                Download .xls/.csv
            </Button>
            <Button type="secondary" className="buttonPrimaryStyle" style={{ marginLeft: '15px' }} loading={loading} onClick={() => emailMe(filters.date)}>
                Email Me
            </Button>
        </div>
        <div className="marginTopLarge">
            <FilterTable size="small" bordered dataSource={dataSource} columns={columns} loading={loading} scroll={{ x: 'max-content' }} />
        </div>
    </div>
}

export default HomeView;