import { useState, useEffect } from "react";
import { DatePicker, Select, Button, Tag, notification } from "antd";
import { useSelector } from "react-redux";
import "./styles.less";
import Request from "../../request";
import moment from "moment";
import _ from "lodash";
import FilterTable from "../../components/Table";
import UpgradePlan from "../settings/upgradePlan";
const { RangePicker } = DatePicker;

const CallerJobTime = (props) => {
  const [loader, setLoader] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [pageTotal, setPageTotal] = useState();
  const [filters, setFilters] = useState({});
  const [callers, setCaller] = useState([]);

  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser,
  }));

  const apiFunction = async (filters) => {
    let newFilter = _.cloneDeep(filters);
    if (newFilter.date) {
      newFilter = {
        ...newFilter,
        startDate: newFilter?.date[0],
        endDate: newFilter?.date[1],
      };
      delete newFilter.date;
    } else {
      delete newFilter.date;
    }
    setLoader(true);
    let { user, token, success, message, data, total } =
      await Request.fetchCallerJobTime({
        ...newFilter,
        pagination: "pagination",
      });
    setLoader(false);
    if (success && data.length) {
      setDataSource(data);
      setPageTotal(total);
    } else {
      setDataSource([]);
    }
  };

  const filterCallerListing = async () => {
    setLoader(true);
    let { user, token, success, message, data, total } =
      await Request.fetchCallerJobTime();
    setLoader(false);
    if (success && data.length) {
      setCaller(data);
    } else {
      setCaller([]);
    }
  };

  useEffect(() => {
    setFilters({ date: [moment().subtract("days", 10), moment()] });
    apiFunction({ date: [moment().subtract("days", 10), moment()] });
    filterCallerListing();
  }, []);

  const columns = [
    {
      title: "Caller",
      dataIndex: "username",
      key: "username",
      width: 150,
      fixed: "left",
      render: (val, r) => {
        return <>{val + " " + r?.lastname}</>;
      },
    },
    {
      title: "Time",
      dataIndex: "logdatetime",
      key: "logdatetime",
      render: (val, r) => {
        return <>{moment.utc(val).format("DD-MM-YYYY HH:mm:ss")}</>;
      },
    },
    {
      title: "Event Type",
      dataIndex: "logtype",
      key: "logtype",
      render: (val, r) => {
        return (
          <Tag color={val?.toLowerCase() == "login" ? "green" : "red"}>
            {_.startCase(val)}
          </Tag>
        );
      },
    },
  ];

  const invDownlod = async (filters) => {
    let newFilter = _.cloneDeep(filters);
    if (newFilter.date) {
      newFilter = {
        ...newFilter,
        startDate: newFilter?.date[0],
        endDate: newFilter?.date[1],
      };
      delete newFilter.date;
    } else {
      delete newFilter.date;
    }
    setLoader(true);
    let { user, token, success, message, data } =
      await Request.downloadCallerJobTime(newFilter);
    setLoader(false);
  };

  const emailMe = async (filter) => {
    let newFilter = _.cloneDeep(filters);
    if (newFilter.date) {
      newFilter = {
        ...newFilter,
        email: "email",
        startDate: newFilter?.date[0],
        endDate: newFilter?.date[1],
      };
      delete newFilter.date;
    } else {
      delete newFilter.date;
    }
    setLoader(true);
    let { data, message, success } = await Request.emailmeloginlogout(
      newFilter
    );
    if (success) {
      notification.success({
        message: message || "Email sent successfully",
      });
    } else {
      notification.error({
        message: message || "Failed",
      });
    }
    setLoader(false);
  };

  if (!currentUser?.plan?.callrecording) {
    return <UpgradePlan />;
  }

  return (
    <div>
      <>
        <h2>Caller Login Logout Report</h2>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: "5px",
          }}
        >
          <RangePicker
            onChange={(v) => setFilters((prev) => ({ ...prev, date: v }))}
            value={filters?.date}
          />
          <Select
            showSearch
            allowClear={true}
            onChange={(val) => setFilters((prev) => ({ ...prev, caller: val }))}
            style={{
              width: 200,
              border: "1px solid #e1e3e8",
              borderRadius: "4px",
            }}
            placeholder="Select Callers"
            value={filters?.caller}
            options={_.map(callers, (val) => {
              return { label: val?.username, value: val?.user };
            })}
          />
          <Button
            type="primary"
            className="buttonPrimaryStyle"
            loading={loader}
            onClick={() => apiFunction(filters)}
          >
            Search
          </Button>
        </div>
        <div className="marginTop">
          <div style={{ marginBottom: 16 }}>
            <Button
              type="primary"
              onClick={() => invDownlod(filters)}
              loading={loader}
            >
              Download
            </Button>
            <Button
              type="primary"
              style={{ marginLeft: "15px" }}
              onClick={() => emailMe(filters)}
              loading={loader}
            >
              Email Me
            </Button>
          </div>
          <FilterTable
            pagination={{
              onChange: (page) => {
                apiFunction({ ...filters, page: page });
              },
              pageSize: 25,
              total: pageTotal,
              hideOnSinglePage: true,
              showSizeChanger: false,
            }}
            bordered
            size="small"
            dataSource={dataSource}
            columns={columns}
            loading={loader}
            scroll={{ x: "max-content" }}
          />
        </div>
      </>
    </div>
  );
};

export default CallerJobTime;
