import { useState, lazy, useEffect } from 'react';
import { Card, Space, Tooltip, notification, Radio, Upload, Table, Tabs, Button, Input, Tag, InputNumber } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
// import './styles.less'
import { UploadOutlined, DeleteColumnOutlined, SearchOutlined, DeleteOutlined, DeleteTwoTone, EditOutlined } from '@ant-design/icons';
import { getPushPathWrapper } from '../../../routes';
import Request from '../../../request'
import moment from "moment";
import { confirmBox } from '../../../utils';
import FilterTable from '../../../components/Table'
import UpgradePlan from '../../settings/upgradePlan'
import { ExcelFormat, MaxExcelSize } from '../../../settings'
const { Search } = Input;


const { TabPane } = Tabs;

const HomeView = (props) => {
    const dispatch = useDispatch()
    const { currentUser } = useSelector((state) => ({
        currentUser: state.global.currentUser
    }))
    const [dataSource, setDataSource] = useState([])
    const [loading, setLoader] = useState(false)
    const [formLoader, setFormLoader] = useState(false)
    const [formFileData, setData] = useState({})
    const [form, setForm] = useState()
    const [code, setCode] = useState()

    const onSearch = value => console.log(value);

    const deleteNumber = (fields) => {
        confirmBox(dispatch, {
            message: 'Are you sure you want to Delete',
            onOk: async () => {
                setLoader(true)
                let { user, token, success, message, data } = await Request.deleteDnc(fields?.id)
                setLoader(false)
                if (success) {
                    notification.success({
                        message: message || "success"
                    })
                    apiFunction()
                } else {
                    notification.error({
                        message: message || "Failed"
                    })
                }
            }
        })
    }
    const onAdd = async () => {
        // console.log(code, form)
        if (!(code)) {
            notification.warning({
                message: 'Required Fields',
                description: 'Choose phonecode'
            })
            return
        }
        if (!(form)) {
            notification.warning({
                message: 'Required Fields',
                description: 'Choose number'
            })
            return
        }
        setFormLoader(true)
        let { user, token, success, message, data } = await Request.addDnc({ number: code + '' + form })
        setFormLoader(false)
        if (success) {
            notification.success({
                message: message || "added"
            })
            setCode()
            setForm()
            apiFunction()
        } else {
            notification.error({
                message: message || "Failed",
                description: JSON.stringify(data?.number[0])?.replace('"', '')?.replace('[', '')?.replace('{', '')?.replace(']', '')?.replace('}', '')?.replace('"', '')

            })
        }
    }
    const columns = [
        {
            title: 'Phone Number',
            dataIndex: 'number',
            search: true,
            key: 'number',
        },
        // {
        //     title: 'Phone Code',
        //     dataIndex: 'phonecode',
        //     key: 'phonecode',
        // },
        // {
        //     title: 'Active',
        //     dataIndex: 'active',
        //     key: 'active',
        //     render: (val, r) => {
        //         return <Tag color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
        //     }
        // },
        // {
        //     title: 'Toll-Free',
        //     dataIndex: 'tollfree',
        //     key: 'tollfree',
        //     render: (val, r) => {
        //         return <Tag color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
        //     }
        // },
        // {
        //   title: 'Created At',
        //   dataIndex: 'createdon',
        //   key: 'createdon',
        //   render: (val, r) => {
        //     return <Tag>{moment.utc(val).format('DD-MM-YYYY')}</Tag>
        //   }
        // },

        {
            title: 'Actions',
            dataIndex: 'Action',
            key: 'Action',
            render: (val, record) => {
                return <div className="flex" style={{ paddingLeft: 5, justifyContent: 'center' }}>
                    <div  >
                        <DeleteOutlined style={{ color: "red", fontSize: 20 }} onClick={() => deleteNumber(record)} />
                    </div>
                </div>
            }
        },
    ];
    const apiFunction = async () => {
        setLoader(true)
        let { user, token, success, message, data } = await Request.ListDnc()
        setLoader(false)
        if (success) {
            setDataSource(() => data)
        } else {
            setDataSource(() => [])

            // notification.error({
            //   message: message || "Failed"
            // })
        }
    }
    const onChangeFile = (info, key) => {
        const nextState = {};
        switch (info.file.status) {
            case "uploading":
                nextState.selectedFile = info.file;
                nextState.id = [info.file];
                setData((prev) => ({ ...prev, [key]: info.file }))
                break;
            case "done":
                nextState.selectedFile = info.file;
                nextState.id = [info.file];
                setData((prev) => ({ ...prev, [key]: info.file }))
                break;

            default:
                // error or removed
                nextState.selectedFile = null;
                nextState.id = [];
                setData((prev) => ({ ...prev, [key]: null }))
        }
    };
    const onFinish = async () => {
        if ((!formFileData?.doc_1)) {
            notification.error({
                message: "Please Upload file"
            })
            return
        }
        const MAINFORM = new FormData();

        MAINFORM.append('file', formFileData?.doc_1?.originFileObj)
        // console.log(MAINFORM.getAll('file'))
        // return
        setLoader(true)
        let { user, success, message, data } = await Request.uploadDnc(MAINFORM)
        if (success) {
            notification.success({
                message: message || "success",
                // description: 'Successfully Registered Please Login To Continue'
            })
            apiFunction()
            setData(() => ({ doc_1: undefined }))
        }
        else {
            notification.error({
                message: message || "Failed",
                description: JSON.stringify(data)?.replace('[', '')?.replace('{', '')?.replace(']', '')?.replace('}', '')

            })
        }
        setLoader(false)
    }

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const beforeUpload = (file) => {
        const isLt2M = file.size / 1024 / 1024 < MaxExcelSize;
        if (!isLt2M) {
            notification.warning({
                message: `Image must smaller than ${MaxExcelSize}MB!`
            })
            return false;
        }
        return true
    };

    useEffect(() => {
        apiFunction()
    }, [])
    if (!currentUser?.balance?.dnc) {
        return <UpgradePlan />
    }

    return <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', rowGap: '10px' }}>
            <div>
                <InputNumber
                    placeholder="phonecode"
                    maxLength={5}
                    controls={false}
                    min={1}
                    value={code}
                    onChange={(number) => setCode(() => (number))}
                    style={{ borderRadius: '4px', width: 100 }}
                />
                <InputNumber
                    placeholder="Enter Number"
                    maxLength={15}
                    controls={false}
                    min={1}
                    value={form}
                    onChange={(number) => setForm(() => (number))}
                    style={{ borderRadius: '4px', width: 250 }}
                />
                <Button type="primary" className="buttonPrimaryStyle" loading={formLoader} onClick={() => onAdd()}>
                    Add DNC</Button>
            </div>
            <div style={{ display: 'flex' }}>
                {/* {console.log(formFileData, "formFileData")} */}
                <Upload beforeUpload={beforeUpload} name="logo" onChange={(p) => onChangeFile(p, 'doc_1')}
                    fileList={formFileData?.doc_1 ? [formFileData?.doc_1] : []}
                    customRequest={dummyRequest} accept={ExcelFormat}>
                    <Button icon={<UploadOutlined />}>Upload .csv/Excel</Button>
                </Upload>
                <Button type="primary" className="buttonPrimaryStyle" loading={formLoader} onClick={() => onFinish()}>
                    Upload File
                </Button>
            </div>
        </div>
        <div className="marginTop">
            <FilterTable title={() => <div style={{ fontWeight: "bold", fontSize: 18 }}>List of DNC Numbers
            </div>} bordered dataSource={dataSource} columns={columns} loading={loading} />

        </div>
    </div >
}

export default HomeView;