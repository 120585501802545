import { useState, lazy, useEffect } from 'react';
import { Card, Space, Tooltip, Image, Radio, List, Table, Tabs, Button, Input } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import './styles.less'
import {
  AudioOutlined, MenuUnfoldOutlined, DatabaseOutlined, DollarOutlined,
  CreditCardOutlined, MenuFoldOutlined, CloudUploadOutlined, SoundOutlined, BarChartOutlined, LineChartOutlined
} from '@ant-design/icons';
import { InfoCircleOutlined, UserOutlined, SearchOutlined } from '@ant-design/icons';
import { ReactComponent as Information } from '../../assets/information.svg'
import Reports from '../reports/report'
import ScheduledCalls from '../scheduledCalls/list'
import History from '../callHistory/history'
import AgentSummary from '../reports/agentscallSummary'
import DailyCallSummary from '../reports/dailyCalls'
import Recordings from '../reports/recording'
import PreviousPayments from '../reports/paymentsSummery'
import { ReactComponent as Activity } from '../../assets/activity.svg'
import { ReactComponent as ReportsSvg } from '../../assets/reports.svg'
import { ReactComponent as Phone } from '../../assets/sscheduled.svg'


// import Department from './list'
// import LeadsetData from './leadsetData'
import { Link, Route } from 'react-router-dom'

import _ from 'lodash'

import { confirmBox } from '../../utils';

const { Search } = Input;


const { TabPane } = Tabs;

let dept = false
const LIST = [

  {
    name: 'Call History',
    active: true,
    icon: <Phone style={{ fontSize: 20, height: 20, width: 20 }} />,
  },

  {
    name: 'Agent Call Summary',
    active: false,
    icon: <LineChartOutlined style={{ fontSize: 20 }} />,
  },
  {
    name: 'Daily Call Summary',
    active: false,
    icon: <BarChartOutlined style={{ fontSize: 20 }} />,
  },
  {
    name: 'Recordings',
    active: false,
    icon: <SoundOutlined style={{ fontSize: 20 }} />,
  },

  {
    name: 'Scheduled Calls',
    active: false,
    icon: <Activity style={{ fontSize: 20, height: 20, width: 20 }} />,
  },
  {
    name: 'Payment History',
    active: false,
    icon: <DollarOutlined style={{ fontSize: 20, height: 20, width: 20 }} />,
  },



];


const DeptList = (props) => {
  const dispatch = useDispatch()
  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser
  }))
  const [state, setState] = useState({})
  const [visible, setVisible] = useState()

  const suffix = (
    <AudioOutlined
      style={{
        fontSize: 16,
        color: '#1890ff',
      }}
    />
  );
  useEffect(() => {
    setState({ dataSource: _.cloneDeep(LIST), selectedKey: _.cloneDeep(LIST)[0] })

  }, [])
  const onChange = (index) => {
    let newData = _.map(state?.dataSource, (e) => {
      e.active = false;
      return (e

      )

    })
    if (index >= 0)
      newData[index].active = true


    setState((prev) => ({
      ...prev,
      categories: newData,
      selectedKey: newData[index]

    }))
  }


  const onSearch = value => console.log(value);
  const [open, setOpen] = useState(false);

  return <div>
    <div className='flex' style={{ alignItems: 'flex-start' }}>
      <div>
        <Button style={{ borderRadius: '6px', marginBottom: '5px', width: '58px' }} onClick={() => { setOpen(!open) }}>{!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}</Button>
        <Card className={`SettingMenu ${open ? null : 'open'}`}>
          <List
            // loading={categoryLoading}
            size="small"
            dataSource={state.dataSource}

            renderItem={(item, i) => (

              <List.Item
                key={item.id}
                onClick={() => onChange(i)}

                //  onClick={() => visit(i) } 

                className={`settingListItem ${item?.active && 'settingListItemSelected'}`}


              >

                <List.Item.Meta avatar={item.path == '/callHistory' ? <Link to='/callHistory'>{item?.icon}</Link> : <>{item?.icon}</>

                } title={item?.name}></List.Item.Meta>
              </List.Item>
            )}
          />

        </Card>
      </div>



      <div className='settingListWrapper'>


        {/* {state?.selectedKey?.name ==   && <Reports /> }  */}
        {state?.selectedKey?.name == 'Call History' && <History />}

        {state?.selectedKey?.name == 'Agent Call Summary' && <AgentSummary />}

        {state?.selectedKey?.name == 'Daily Call Summary' && <DailyCallSummary />}

        {state?.selectedKey?.name == 'Recordings' && <Recordings />}

        {state?.selectedKey?.name == 'Scheduled Calls' && <ScheduledCalls />}

        {state?.selectedKey?.name == 'Payment History' && <PreviousPayments />}

      </div>
    </div>
  </div>
}

export default DeptList;