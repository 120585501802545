let initialState=[""];

const putTheData =(state=initialState, action)=>{

    switch(action.type) {

        case "PUT": return state= action.payload;

        default: return state; 
    }

}
export default putTheData;