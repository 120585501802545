import { useState, lazy, useEffect } from 'react';
import { Card, Space, Tooltip, Image, Radio, List, Table, Tabs, Button, Input } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import './styles.less'
import { PhoneOutlined, AudioOutlined, ProfileOutlined, MenuUnfoldOutlined, CreditCardOutlined, MenuFoldOutlined } from '@ant-design/icons';
import Numbers from './CompanyNo/listNumbers'
import DIDNumbers from './DIDNumber/listDidNumbers'
import CallQueue from '../settings/CallQueue'
import _ from 'lodash';
import { push } from 'connected-react-router';
const { Search } = Input;

const LIST = [
  {
    name: 'Numbers',
    active: true,
    icon: <ProfileOutlined style={{ fontSize: 20 }} />

  },
  {
    name: 'External DIDs',
    active: false,
    icon: <CreditCardOutlined style={{ fontSize: 20 }} />

  },
  {
    name: 'Call Priority Queue',
    active: false,
    icon: <PhoneOutlined style={{ fontSize: 20 }} />

  },
];

const HomeView = (props) => {
  const dispatch = useDispatch()
  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser
  }))
  const [state, setState] = useState({})

  const suffix = (
    <AudioOutlined
      style={{
        fontSize: 16,
        color: '#1890ff',
      }}
    />
  );
  useEffect(() => {
    setState({ dataSource: _.cloneDeep(LIST), selectedKey: _.cloneDeep(LIST)[0] })

  }, [])
  const onChange = (index) => {
    let newData = _.map(state?.dataSource, (e) => {
      e.active = false;
      return e
    })
    if (index >= 0)
      newData[index].active = true
    setState((prev) => ({
      ...prev,
      categories: newData,
      selectedKey: newData[index]
    }))
  }
  const onSearch = value => console.log(value);
  const [open, setOpen] = useState(false);

  return <div>
    <div className='flex' style={{ alignItems: 'flex-start' }}>
      <div>
        <Button style={{ borderRadius: '6px', marginBottom: '5px', width: '58px' }} onClick={() => { setOpen(!open) }}>{!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}</Button>
        <Card className={`SettingMenu ${!open && 'open'}`}>
          <List
            // loading={categoryLoading}
            size="small"
            dataSource={state.dataSource}
            renderItem={(item, i) => (
              <List.Item
                key={item.id}
                title={item.name}
                onClick={() => onChange(i)}
                className={`settingListItem ${item?.active && 'settingListItemSelected'}`}
              >

                <List.Item.Meta avatar={
                  item?.icon
                } title={item?.name}></List.Item.Meta>
              </List.Item>
            )}
          />

        </Card>
      </div>
      <div
        className='settingListWrapper'
      >
        {state?.selectedKey?.name == 'Numbers' && <Numbers />}
        {state?.selectedKey?.name == 'External DIDs' && <DIDNumbers />}
        {state?.selectedKey?.name == 'Call Priority Queue' && <CallQueue />}

      </div>
    </div>
  </div>
}

export default HomeView;