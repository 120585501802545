import { useState, lazy, useEffect } from 'react';
import { Card, Space, Tooltip, Image, Radio, List, Table, Tabs, Button, Input } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import './styles.less'
import {
  AudioOutlined, ProfileOutlined, CloseCircleOutlined, ReconciliationOutlined, MenuUnfoldOutlined,
  PhoneOutlined, DollarOutlined, ContactsOutlined, WalletOutlined, QrcodeOutlined, TagOutlined, HomeOutlined, EyeInvisibleOutlined, LayoutOutlined, LogoutOutlined, SettingOutlined, CreditCardOutlined, MenuFoldOutlined
} from '@ant-design/icons';
import { InfoCircleOutlined, UserOutlined, SearchOutlined } from '@ant-design/icons';
import { ReactComponent as Information } from '../../assets/information.svg'
import SDAP from './SDAP'
import YourPlan from './YourPlan'
import PriceList from './PriceList'
import IVR from './Ivr'
// import Dnc from '../contacts/DNC/Dnc'
// import CallQueue from './CallQueue'
// import RestrictedContacts from '../contacts/restrictedcontacts/RestrictedContacts'
import RestrictedAreas from './RestrictedAreas'
import BillingContacts from './BillingContacts'
import BlockedCountries from './BlockedCountries'
import Holidays from './Holidays'
import Upgradeplan from './upgradePlan'
import ChangePassword from './changePassword'
import _ from 'lodash'
import Profile from './Profile'
import { confirmBox } from '../../utils';
import PlanDetails from './YourPlanDetails'
import Request from '../../request';
const { Search } = Input;

const HomeView = (props) => {
  const dispatch = useDispatch()
  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser
  }))
  const LIST = [
    // {
    //   name: 'SDAP',
    //   icon:<ClockCircleOutlined style={{fontSize:20}}/>

    // },
    {
      name: 'Your Plan',
      active: true,
      icon: <ProfileOutlined style={{ fontSize: 20 }} />

    },
    {
      name: 'Plan Details',
      active: false,
      icon: <CreditCardOutlined style={{ fontSize: 20 }} />

    },
    {
      name: 'Billing Contacts',
      active: false,
      icon: <WalletOutlined style={{ fontSize: 20 }} />

    },
    !currentUser?.balance?.callqueues ? {active: undefined,} :
    // {
    //   name: 'Call Priority Queue',
    //   active: false,
    //   icon: <PhoneOutlined style={{ fontSize: 20 }} />

    // },
    !currentUser?.balance?.callcostlimit ? {active: undefined,} :
    {
      name: 'Call Cost Limit',
      active: false,
      icon: <DollarOutlined style={{ fontSize: 20 }} />

    },
    // !currentUser?.balance?.dnc ? {active: undefined,} :
    // {
    //   name: 'DNC',
    //   active: false,
    //   icon: <EyeInvisibleOutlined style={{ fontSize: 20 }} />

    // },
    !currentUser?.balance?.holidayrouting ? {active: undefined,} :
    {
      name: 'Holidays',
      active: false,
      icon: <HomeOutlined style={{ fontSize: 20 }} />

    },
    {
      name: 'Blocked Countries',
      active: false,
      icon: <CloseCircleOutlined style={{ fontSize: 20 }} />

    },
    !currentUser?.balance?.callholdwithmusic ? {active: undefined,} :
    {
      name: 'IVR & Prompts',
      active: false,
      icon: <TagOutlined style={{ fontSize: 20 }} />

    },
    // {
    //   name: 'Upgradeplan',
    //   active: false,
    //   icon: <ReconciliationOutlined style={{ fontSize: 20 }} />

    // },
    // !currentUser?.balance?.callblocking ? {active: undefined,} :
    // {
    //   name: 'Restricted Contacts',
    //   active: false,
    //   icon: <ContactsOutlined style={{ fontSize: 20 }} />

    // },
    !currentUser?.balance?.callblocking ? {active: undefined,} :
    {
      name: 'Restricted Areas',
      active: false,
      icon: <QrcodeOutlined style={{ fontSize: 20 }} />

    }, {
      name: 'Profile',
      active: false,
      icon: <UserOutlined style={{ fontSize: 20 }} />

    },
    {
      name: 'Change Password',
      active: false,
      icon: <SettingOutlined style={{ fontSize: 20 }} />

    },
  ];
  const [state, setState] = useState({})
  const [dataSource2, setDataSource2] = useState([])
  const [plan,setPlan] = useState([])
  const [loader,setLoader] =  useState(false)

  const apiFunction = async () => {
    setLoader(true)
    let { data, success } = await Request.getProfile()
    setLoader(false)
    if (success) {
        setDataSource2(() => data)
        setPlan(data?.plan?.planname)
    } else {
        setDataSource2(() => { })
        // notification.error({
        //   message: message || "Failed"
        // })
    }
}

useEffect(() => {
  apiFunction()
}, [currentUser])

// const LIST = LIST1.filter((e)=>{

//   console.log(e.planname,"planname")
  
// // if(e.planname[0]=="starter")
//  return e.planname=="medium"


// //  || e.planname[1]=="medium"||e.planname[2]=="large"

// })

  const suffix = (
    <AudioOutlined
      style={{
        fontSize: 16,
        color: '#1890ff',
      }}
    />
  );
  useEffect(() => {
   
    setState({ dataSource: _.cloneDeep(LIST), selectedKey: _.cloneDeep(LIST)[0] })

    console.log(state,"dataSource")

  }, [])
  const onChange = (index) => {
    let newData = _.map(state?.dataSource, (e) => {
      // console.log(e,"#############")
      // console.log(state,"$$$$$$$$$")
      if(e.active == undefined){
        e.active = undefined
      }else{
      e.active = false;
      }
      return e
    })
    if (index >= 0)
      newData[index].active = true
    setState((prev) => ({
      ...prev,
      categories: newData,
      selectedKey: newData[index]
    }))
  }
  const onSearch = value => console.log(value);
  const [open, setOpen] = useState(false);

  return <div>
    <div className='flex' style={{ alignItems: 'flex-start' }}>
      <div>
        <Button style={{ borderRadius: '6px', marginBottom: '5px', width: '58px' }} onClick={() => { setOpen(!open) }}>{!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}</Button>
        <Card className={`SettingMenu ${!open && 'open'}`}>
          <List
            // loading={categoryLoading}
            size="small"
            dataSource={state.dataSource}
            renderItem={(item, i) => (
              <List.Item
                key={item.id}
                title={item.name}
                onClick={() => onChange(i)}
                className={`settingListItem ${item?.active && 'settingListItemSelected'}`}
              >

                <List.Item.Meta avatar={
                  item?.icon
                } title={item?.name}></List.Item.Meta>
              </List.Item>
            )}
          />

        </Card>
      </div>
      <div
        className='settingListWrapper'
      >
        {state?.selectedKey?.name == 'SDAP' && <SDAP />}
        {state?.selectedKey?.name == 'Your Plan' && <YourPlan />}
        {state?.selectedKey?.name == 'Plan Details' && <PlanDetails />}
        {state?.selectedKey?.name == 'Billing Contacts' && <BillingContacts />}
        {state?.selectedKey?.name == 'Call Cost Limit' && <PriceList />}
        {/* {state?.selectedKey?.name == 'DNC' && <Dnc />} */}
        {state?.selectedKey?.name == 'IVR & Prompts' && <IVR />}
        {/* {state?.selectedKey?.name == 'Call Priority Queue' && <CallQueue />} */}
        {/* {state?.selectedKey?.name == 'Restricted Contacts' && <RestrictedContacts />} */}
        {state?.selectedKey?.name == 'Restricted Areas' && <RestrictedAreas />}
        {state?.selectedKey?.name == 'Holidays' && <Holidays />}
        {state?.selectedKey?.name == 'Blocked Countries' && <BlockedCountries />}
        {state?.selectedKey?.name == 'Profile' && <Profile />}
        {state?.selectedKey?.name == 'Change Password' && <ChangePassword />}
        {/* {state?.selectedKey?.name == 'Upgradeplan' && <Upgradeplan />} */}


      </div>
    </div>
  </div>
}

export default HomeView;