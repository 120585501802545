import { useState, lazy } from 'react';
import { Card, Space, Carousel, Image, Radio, message, Modal, Tabs } from 'antd';
import { useSelector } from 'react-redux';
import './styles.less'
import Completed from './completed'
import Scheduled from './scheduled'
const { TabPane } = Tabs;

const HomeView = (props) => {

  const [state, setState] = useState({
    data: [],
    loading: false,
    hasMore: true,
    orderStatus: 'PENDING',
    showQr: false
  })
  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser
  }))
  const callback = (key) => {
    // console.log(key);
  }
  return  <div >
  <h2>Scheduled Calls</h2>
  <div className="flex jcsb"></div>
  <div className="HomeTabs"><Tabs defaultActiveKey="1" onChange={callback} size={"large"}>
    <TabPane tab="Scheduled" key="1">
      <Scheduled />
  </TabPane>
    <TabPane tab="Completed" key="2">
      <Completed />
  </TabPane>
  </Tabs>
  </div>
  </div>
}

export default HomeView;