import React from "react";
import { Result, Button } from 'antd';
import { push } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';

function PageNotFound() {

    const dispatch = useDispatch()
    return (
        <>
            <Result
                status="404"
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column"
                }}
                title={<div style={{ fontSize: 35, margin: 10 }}>404</div>}
                subTitle={<div style={{ fontSize: 20, margin: 10 }}>Sorry, the page you visited does not exist.</div>}
                extra={[
                    <Button type="primary" onClick={() => {
                        dispatch(push('/'))
                    }} key="console" style={{ margin: 10 }}>
                        Go to Dashboard
                    </Button>
                ]}
            />
        </>
    );
}

export default PageNotFound;  
