import react, { useEffect, useState } from 'react'
import { Form, Input, Button, notification, Card } from 'antd';
import FormItem from '../../components/FormItem'
import './styles.less'
import { useSelector, useDispatch } from 'react-redux';
import Request from '../../request';
import async from 'async'
import { getUrlParams } from '../../routes';
import { push } from 'connected-react-router';
import _, { slice } from 'lodash'
const layout = {
    // labelCol: {
    //     span: 8,
    // },
    // wrapperCol: {
    //     span: 16,
    // },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

const Demo = (props) => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [loader, setLoader] = useState(false)
    const [params, setParams] = useState(false)
    const [dialerStat, setDialerStat] = useState(false)
    const [dialerStat1, setDialerStat1] = useState(false)
    const [numbers, setNumber] = useState([])
    const [countries, setCountries] = useState([])
    const [radioPlan, setRadioPlan] = useState([])
    const [RadioType, setRadioType] = useState([])
    const [value1, setValue] = useState('autoOff')

    const { currentUser } = useSelector((state) => ({
        currentUser: state.global.currentUser
    }))

    useEffect(() => {
        if (RadioType != 'predictive') {
            const mainData = {
                maxdialcount: 1,
                maxdialratio: 1,
                maxdropcalls: 0.1,
            }
            form.setFieldsValue(mainData)
        }
    }, [RadioType])

    const onFinish = async (values) => {
        let valData = _.cloneDeep(values)

        if ((valData.allocatedphones == "") || (valData.allocatedphones == "")) {
            valData.allocatedphones = null
        }

        if (params?.id) {
            let autodialerstatus = []
            if (dialerStat == 'notadded') {
                autodialerstatus.push('notadded')
            }
            else if (valData?.autodialerstatus == true || valData?.autodialerstatus == 'enabled') {
                autodialerstatus.push('enabled')
            }
            else {
                autodialerstatus.push('disabled')
            }
            // const autodialerstatus = (valData?.autodialerstatus == true || valData?.autodialerstatus == 'enabled') ? 'enabled' : 'disabled'
            const valData0 = {
                deptname: valData.deptname,
                allocatedphones: valData.allocatedphones != null ? valData.allocatedphones.join(', ') : null,
                comments: valData.comments,
                autodialerstatus: autodialerstatus[0],
                defaultcountry: valData?.defaultcountry
            }
            setLoader(true)
            let { user, token, success, error, message, data } = await Request.updateDept({ ...valData0, deptid: params?.id })
            setLoader(false)
            if (success && value1 != 'autoOn' && (autodialerstatus == 'enabled' || autodialerstatus == 'disabled' || autodialerstatus == 'notadded')) {
                notification.success({
                    message: message
                })
                setValue()
                form.resetFields()
                setDialerStat1(false)
                dispatch(push('/department'))
            }
            if (error) {
                notification.error({
                    message: message || "Failed",
                })
            }
            var customerId = data?.userid
            var departmentId = data?.id
            var autodialerstatus1 = data?.autodialerstatus == 'enabled' ? true : false
            if (success && value1 == 'autoOn') {
                var valData1 = {
                    usednc: valData.usednc,
                    answeringmachnedetection: valData.answeringmachnedetection,
                    clallerID: valData.clallerID,
                    autodialtype: valData.autodialtype,
                    maxdialcount: valData.maxdialcount,
                    maxdialratio: valData.maxdialratio,
                    maxdropcalls: valData.maxdropcalls,
                    dialtimeout: valData.dialtimeout,
                }
                var valData2 = {
                    ...valData1,
                    customerId: customerId,
                    departmentId: departmentId,
                    autodialon: autodialerstatus1
                }
                setLoader(true)
                let { user, token, success, message, data } = await Request.addAutoDialer(valData2)
                setLoader(false)
                if (success) {
                    notification.success({
                        message: message
                    })
                    setValue()
                    setDialerStat1(false)
                    dispatch(push('/department'))
                }
                else {
                    notification.error({
                        message: message || "Failed",
                    })
                }
            }

        } else {

            var valData1 = {
                usednc: valData.usednc,
                answeringmachnedetection: valData.answeringmachnedetection,
                clallerID: valData.clallerID,
                autodialtype: valData.autodialtype,
                maxdialcount: valData.maxdialcount,
                maxdialratio: valData.maxdialratio,
                maxdropcalls: valData.maxdropcalls,
                dialtimeout: valData.dialtimeout,
            }

            const MAINFORM = new FormData();

            _.forEach(valData, (value, key) => {
                // console.log(value, key)
                if (key == 'musiconhold' || key == 'ivrmusic') {
                    MAINFORM.append(key, value ? value[0]?.originFileObj ? value[0]?.originFileObj : null : null)
                }
                else if (key == 'deptname' || key == 'comments' || key == 'defaultcountry') {
                    MAINFORM.append(key, value ? value : '')
                }
                else if (key == 'allocatedphones') {
                    MAINFORM.append(key, value ? value : null)
                }
                else if (key == 'musicenabled' || key == 'ivrenabled' || key == 'isdispositions') {
                    MAINFORM.append(key, value ? value : false)
                } else if (key == 'autodialerstatus') {
                    MAINFORM.append(key, value ? 'enabled' : 'notadded')
                }
            })

            setLoader(true)
            let { user, token, success, error, message, data } = await Request.adddept(MAINFORM)
            setLoader(false)

            var customerId = data?.userid
            var departmentId = data?.id
            var autodialerstatus = data?.autodialerstatus == 'enabled' ? true : false

            if (success && !autodialerstatus) {
                notification.success({
                    message: message
                })
                form.resetFields()
                apiFunction()
                dispatch(push('/department'))
            }
            if (error) {
                notification.error({
                    message: message
                })
            }
            else if (success && autodialerstatus) {
                var valData2 = {
                    ...valData1,
                    customerId: customerId,
                    departmentId: departmentId,
                    autodialon: autodialerstatus
                }
                // console.log(...valData2, "valdayayha>>>>>>>>>>>>>>")
                setLoader(true)
                let { user, token, success, message, data } = await Request.addAutoDialer(valData2)
                setLoader(false)
                if (success) {
                    notification.success({
                        message: message
                    })
                    form.resetFields()
                    apiFunction()
                    dispatch(push('/department'))
                }
                else {
                    setLoader(true)
                    let { user, token, success, message, data } = await Request.deleteDept(departmentId)
                    setLoader(false)
                    notification.error({
                        message: `${message}` || "Failed",
                        description: JSON.stringify(data)?.replace('[', '')?.replace('{', '')?.replace(']', '')?.replace('}', '')
                    })
                }
            }
        }
    }

    const NumberFxn = async () => {
        let { user, token, success, message, data } = await Request.getAllNumbers()
        if (success) {
            setNumber(() => data)
        } else {
            setNumber(() => [])
        }
    }
    const requiredRule = [{ required: true, message: 'Please input field' }]
    const apiFunction = async () => {
        let params = getUrlParams('editDepartment', window.location.pathname)
        if (params) {
            setParams(params)
            let { user, token, success, message, data } = await Request.getDept(params?.id)
            if (success) {
                let mainData = data
                setDialerStat(mainData?.autodialerstatus)
                setDialerStat1(mainData?.autodialerstatus)
                mainData = {
                    ...mainData,
                    allocatedphones: mainData?.allocatedphones?.map(phone => phone.id),
                    ivrmusic: mainData?.ext,
                    musiconhold: mainData?.ext,
                    autodialerstatus: mainData?.autodialerstatus,
                    defaultcountry: mainData?.defaultcountry?.id
                }
                form.setFieldsValue(mainData)


            } else {
                notification.error({
                    message: message || "Failed"
                })
            }

        }
    }
    const getFunction = async () => {
        let { user, token, success, data } = await Request.getCountries()
        if (success) {
            let setOnlyCountries = []
            async.forEach(data, (value, cb) => {
                if (!value?.default_blocked) {
                    setOnlyCountries.push(value)
                }
                cb()
            }, () => {
                setCountries(() => setOnlyCountries)
            })
        } else {
            setCountries(() => [])

        }
    }

    useEffect(() => {
        radioCheck()
        getFunction()
    }, [])

    useEffect(() => {
        apiFunction()
        NumberFxn()
    }, [props])

    const normFile = (e) => {
        // console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    }
    const radioCheck = () => {
        const arr = []

        if (currentUser?.plan?.powerdialer) {
            arr.push('Power')
        }
        if (currentUser?.plan?.previewdialer) {
            arr.push('Preview')
        }
        if (currentUser?.plan?.predictivedialer) {
            arr.push('Predictive')
        }
        setRadioPlan(arr)
    }

    return (<div className='autoDialerNumber' style={{ width: 'auto', overflow: 'hidden' }}>


        <Card style={{ flex: 1, }}>
            <Form
                {...layout}
                layout='vertical'
                form={form}
                initialValues={{
                    ["dialtimeout"]: 20,
                    ["maxdialcount"]: 1,
                    ["maxdialratio"]: 1,
                    ["maxdropcalls"]: 0.1
                }}
                onFinish={onFinish}
            >
                <FormItem
                    label={'Department Name'}
                    name="deptname"
                    rules={requiredRule}
                />
                <FormItem
                    label={'Allocated Phone'}
                    name="allocatedphones"
                    initialValues=""
                    // rules={requiredRule}
                    allowClear={true}
                    mode="multiple"
                    inputType={"SELECT"}
                    options={_.map(numbers, val => { return ({ label: val?.phonenumber, value: val?.id }) })}
                />
                <FormItem
                    label={'Comments'}
                    name="comments"
                />
                {currentUser?.plan?.defaultcountrysetting && <FormItem
                    label={'Default Country Code'}
                    name="defaultcountry"
                    initialValues=""
                    inputType={"SELECT"}
                    options={_.map(countries, val => { return ({ label: `${val?.countryname}  (+${val?.phonecode})`, value: val?.id }) })}
                />}
                {!params?.id && <FormItem
                    label={'Disposition'}
                    inputType={'SWITCH'}
                    name="isdispositions"
                />}
                {currentUser?.plan?.powerdialer || currentUser?.plan?.previewdialer || currentUser?.plan?.predictivedialer ? <>
                    {!params?.id && <FormItem
                        label={'Music on hold'}
                        inputType={'SWITCH'}
                        name="musicenabled"
                    />}
                    {!params?.id && <FormItem
                        name="musiconhold"
                        label="Music on hold"
                        hidden={params?.deptname}
                        valuePropName="fileList"
                        accept={".mp3,.mp4"}
                        getValueFromEvent={normFile}
                        inputType={'UPLOAD'}
                    />}
                    {!params?.id && <FormItem
                        label={'IVR Music'}
                        inputType={'SWITCH'}
                        name="ivrenabled"
                    />}
                    {!params?.id && <FormItem
                        name="ivrmusic"
                        hidden={params?.deptname}
                        label="IVR Music File"
                        accept={".mp3,.mp4"}
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        inputType={'UPLOAD'}
                    />}
                </> : null}
                {currentUser?.plan?.powerdialer || currentUser?.plan?.previewdialer || currentUser?.plan?.predictivedialer ?
                    <FormItem
                        label={'Auto Dial Enable'}
                        inputType={'SWITCH'}
                        name="autodialerstatus"
                        defaultChecked={dialerStat == 'enabled' ? true : false}
                        checked={dialerStat == 'enabled' ? true : false}
                        onChange={(e) => { { (dialerStat1 == 'notadded' || dialerStat1 == false || params && dialerStat == 'enabled') && setValue(e == true ? 'autoOn' : 'autoOff') }; setDialerStat(dialerStat == 'enabled' ? 'disabled' : 'enabled') }}
                    />
                    : null}
                {value1 == 'autoOn' && <>
                    <FormItem
                        label={'Auto Dial Type'}
                        name="autodialtype"
                        inputType="RADIO"
                        rules={requiredRule}
                        onChange={(e) => { setRadioType(e.target.value) }}
                        radioOptions={radioPlan}
                    />
                    <FormItem
                        label={'Caller ID'}
                        min={0}
                        inputType={'NUMBER'}
                        rules={requiredRule}
                        name="clallerID"
                    />
                    <FormItem
                        label={'Dial Time Out'}
                        inputType={'NUMBER'}
                        min={20}
                        max={60}
                        name="dialtimeout"
                    />
                    <FormItem
                        label={'Max Dial Count'}
                        inputType={'NUMBER'}
                        controls={true}
                        min={1}
                        step={1}
                        max={RadioType != 'predictive' && 1}
                        name="maxdialcount"
                    />
                    <FormItem
                        label={'Max Dial Ratio'}
                        inputType={'NUMBER'}
                        controls={true}
                        min={1}
                        step={0.5}
                        // max={!currentUser?.plan?.predictivedialer ? 1 : 5}
                        max={RadioType != 'predictive' ? 1 : 5}
                        name="maxdialratio"
                    />
                    <FormItem
                        label={'Max Drop Calls(%)'}
                        inputType={'NUMBER'}
                        controls={true}
                        min={0.1}
                        // max={5}
                        max={RadioType != 'predictive' ? 0.1 : 5}
                        step={0.1}
                        name="maxdropcalls"
                    />
                    <FormItem
                        label={'Answering Machine Detection'}
                        inputType={'SWITCH'}
                        name="answeringmachnedetection"
                    />
                    <FormItem
                        label={'Use DNC'}
                        inputType={'SWITCH'}
                        name="usednc"
                    />
                </>
                }


                <div className="marginTop">
                    <FormItem
                        inputType="BUTTON"
                        tailLayout={tailLayout}
                        type="primary"
                        loading={loader}
                        title="SUBMIT"
                        htmlType="submit" />
                </div>

            </Form>
        </Card >

    </div>
    );
};

export default Demo
