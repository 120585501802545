import { useState, lazy, useEffect } from 'react';
import { Card, Space, Tooltip, notification, Switch, Select, Radio, Upload, Table, Tabs, Button, Input, Tag, InputNumber } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import './styles.less'
import { UploadOutlined, DeleteColumnOutlined, SearchOutlined, DeleteOutlined, DeleteTwoTone, EditOutlined } from '@ant-design/icons';
import { getPushPathWrapper } from '../../routes';
import _, { filter } from 'lodash'
import Request from '../../request'
import async from 'async'
import moment from "moment";
import { confirmBox } from '../../utils';
import { ExcelFormat, MaxExcelSize } from '../../settings'
import UpgradePlan from './upgradePlan'

const { Search } = Input;


const { TabPane } = Tabs;

const HomeView = (props) => {
    const dispatch = useDispatch()
    const { currentUser } = useSelector((state) => ({
        currentUser: state.global.currentUser
    }))
    const [dataSource, setDataSource] = useState([])
    const [data, setData] = useState([])
    const [loading, setLoader] = useState(false)
    const [formLoader, setFormLoader] = useState(false)
    const [filters, setFilters] = useState({})

    const onSearch = value => console.log(value);

    const deleteNumber = (fields) => {
        confirmBox(dispatch, {
            message: 'Are you sure you want to Delete',
            onOk: async () => {
                setLoader(true)
                let { user, token, success, message, data } = await Request.deleteAreaCodes(fields?.id)
                setLoader(false)
                if (success) {
                    notification.success({
                        message: message || "success"
                    })
                    apiFunction()
                } else {
                    notification.error({
                        message: message || "Failed"
                    })
                }
            }
        })
    }
    const onAdd = async () => {
        if (!(filters?.area)) {
            notification.warning({
                message: 'Required Fields',
                description: 'Choose Area Code'
            })
            return
        }
        setFormLoader(true)
        let { user, token, success, message, data } = await Request.addAreaCodes({ ...filters })
        setFormLoader(false)
        if (success) {
            notification.success({
                message: message || "added"
            })
            setFilters({ area: undefined })
            apiFunction()
        } else {
            notification.error({
                message: message || "Failed",
                description: JSON.stringify(data)?.replace('[', '')?.replace('{', '')?.replace(']', '')?.replace('}', '')

            })
        }
    }
    const onChange = async (val, id) => {
        setLoader(true)
        let { user, token, success, message, data } = await Request.updateAreaCodes(id, { active: val })
        setLoader(false)
        if (success) {
            notification.success({
                message: message || "success"
            })
            apiFunction()
        } else {
            notification.error({
                message: message || "Failed"
            })
        }
    }
    const columns = [
        {
            title: 'Phone Code',
            dataIndex: 'areacode',
            key: 'areacode',
            fixed: 'left',
            render: (val, record) => {
                return <>{val} {record?.area?.areacode}</>
            }
        },
        {
            title: 'Country Code',
            dataIndex: 'countrycode',
            key: 'countrycode',
            render: (val, record) => {
                return <>{val} {record?.area?.country?.countrycode}</>
            }
        },
        {
            title: 'Phone Type',
            dataIndex: 'phonetype',
            key: 'phonetype',
            render: (val, record) => {
                return <>{val} {record?.area?.phonetype}</>
            }
        },
        // {
        //     title: 'Active',
        //     dataIndex: 'active',
        //     key: 'active',
        //     render: (val, r) => {
        //         return <Tag color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
        //     }
        // },
        // {
        //     title: 'Toll-Free',
        //     dataIndex: 'tollfree',
        //     key: 'tollfree',
        //     render: (val, r) => {
        //         return <Tag color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
        //     }
        // },
        // {
        //   title: 'Created At',
        //   dataIndex: 'createdon',
        //   key: 'createdon',
        //   render: (val, r) => {
        //     return <Tag>{moment.utc(val).format('DD-MM-YYYY')}</Tag>
        //   }
        // },
        {
            title: 'Active',
            dataIndex: 'active',
            key: 'active',
            render: (val, record) => {
                return <Switch checkedChildren="On" unCheckedChildren="Off" checked={val} onChange={(e) => onChange(e, record?.id)} />
            }
        },

        {
            title: 'Actions',
            dataIndex: 'Action',
            key: 'Action',
            render: (val, record) => {
                return <div className="flex" style={{ justifyContent: 'center' }}>
                    <div>
                        <DeleteOutlined style={{ color: "red", fontSize: 20 }} onClick={() => deleteNumber(record)} />
                    </div>
                </div>
            }
        },
    ];
    const getAreaCode = async () => {
        setLoader(true)
        let { user, token, success, message, data } = await Request.getAreaCodes()
        setLoader(false)
        if (success) {
            setData(() => data)
        } else {
            setData(() => [])

            // notification.error({
            //   message: message || "Failed"
            // })
        }
    }
    const apiFunction = async () => {
        setLoader(true)
        let { user, token, success, message, data } = await Request.listAreaCodes()
        setLoader(false)
        let newData = []
        if (success) {
            async.forEach(data, (val, cb) => {
                let obj = {
                    ...val,
                    ...val,
                }
                newData.push(obj)
                cb()
            }, () => {
                setDataSource(newData)
            })
        } else {
            setDataSource([])

            // notification.error({
            //   message: message || "Failed"
            // })
        }
    }

    useEffect(() => {
        apiFunction()
        getAreaCode()

    }, [])
    if (!currentUser?.balance?.callblocking) {
        return <UpgradePlan />
    }
    return <div className="">
        <div className="flex jcsb aic" style={{ justifyContent: 'end', width: '100%' }}>
            <div className="flex ">

                <div className=" marginRight" style={{ width: '100%', float: 'right' }}>
                    <Select
                        showSearch
                        filterOption={(input, val) =>
                            val?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        allowClear={true}
                        onChange={(val) => setFilters((prev) => ({ ...prev, area: val }))}
                        style={{ width: 200, border: '1px solid #e1e3e8', borderRadius: '4px', float: 'right' }}
                        placeholder="Select Area Codes"
                        value={filters?.area}
                        options={_.map(data, val => { return ({ label: `${val?.areacode} - ${val?.country}`, value: val?.id }) })}
                    />
                </div>
                <div className="" style={{ float: 'left' }}>
                    <Button type="primary" className="buttonPrimaryStyle" loading={formLoader} onClick={() => onAdd()}>
                        Add Area Codes</Button>
                </div>
            </div>
        </div>
        <div className="marginTop" style={{ width: '100%' }}>
            <Table title={() => <div style={{ fontWeight: "bold", fontSize: 18 }}>List of Restricted Area Codes
            </div>} bordered dataSource={dataSource} columns={columns} loading={loading} scroll={{ x: 'max-content' }} />

        </div>
    </div>
}

export default HomeView;