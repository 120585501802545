import React, { useState, useEffect, lazy } from 'react';
import { notification, Table, Button, Modal, Switch, Form } from 'antd';
import './styles.less'
import Request from '../../request'
import _ from 'lodash'
import moment from 'moment'
import FormItem from '../../components/FormItem'
import { DeleteOutlined } from '@ant-design/icons';
import { DateFormat } from '../../settings';
import { useSelector } from 'react-redux';
import UpgradePlan from './upgradePlan'

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

const Home = () => {
    const { currentUser } = useSelector((state) => ({
        currentUser: state.global.currentUser
    }))
    const [dataSource, setState] = useState([])
    const [allNumbers, setNumbers] = useState([])

    const [loading, setLoader] = useState(false)
    const [modalLoading, setModalLoader] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisible1, setIsModalVisible1] = useState(false);
    const [Music, setMusic] = useState({});
    const [form] = Form.useForm()
    const requiredRule = [{ required: true, message: 'Please input field' }]
    const columns = [
        {
            title: 'Start Date-End Date',
            dataIndex: 'startdate',
            key: 'startdate',
            width: 200,
            render: (val, record) => {
                return <>{val},{record?.enddate}</>
            }
        },
        {
            title: 'Number',
            dataIndex: 'number',
            key: 'number',
            width: 250,
            search: true
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Music File',
            dataIndex: 'file',
            key: 'file',
            render: (val, record) => {
                return <Button loading={modalLoading} onClick={() => showModal1(record?.id)} type="primary">View File</Button>
            }
        },
        {
            title: 'Actions',
            dataIndex: 'Action',
            key: 'Action',
            // width: 180,
            // fixed: 'right',
            render: (val, r) => {
                return <div className="flex" style={{ justifyContent: 'center' }}>
                    <div>
                        <DeleteOutlined style={{ fontSize: 24 }} onClick={() => deleteHolidays(r?.id)} />
                    </div>
                </div>
            },
        }
    ];
    const apiFunction = async () => {
        setLoader(true)
        let { user, token, success, message, data } = await Request.getHolidays()
        setLoader(false)
        if (success) {
            setState(() => data)
        } else {
            setState(() => [])

            // notification.error({
            //   message: message || "Failed"
            // })
        }
    }
    const apiFunction1 = async () => {
        let { user, token, success, message, data } = await Request.getAllNumbers()
        if (success) {
            setNumbers(() => data)
        } else {
            setNumbers(() => [])

            // notification.error({
            //   message: message || "Failed"
            // })
        }
    }
    const showModal = () => {
        setIsModalVisible(true);
    };
    const showModal1 = async (id) => {
        setLoader(true)
        let { user, token, success, message, data } = await Request.getFileForHolidays(id)
        setLoader(false)
        if (success) {
            setIsModalVisible1(true);
            setMusic(() => data)
        } else {
            setMusic(() => { })

            // notification.error({
            //   message: message || "Failed"
            // })
        }
    };
    const deleteHolidays = async (id) => {
        setLoader(true)
        let { user, token, success, message, data } = await Request.deleteHolidays(id)
        setLoader(false)
        if (success) {
            notification.success({
                message: message || "success"
            })
            apiFunction()
        } else {
            notification.error({
                message: message || "Failed"
            })
        }
    }
    const handleOk = () => {
        setIsModalVisible(false);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const handleOk1 = () => {
        setIsModalVisible1(false);
    };
    const handleCancel1 = () => {
        setIsModalVisible1(false);
    };
    useEffect(() => {
        apiFunction()
        apiFunction1()
    }, [])

    const onFinish = async (valData) => {
        const MAINFORM = new FormData();
        _.forEach(valData, (value, key) => {
            if (key == 'music') {
                MAINFORM.append(key, value ? value[0]?.originFileObj ? value[0]?.originFileObj : null : null)
            } else if (key == 'date') {
                MAINFORM.append('startdate', value ? value[0].format('YYYY-MM-DD') : null)
                MAINFORM.append('enddate', value ? value[1].format('YYYY-MM-DD') : null)
            }
            else {
                MAINFORM.append(key, value ? value : null)

            }
        })
        console.log(MAINFORM, ">>>>>>>>>>>>>>>>>>>>>>>>>>><<<<<<<<<<")
        setLoader(true)
        let { user, token, success, message, data } = await Request.addHolidays(MAINFORM)
        setLoader(false)
        if (success) {
            notification.success({
                message: message
            })
            form.resetFields()
            handleCancel()
            apiFunction()

        } else {
            notification.error({
                message: message || "Failed",
                description: JSON.stringify(data)?.replace('[', '')?.replace('{', '')?.replace(']', '')?.replace('}', '')

            })
        }
    }
    const normFile = (e) => {
        // console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    }
    if (!currentUser?.balance?.holidayrouting) {
        return <UpgradePlan />
    }


    return (<div className="">
        <div className="flex jcfe aic">


            <Button type="primary" className="buttonPrimaryStyle" onClick={() => showModal()}>Add Holidays</Button>


        </div>
        <div className="marginTop">
            <Table
                // pagination={false}
                dataSource={dataSource}
                columns={columns}
                rowKey="index"
                loading={loading}
                title={() => <div style={{ fontWeight: "bold", fontSize: 18 }}>Holidays</div>}
                bordered
                size="small"
                scroll={{ x: 'max-content' }}
            />
        </div>
        <Modal title="Add Holidays" destroyOnClose width={700} height={600} footer={null} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
            <Form
                {...layout}
                form={form}
                layout='vertical'
                onFinish={onFinish}
            >

                <FormItem
                    label={'Phone Number'}
                    name="number"
                    inputType={'SELECT'}
                    options={_.map(allNumbers, val => { return ({ label: val?.phonenumber, value: val?.id }) })}
                    rules={requiredRule}
                />
                <FormItem
                    label={'Date'}
                    name="date"
                    disabledDate={current => {
                        return current && current < moment();
                    }}
                    inputType="RANGEPICKER"
                    rules={requiredRule}
                />
                <FormItem
                    label={'Description'}
                    name="description"
                    rules={requiredRule}
                />
                <FormItem
                    name="music"
                    label="Music on Holiday"
                    valuePropName="fileList"
                    accept={".mp3,.mp4"}
                    rules={requiredRule}
                    getValueFromEvent={normFile}
                    inputType={'UPLOAD'}
                />
                <div className="marginTop">
                    <FormItem
                        inputType="BUTTON"
                        tailLayout={tailLayout}
                        type="primary"
                        buttonStyle={{ width: 200, marginTop: 20 }}
                        loading={loading}
                        title="SUBMIT"
                        htmlType="submit" />
                </div>
            </Form>

        </Modal>
        <Modal destroyOnClose title="Music File" footer={null} visible={isModalVisible1} onOk={handleOk1} onCancel={handleCancel1}>
            <audio
                controls
                type={Music?.music?.ext}
                src={"data:audio/wav;base64," + Music?.music?.file}
            >
                Your browser does not support the
                <code>audio</code> element.
            </audio>
        </Modal>
    </div>
    );
}

export default Home
