import { useState, lazy } from 'react';
import { Card, Space, Tooltip, Image, Radio, message, Table, Tabs, Button, Input } from 'antd';
import { useSelector } from 'react-redux';
import './styles.less'
import { AudioOutlined } from '@ant-design/icons';
import { InfoCircleOutlined, UserOutlined,SearchOutlined } from '@ant-design/icons';

const { Search } = Input;


const { TabPane } = Tabs;
const data = [
  {
    key: '1',
    name: 'Mike',
    age: 32,
    address: '10 Downing Street',
  },
  {
    key: '2',
    name: 'John',
    age: 42,
    address: '10 Downing Street',
  },
];

const HomeView = (props) => {

  const [state, setState] = useState({
    data: [],
    loading: false,
    hasMore: true,
    orderStatus: 'PENDING',
    showQr: false
  })
  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser
  }))
  const [dataSource, setDataSource] = useState([])

  const suffix = (
    <AudioOutlined
      style={{
        fontSize: 16,
        color: '#1890ff',
      }}
    />
  );

  const onSearch = value => console.log(value);

  const columns = [
    {
      title: 'Campaigns',
      dataIndex: 'Campaigns',
      key: 'Campaigns',
    },
    {
      title: 'Assignee',
      dataIndex: 'Assignee',
      key: 'Assignee',
    },
    {
      title: 'Calls Completed',
      dataIndex: 'CallsCompleted',
      key: 'CallsCompleted',
    },
    {
      title: 'Creation Date',
      dataIndex: 'creationDate',
      key: 'creationDate',
    },
    {
      title: 'Description',
      dataIndex: 'Description',
      key: 'Description',
    },
    {
      title: 'Active/Inactive',
      dataIndex: 'Active',
      key: 'Active',
    },
    {
      title: 'Actions',
      dataIndex: 'Action',
      key: 'Action',
    },

  ];
  return <div>
    <div className="flex jcsb">
      <div>
        <Input
          placeholder="Search"
          suffix={
              <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
          }
          style={{borderRadius:'4px'}}
        />
      </div>
      <Button type="primary" className="buttonPrimaryStyle">
        Add Campaign
        </Button>
    </div>
    <div className="marginTop">
      <Table bordered dataSource={dataSource} columns={columns} />

    </div>
  </div>
}

export default HomeView;