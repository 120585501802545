import axios from 'axios'
import { apiUrl, ExcelDownloadFormat } from './settings'
import {
    fullBrowserVersion,
    browserName,
    osVersion,
    osName
} from "react-device-detect";
import { saveAs } from 'file-saver';
import { DateFormat } from './settings'
import moment from 'moment'
let authAxios = axios.create({
    baseURL: apiUrl
})

let authAxios1 = axios.create({
    baseURL: apiUrl
})

authAxios1.interceptors.request.use(function (request) {
    // Do something before request is sent
    request.responseType = 'blob'
    return request;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});
// authAxios1.interceptors.request.use(function (config) {
//     // Do something before request is sent
//     // config.data ='{"dateRange":"2022-06-01,2022-06-11"}'
//     if (localStorage.getItem('dateRange') != 'undefined') {
//         config.params = {
//             count: undefined,
//             dateRange: localStorage.getItem('dateRange')
//         }
//     }

//     return config;
// },
// function (error) {
//     // Do something with request error
//     return Promise.reject(error);
// });

export const getToken = () => {
    return {
        headers: {
            Authorization: 'Token ' + localStorage.getItem('token'),
            type: "WEB",
            fullbrowserversion: fullBrowserVersion,
            browsername: browserName,
            osversion: osVersion,
            osname: osName
        }
    }
}

// export const getToken3 = () => {
//     return {
//         headers: {
//             Authorization: 'Token ' + localStorage.getItem('f123c0a191e4b1aea3f999e2e18869497b8182c9'),
//             type: "WEB",
//             fullbrowserversion: fullBrowserVersion,
//             browsername: browserName,
//             osversion: osVersion,
//             osname: osName
//         }
//     }
// }


export const getTokenFromParams = (token) => {
    return {
        headers: {
            Authorization: 'Token ' + token,
            type: "WEB",
            fullbrowserversion: fullBrowserVersion,
            browsername: browserName,
            osversion: osVersion,
            osname: osName
        }
    }
}
export const getTokenFromParamsForFile = (token) => {
    return {
        headers: {
            Authorization: 'Token ' + token,
            'content-type': 'multipart/form-data; boundary=ebf9f03029db4c2799ae16b5428b06bd',
            type: "WEB",
            fullbrowserversion: fullBrowserVersion,
            browsername: browserName,
            osversion: osVersion,
            osname: osName
        }
    }
}
class Request {

    error = err => {
        try {
            if (err.response.status === 401) {
                localStorage.clear()
                window.location.href = ''
            }
        } catch (e) {
        }
    }

    // ------------------------------------------- API Start from here --------------------------------------------- //
    getCountries(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/countries', { ...data })
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    signup(data, recaptchaToken) {
        return new Promise((next, error) => {
            authAxios
                .post('/customer/signup', data)
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    checkEmail(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/signup', data)
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    login(data, recaptchaToken) {
        return new Promise((next, error) => {
            authAxios
                .post('/customer/login', data)
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    logout(data, recaptchaToken) {
        return new Promise((next, error) => {
            authAxios
                .post('/customer/logout', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    resetPassword(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/customer/password-reset-complete', data)
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    forgotPassWord(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/customer/request-reset-email', data)
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    // user api

    getProfile() {
        return new Promise((next, error) => {
            authAxios
                .post('/customer/details', {}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    getProfileFromToken(token) {
        return new Promise((next, error) => {
            authAxios
                .post('/customer/details', {}, getTokenFromParams(token))
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    updateProfile(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/customer/update', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    selectPlan(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/customer/selectplan', { ...data }, getToken())
                .then(d => {
                    if (d?.data?.data?.url) {
                        window.location.href = `${apiUrl}${d?.data?.data?.url}`
                    }
                    next(d.data)

                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    // credit amount
    addCreditAmount(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/payments/top-up', { ...data }, getToken())
                .then(d => {
                    if (d?.data?.data?.url) {
                        window.location.href = `${apiUrl}${d?.data?.data?.url}`
                    }
                    next(d.data)

                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    //Price limit
    updateCostLimit(data, id) {
        return new Promise((next, error) => {
            authAxios
                .post(`/customer/settings/update/call/cost/${id}`, { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    getCostLimit(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/customer/settings/call/cost', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    removeCostLimit(data, id) {
        return new Promise((next, error) => {
            authAxios
                .post(`/customer/settings/update/call/cost/${id}`, { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    //Renew Plan
    RenewPlan(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/payments/renew', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    //Addons
    getAddOns(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/plans/addons', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    listSubAddOn(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/subscriptions', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    subscribeAddOn(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/subscriptions/addonsub', data, getToken())
                .then(d => {
                    if (d?.data?.data?.url) {
                        window.location.href = `${apiUrl}${d?.data?.data?.url}`
                    }
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    unsubscribeAddOn(id) {
        return new Promise((next, error) => {
            authAxios
                .post(`/subscriptions/subdelete/${id}`, {}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }


    //department
    adddept(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/department/add', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    addAutoDialer(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/customer/leadset/create/autodialer', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    getAutoDialer(data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/customer/leadset/autodialer/list`, { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    updateAutoDialer(id, data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/customer/leadset/autodialer/update/${id}`, { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    addLeadset(data) {
        return new Promise((next, error) => {
            authAxios.post('customer/leadset/upload', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    allDept(data) {
        return new Promise((next, error) => {
            authAxios.post('/department/list', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    deptLeadset(id) {
        return new Promise((next, error) => {
            authAxios.post(`customer/leadset/fetch/by-file-id/${id}`, {}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }


    leadsetData() {
        return new Promise((next, error) => {
            authAxios
                .post(`customer/leadset/list`, {}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    depttCallSummary(id) {
        return new Promise((next, error) => {
            authAxios.post(`/leadset/deptsummary/${id}`)
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }


    fileCallSummary(id) {
        return new Promise((next, error) => {
            authAxios.post(`/leadset/filesummary/${id}`)
                .then(d => {

                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    updateLeadsetData(id, data) {
        return new Promise((next, error) => {
            authAxios.post(`/customer/leadset/file/update/${id}`, { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    resetCalledData(id, data) {
        return new Promise((next, error) => {
            authAxios.post(`/customer/leadset/update/${id}`, { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    deleteLeadset(id) {
        return new Promise((next, error) => {
            authAxios.post(`/customer/leadset/delete/${id}`, {}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }


    getDept(id) {
        return new Promise((next, error) => {
            authAxios
                .post(`/department/${id}`, {}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    updateDept(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/department/update', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    deleteDept(id) {
        return new Promise((next, error) => {
            authAxios
                .post(`/department/delete/${id}`, {}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    //*diposition
    addDisposition(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/department/create/dispostions', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    if (err.response) {
                        // console.log(err.response.data);
                        next({ message: err.response.data.message, err })
                        this.error(err)
                    }

                })
        })
    }
    updateDisposition(data, id) {
        return new Promise((next, error) => {
            authAxios
                .post(`/department/update/dispostions/${id}`, { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    listDispositions(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/department/list/dispostions', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    customerListDispositions(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/customer/dispositions/list', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    deleteDisposition(id) {
        return new Promise((next, error) => {
            authAxios
                .post(`/department/delete/dispostions/${id}`, {}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    //choose number
    getNumbers(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/customer/numbers/list-available?', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    chooseNumbers(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/customer/numbers/choose?', { ...data }, getToken())
                .then(d => {
                    if (d?.data?.data?.url) {
                        window.location.href = `${apiUrl}${d?.data?.data?.url}`
                    }
                    next(d.data)

                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    updateNumber(id, data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/customer/numbers/update/${id}`, { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    getNumberCode(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/customer/numbers/phonecodes', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    getAllNumbers(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/customer/numbers/get', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    deleteNumbers(id) {
        return new Promise((next, error) => {
            authAxios
                .post(`/customer/numbers/delete/${id}`, {}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    addNumber(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/choosenumber', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    //restricted area codes
    getAreaCodes(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/carriers/charge/outgoing', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    addAreaCodes(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/restricted_area/add', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    listAreaCodes(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/restricted_area', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    deleteAreaCodes(id) {
        return new Promise((next, error) => {
            authAxios
                .post(`/restricted_area/remove/${id}`, {}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    updateAreaCodes(id, data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/restricted_area/update/${id}`, { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    //DID
    addDID(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/customer/numbers/did/new', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    allDID(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/customer/numbers/did', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    updateGateway(id, data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/customer/numbers/gateway/update/${id}`, { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    updateDID(id, data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/customer/numbers/did/update/${id}`, { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    deleteDID(id) {
        return new Promise((next, error) => {
            authAxios
                .post(`/customer/numbers/did/delete/${id}`, {}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    //gateway
    addGateway(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/customer/numbers/gateway/new', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    //caller
    addCaller(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/callers/new', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    allCaller(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/callers', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    getCaller(id, data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/callers/${id}`, { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    updateCaller(id, data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/callers/update/${id}`, { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    deleteCaller(id) {
        return new Promise((next, error) => {
            authAxios
                .post(`/callers/delete/${id}`, {}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    //plans
    getPlans(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/plans', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    allDocumentTypeWithToken(data, token) {
        return new Promise((next, error) => { //apoi ni h
            authAxios
                .post('/customer/docs/type', { ...data }, getTokenFromParams(token))
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    UploadDocument(data, token) {
        return new Promise((next, error) => { //apoi ni h
            authAxios
                .post('/customer/docs/new', data, getTokenFromParamsForFile(token))
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    dashboard(data) {
        return new Promise((next, error) => { //apoi ni h
            authAxios
                .post('/dashboard', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    getLiveCalls(data) {
        return new Promise((next, error) => { //apoi ni h
            authAxios
                .post('/dashboard/live-summary', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    getCallsTable(data) {
        return new Promise((next, error) => { //apoi ni h
            authAxios
                .post('/dashboard/live-list', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    getCallsAgents(data) {
        return new Promise((next, error) => { //apoi ni h
            authAxios
                .post('/dashboard/callers', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    graph(data) {
        return new Promise((next, error) => { //apoi ni h
            authAxios
                .post('/dashboard/graph', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    //call history
    callHistory(data) {
        return new Promise((next, error) => { //apoi ni h
            authAxios
                .post('/call/log', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    getFiles(data, value) {
        return new Promise((next, error) => {
            authAxios
                .post(`/serve`, { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    ListCallBack(data) {
        return new Promise((next, error) => { //apoi ni h
            authAxios
                .post('/callers/callback', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    addDnc(data) {
        return new Promise((next, error) => { //apoi ni h
            authAxios
                .post('/customer/settings/dnc/new', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    ListDnc(data) {
        return new Promise((next, error) => { //apoi ni h
            authAxios
                .post('/customer/settings/dnc', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    deleteDnc(id) {
        return new Promise((next, error) => {
            authAxios
                .post(`/customer/settings/dnc/delete/${id}`, {}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    uploadDnc(data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/customer/settings/dnc/upload`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    addQueue(data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/customer/queue/new`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    deleteQueue(data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/customer/queue/delete`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    fetchQueue(data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/customer/queue/fetch`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    updateQueue(id, data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/customer/queue/update/${id}`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    //restricted contacts
    addRestrictedContacts(data) {
        return new Promise((next, error) => { //apoi ni h
            authAxios
                .post('/customer/settings/block/new', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    ListRestrictedContacts(data) {
        return new Promise((next, error) => { //apoi ni h
            authAxios
                .post('/customer/settings/block', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    deleteRestrictedContacts(id) {
        return new Promise((next, error) => {
            authAxios
                .post(`/customer/settings/block/delete/${id}`, {}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    uploadRestrictedContacts(data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/customer/settings/block/upload`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    getCustomerSettings(data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/customer/settings`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    updateCustomerSettings(data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/customer/settings/update`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    getReport(data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/call/report`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    getDidCdr(data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/callers/fetch/didcbr`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    getReportOfAbandonCalls(data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/call/abandon`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    getPaymentReports(data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/customer/fetchinvoice`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    getBillingContacts(data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/customer/settings/billing`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    addBillingContacts(data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/customer/settings/billing/new`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    addBillingContactsWithToken(data, token) {
        return new Promise((next, error) => {
            authAxios
                .post(`/customer/settings/billing/new`, data, getTokenFromParams(token))
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    updateBillingContacts(id, data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/customer/settings/billing/update/${id}`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    deleteBillingContacts(id) {
        return new Promise((next, error) => {
            authAxios
                .post(`/customer/settings/billing/delete/${id}`, {}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    getHolidays(data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/customer/settings/holiday`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    addHolidays(data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/customer/settings/holiday/new`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    deleteHolidays(id) {
        return new Promise((next, error) => {
            authAxios
                .post(`/customer/settings/holiday/delete/${id}`, {}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    getFileForHolidays(id) {
        return new Promise((next, error) => {
            authAxios
                .post(`/customer/settings/holiday/${id}`, {}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    contacts(data) {
        return new Promise((next, error) => { //apoi ni h
            authAxios
                .post('/customer/contacts', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    hangupCall(id) {
        return new Promise((next, error) => { //apoi ni h
            authAxios
                .post(`/channel/caller-hangup/${id}`, {}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    changePassword(data) {
        return new Promise((next, error) => { //apoi ni h
            authAxios
                .post(`/customer/change_password`, { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    emailme(data) {
        return new Promise((next, error) => { //apoi ni h
            authAxios
                .post(`/call/log-download`, { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    emailmedisposition(data) {
        return new Promise((next, error) => { //apoi ni h
            authAxios
                .post(`/customer/dispositions/email/report`, { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    emailmepayment(data) {
        return new Promise((next, error) => { //apoi ni h
            authAxios
                .post(`/customer/invoice/export/onmail`, { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    emailmeloginlogout(data) {
        return new Promise((next, error) => { //apoi ni h
            authAxios
                .post(`/customer/caller/report/onmail`, { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    downloadRecording(data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/serve/recording-zip`, { ...data }, getToken())
                .then(d => {
                    const linkSource = 'data:' + 'application/zip' + ';base64, ' + d?.data?.data?.filecontent;
                    const downloadLink = document.createElement("a");
                    downloadLink.href = linkSource;
                    downloadLink.download = d?.data?.data?.filename;
                    downloadLink.click();
                    next(d?.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    downloadXlsForDailyCalls(data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/call/daily-download`, { ...data }, getToken())
                .then(d => {
                    const linkSource = 'data:' + `application/${ExcelDownloadFormat}` + ';base64, ' + d?.data?.data;
                    const downloadLink = document.createElement("a");
                    downloadLink.href = linkSource;
                    downloadLink.download = `DailyCallReports.${ExcelDownloadFormat}`;
                    downloadLink.click();
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    downloadXlsForDidCdr(data) {
        return new Promise((next, error) => {
            authAxios1
                .post(`/callers/download/didcbr`, { ...data }, getToken())
                .then(d => {
                    const blob = new Blob(
                        [d.data], { type: 'application/vnd.ms-excel' })
                    const aEle = document.createElement('a');     // Create a label
                    const href = window.URL.createObjectURL(blob);       // Create downloaded link
                    aEle.href = href;
                    aEle.download = `Did_Cdr_${data?.dateRange ? data?.dateRange?.replace(',', ' to ') : moment().format(DateFormat)}`;
                    document.body.appendChild(aEle);
                    aEle.click();     // Click to download
                    document.body.removeChild(aEle); // Download complete remove element
                    window.URL.revokeObjectURL(href)
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    downloadXlsForPaymentHistory(data) {
        return new Promise((next, error) => {
            authAxios1
                .post(`/customer/invoice_export`, { ...data }, getToken())
                .then(d => {
                    const blob = new Blob(
                        [d.data], { type: 'application/vnd.ms-excel' })
                    const aEle = document.createElement('a');     // Create a label
                    const href = window.URL.createObjectURL(blob);       // Create downloaded link
                    aEle.href = href;
                    aEle.download = `PaymentHistory (${data?.dateRange ? data?.dateRange?.replace(',', ' to ') : moment().format(DateFormat)}))`;
                    document.body.appendChild(aEle);
                    aEle.click();     // Click to download
                    document.body.removeChild(aEle); // Download complete remove element
                    window.URL.revokeObjectURL(href)
                    next(d.data)
                    //     localStorage.removeItem('dateRange')
                    // .then(d => {
                    //     var blob = new Blob([d.data], { type: 'application/vnd.ms-excel' });
                    //     // const linkSource = 'data:' + `application/${ExcelDownloadFormat}` + ';base64, ' + d?.data?.data;
                    //     const downloadLink = document.createElement("a");
                    //     downloadLink.href = blob;
                    //     // downloadLink.href = linkSource;
                    //     downloadLink.download = `PaymentHistory${data?.dateRange ? data?.dateRange?.replace(',', ' to ') : moment().format(DateFormat)}.${ExcelDownloadFormat}`;
                    //     downloadLink.click();
                    //     // saveAs(blob, "hello world.csv");
                    //     next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    downloadXlCalls(data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/call/log-download`, { ...data }, getToken())
                .then(d => {
                    const linkSource = 'data:' + `application/${ExcelDownloadFormat}` + ';base64, ' + d?.data?.data;
                    const downloadLink = document.createElement("a");
                    downloadLink.href = linkSource;
                    downloadLink.download = `CallHistory.${ExcelDownloadFormat}`;
                    downloadLink.click();
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    downloadInvoicePDF(data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/customer/invoice/pdf/download`, { ...data }, getToken())
                .then(d => {
                    console.log(d, "datainside<><<<<<<<<<<")
                    const linkSource = 'data:' + `application/pdf` + ';base64, ' + d?.data?.data;
                    const downloadLink = document.createElement("a");
                    downloadLink.href = linkSource;
                    downloadLink.download = `${data?.orderid}.pdf`;
                    downloadLink.click();
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    downloadXlsForAgents(data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/call/agent-download`, { ...data }, getToken())
                .then(d => {
                    const linkSource = 'data:' + `application/${ExcelDownloadFormat}` + ';base64, ' + d?.data?.data;
                    const downloadLink = document.createElement("a");
                    downloadLink.href = linkSource;
                    downloadLink.download = `AgentsCallReports.${ExcelDownloadFormat}`;
                    downloadLink.click();
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    getSummary(data) {
        return new Promise((next, error) => { //apoi ni h
            authAxios
                .post('/customer/plan-summary', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    checkEndpointStatus() {
        return new Promise((next, error) => {
            authAxios
                .post('/endpoints/detail', {}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    downloadDispositionReport(data) {
        return new Promise((next, error) => {
            authAxios1
                .post(`/customer/dispositions/list`, { ...data }, getToken())
                .then(d => {
                    const blob = new Blob(
                        [d.data], { type: 'application/vnd.ms-excel' })
                    const aEle = document.createElement('a');     // Create a label
                    const href = window.URL.createObjectURL(blob);       // Create downloaded link
                    aEle.href = href;
                    aEle.download = `disposition-report`;
                    document.body.appendChild(aEle);
                    aEle.click();     // Click to download
                    document.body.removeChild(aEle); // Download complete remove element
                    window.URL.revokeObjectURL(href)
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    downloadLeadset(id) {
        return new Promise((next, error) => {
            authAxios1
                .post(`/customer/leadset/excel/download`, { id }, getToken())
                .then(d => {
                    const blob = new Blob(
                        [d.data], { type: 'application/vnd.ms-excel' })
                    const aEle = document.createElement('a');     // Create a label
                    const href = window.URL.createObjectURL(blob);       // Create downloaded link
                    aEle.href = href;
                    aEle.download = `leadset-report`;
                    document.body.appendChild(aEle);
                    aEle.click();     // Click to download
                    document.body.removeChild(aEle); // Download complete remove element
                    window.URL.revokeObjectURL(href)
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    fetchCallerJobTime(data) {
        return new Promise((next, error) => { //apoi ni h
            authAxios
                .post('/customer/caller/job/list', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    downloadCallerJobTime(data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/customer/caller/report`, { ...data }, getToken())
                .then(d => {
                    const linkSource = 'data:' + `application/${ExcelDownloadFormat}` + ';base64, ' + d?.data?.data;
                    const downloadLink = document.createElement("a");
                    downloadLink.href = linkSource;
                    downloadLink.download = `caller-login-logout-report.${ExcelDownloadFormat}`;
                    downloadLink.click();
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    fetchRealTimeScreen(data) {
        return new Promise((next, error) => { //apoi ni h
            authAxios
                .post('/dashboard/call/data', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    defineWorkingHours(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/department/hour/routing/create', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    ListingWorkingHours(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/department/hour/routing/list', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    DeleteWorkingHoursRouting(id, data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/department/hour/routing/delete/${id}`, { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    UpdateWorkingHours(id, data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/department/hour/routing/update/${id}`, { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    FilterWorkingHours(id, data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/department/hour/routing/fetch/${id}`, { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

}

export default new Request();
