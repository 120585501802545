import { useState, lazy, useEffect } from 'react';
import { Space, form, Tooltip, notification, Switch, Modal, Button, Input, Tag, Form, Card } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import './styles.less'
import { AudioOutlined, DeleteOutlined, UserSwitchOutlined, EditOutlined, LockOutlined } from '@ant-design/icons';
import { getPushPathWrapper } from '../../routes';
import Request from '../../request'
import moment from "moment";
import FormItem from '../../components/FormItem'
import { confirmBox, confirmBox1, confirmBox2 } from '../../utils';
import async, { auto } from 'async'
import FilterTable from '../../components/Table'
import _ from 'lodash';
import UpgradePlan from '../settings/upgradePlan'


const { Search } = Input;


const HomeView = (props) => {
    const dispatch = useDispatch()
    const { currentUser } = useSelector((state) => ({
        currentUser: state.global.currentUser
    }))
    const [dataSource, setDataSource] = useState([])
    const [loading, setLoader] = useState(false)
    const [idValue, setIdValue] = useState([]);
    const [idValue1, setIdValue1] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisible1, setIsModalVisible1] = useState(false);
    const requiredRule = [{ required: true, message: 'Please input field' }]

    const suffix = (
        <AudioOutlined
            style={{
                fontSize: 16,
                color: '#1890ff',
            }}
        />
    );

    // const onSearch = value => console.log(value);

    const deleteUser = (fields) => {
        confirmBox(dispatch, {
            message: 'Are you sure you want to Delete',
            onOk: async () => {
                setLoader(true)
                let { user, token, success, message, data } = await Request.deleteCaller(fields?.id)
                setLoader(false)
                if (success) {
                    notification.success({
                        message: message || "Failed"
                    })
                    apiFunction()
                } else {
                    notification.error({
                        message: message || "Failed"
                    })
                }
            }
        })
    }
    const onFinish = (valdata) => {
        setIsModalVisible(false)
        confirmBox1(dispatch, {
            message: 'Do you want to change the password of user',
            onOk: async () => {
                setLoader(true)
                let { user, token, success, message, data } = await Request.updateCaller(idValue?.id, { auth: { password: (valdata?.password) } })
                setLoader(false)
                if (success) {
                    setIsModalVisible(false)
                    notification.success({
                        message: message
                    })
                    apiFunction()
                } else {
                    notification.error({
                        message: message || "Failed"
                    })
                }
            }
        })
    }
    const onFinish1 = (valdata) => {
        setIsModalVisible1(false)
        confirmBox2(dispatch, {
            message: 'Do you want to change the CLI',
            onOk: async () => {
                setLoader(true)
                let { user, token, success, message, data } = await Request.updateCaller(idValue1?.id, { cli: (valdata?.cli == undefined ? null : valdata?.cli) })
                setLoader(false)
                if (success) {
                    setIsModalVisible(false)
                    notification.success({
                        message: message
                    })
                    apiFunction()
                } else {
                    notification.error({
                        message: message || "Failed"
                    })
                }
            }
        })
    }

    const edit = (record) => {
        dispatch(getPushPathWrapper('editUser', record))
    }
    const onChange = async (val, id) => {
        setLoader(true)
        let { user, token, success, message, data } = await Request.updateCaller(id, { callerstatus: val ? 'blocked' : 'inactive' })
        setLoader(false)
        if (success) {
            notification.success({
                message: message || "success"
            })
            apiFunction()
        } else {
            notification.error({
                message: message || "Failed"
            })
        }
    }

    const handleOk = () => {
        setIsModalVisible(false);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const handleOk1 = () => {
        setIsModalVisible1(false);
    };
    const handleCancel1 = () => {
        setIsModalVisible1(false);
    };

    const layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    };
    const columns = [
        {
            title: 'Name',
            dataIndex: 'firstname',
            key: 'firstname',
            width: 150,
            height: 50,
            fixed: 'left',
            // search:true,
            render: (val, record) => {
                return <>{val} {record.lastname}</>
            }
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: 250,
            search: true

        },
        {
            title: 'Allocated Phone',
            dataIndex: 'allocatedphone',
            key: 'allocatedphone',
            render: (val, r) => {
                return <>{val?.phonecode && <>({val?.phonecode})</>} {val?.phonenumber}</>
            }
        },
        {
            title: 'Department',
            dataIndex: 'department',
            key: 'department',
            render: (val, r) => {
                return val?.deptname
            }
        },
        {
            title: 'CLI',
            dataIndex: 'cli',
            key: 'cli'
        },
        // {
        //     title: 'Phone',
        //     dataIndex: 'mobile',
        //     key: 'mobile',
        // },
        // {
        //     title: 'Country',
        //     dataIndex: 'callercountry',
        //     key: 'callercountry',
        // },
        // {
        //     title: 'Status',
        //     dataIndex: 'callerstatus',
        //     key: 'callerstatus',
        // },
        {
            title: 'Status',
            dataIndex: 'online',
            key: 'online',
            render: (val, r) => {
                return <Tag style={{ borderRadius: 30, width: 80, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{(val ? 'online' : 'offline')}</Tag>
            }
        },
        // {
        //     title: 'Working hours',
        //     dataIndex: 'workinghours',
        //     key: 'workinghours',
        // },
        // {
        //     title: 'Comments',
        //     dataIndex: 'comments',
        //     key: 'comments',
        // },

        // {
        //     title: 'Created At',
        //     dataIndex: 'createdon',
        //     key: 'createdon',
        //     render: (val, r) => {
        //         return <Tag>{moment.utc(val).format('DD-MM-YYYY')}</Tag>
        //     }
        // },
        {
            title: 'Blocked',
            dataIndex: 'callerstatus',
            key: 'callerstatus',
            render: (val, record) => {
                return <Switch checkedChildren="On" unCheckedChildren="Off" checked={val == 'blocked' ? true : false} onChange={(e) => onChange(e, record?.id)} />
            }
        },
        {
            title: 'Actions',
            dataIndex: 'Action',
            key: 'Action',
            // width: 130,
            // fixed: 'right',
            render: (val, record) => {
                return <div className="flex" style={{ justifyContent: 'center' }}>
                    <div>
                        <Tooltip title="Delete" color='#ff0000a1' >
                            <DeleteOutlined style={{ color: "red", fontSize: 20 }} onClick={() => deleteUser(record)} />
                        </Tooltip>
                    </div>
                    <div style={{ marginLeft: 10 }}>
                        <Tooltip title="Edit">
                            <EditOutlined style={{ color: "primary", fontSize: 20 }} onClick={() => edit(record)} />
                        </Tooltip>
                    </div>
                    <div style={{ marginLeft: 10 }}>
                        <Tooltip title="Change Password" >
                            <LockOutlined style={{ color: "primary", fontSize: 20 }} onClick={() => {
                                setIsModalVisible(true)
                                setIdValue(record)
                            }} />
                        </Tooltip>
                    </div>
                    {currentUser?.balance?.cli && <div style={{ marginLeft: 10 }}>
                        <Tooltip title="Change Cli" color='#52c41aa8' >
                            <UserSwitchOutlined style={{ color: "green", fontSize: 20 }} onClick={() => {
                                setIsModalVisible1(true)
                                setIdValue1(record)
                            }} />
                        </Tooltip>
                    </div>}
                </div>
            }
        },
    ];
    const apiFunction = async () => {
        setLoader(true)
        let { user, token, success, message, data } = await Request.allCaller()
        setLoader(false)
        let newData = []
        if (success) {
            async.forEach(data, (val, cb) => {
                let obj = {
                    ...val?.auth,
                    ...val,
                }
                newData.push(obj)
                cb()
            }, () => {
                setDataSource(newData)
            })
        } else {
            setDataSource([])

            // notification.error({
            //     message: message || "Failed"
            // })
        }
    }
    useEffect(() => {
        apiFunction()
    }, [])
    if (!currentUser?.balance?.departments) {
        return <UpgradePlan />

    }

    return <div>
        <div className="flex jcsb" style={{ float: 'right', margin: 10 }}>
            <div>
                {/* <Input
                    placeholder="Search"
                    suffix={
                        <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                    }
                    style={{ borderRadius: '4px' }}
                /> */}
            </div>
            <Button type="primary" className="buttonPrimaryStyle" onClick={() => dispatch(getPushPathWrapper('addUser'))}>
                Add User
            </Button>
        </div>
        <div className="marginTop " >
            <FilterTable size="large" bordered dataSource={dataSource} columns={columns} loading={loading} scroll={{ x: 'max-content' }} />

        </div>
        <Modal title={<b>Change Password For {idValue?.auth?.firstname} {idValue?.auth?.lastname}</b>} destroyOnClose={true} visible={isModalVisible} footer={null} onOk={handleOk} onCancel={handleCancel}>
            <div className="flex aic jcc">
                <Form
                    {...layout}
                    onFinish={onFinish} hideRequiredMark={false} style={{ width: '90%' }}
                >
                    <div className='inputWidthNew' style={{ width: '100%' }}>
                        <Form.Item
                            label={'password'}
                            name="password"
                            maxLength={16}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                            hasFeedback
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item
                            name="confirm_new_password"
                            label="Confirm Password"
                            maxLength={16}
                            dependencies={['password']}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please confirm your password!'
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                    },
                                }),
                            ]}
                            hasFeedback
                        >
                            <Input.Password />
                        </Form.Item>

                    </div>
                    <div style={{ display: "flex", justifyContent: "space-around", marginTop: '2em' }}>
                        <div className="flex aic jcc" style={{ padding: 2 }}>
                            <Button type="secondary" onClick={() => setIsModalVisible(false)}>Cancel</Button>
                        </div>
                        <div className="flex aic jcc" style={{ padding: 2 }}>
                            <Form.Item style={{ marginBottom: 0 }}>
                                <Button loading={loading} htmlType='submit' type='primary'>Change Password</Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </div>
        </Modal>
        <Modal title="Change CLI" destroyOnClose width={500} height={400} footer={null} visible={isModalVisible1} onOk={handleOk1} onCancel={handleCancel1}>
            <div className="flex aic jcc">
                <Form style={{ width: '100%' }}
                    {...layout}
                    onFinish={onFinish1}
                >
                    <FormItem
                        label={'Caller ID'}
                        maxLength={11}
                        name='cli'
                        // rules={requiredRule}
                        width={'100%'}
                        inputType={'NUMBER'}
                        controls={false}
                        min={1}
                    />
                    <div className="" style={{ justifyContent: 'center', display: 'flex' }}>
                        <FormItem
                            inputType="BUTTON"
                            type="primary"
                            buttonStyle={{ width: 200 }}
                            loading={loading}
                            title="SUBMIT"
                            htmlType="submit" />
                    </div>
                </Form>
            </div>
        </Modal>
    </div>
}

export default HomeView;
