import React, { useState, useEffect, lazy } from 'react';
import { notification, Table, Button, Switch, Form, Modal } from 'antd';
import './styles.less'
import Request from '../../request'
import _ from 'lodash'
import { ReactComponent as Upgradeplan } from '../../assets/upgradeplan.svg'
import CallsPlans from '../../containers/plans'
import { push } from 'connected-react-router';
import moment from 'moment'
import { getPushPathWrapper } from '../../routes';
import { useDispatch, useSelector } from 'react-redux';
const Home = (props) => {
    const dispatch = useDispatch()
    const [modal, setModalVisible] = useState(false)
    const { currentUser } = useSelector((state) => ({
        currentUser: state.global.currentUser
    }))
    const reuploadDocs = () => {
        // window.location.href = "/reconfirmation"
        dispatch(push('/reconfirmation'))
    }

    return (<div className="">
        <div className="flex jcc headingPlan">
            This feature is not available in your plan
        </div>
        <div className="flex jcc">
            <Upgradeplan width={350} height={350} />
        </div>

        <div className="flex jcc headingSubPlan">
            Please upgrade your plan to avail this feature
        </div>
        <div className="flex jcc marginTopLarge">
            <Button type="primary" size="large" className="buttonPrimaryStyle" onClick={() => setModalVisible(true)}>
                Upgrade Plan
            </Button>
            {currentUser?.status?.name == null && <Modal title={<b>Upload Documents</b>} visible={modal} width={'40%'} footer={null} onCancel={() => { setModalVisible(false) }}>
                <div className="flex" style={{ justifyContent: 'center' }}>
                    <div style={{ marginLeft: 10, fontSize: 16, fontWeight: 500 }}>
                        Please upload documents before choosing any plan
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
                    <div className="flex aic marginTop jcc" style={{ padding: 2 }}>
                        <Button type="primary" onClick={() => reuploadDocs()}>Upload Documents</Button>
                    </div>
                </div>
            </Modal> }
               { currentUser?.status?.name == "pending" && <Modal title={<b>Document Verification Pending</b>} visible={modal} width={'40%'} footer={null} onCancel={() => { setModalVisible(false) }}>
                    <div className="flex" style={{ justifyContent: 'center' }}>
                        <div style={{ marginLeft: 10, fontSize: 16, fontWeight: 500 }}>
                            Your documents has been successfully uploaded and the document's verification is under process, it will take upto 2 working days.<br /> Please check again later.
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
                        <div className="flex aic marginTop jcc" style={{ padding: 2 }}>
                            <Button type="primary" onClick={() => setModalVisible(false)}>OK</Button>
                        </div>
                    </div>
                </Modal> }
                   {(currentUser?.status?.name == "verified" || currentUser?.plan == null) &&
                        <Modal visible={modal} width={'90%'} footer={null} onCancel={() => {
                            setModalVisible(false)
                        }}
                            title="Please Select The Plan First To Proceed Further">
                            <CallsPlans />
                        </Modal>}
        </div>
    </div>
    );
}

export default Home
