import React, { useState, useEffect, lazy } from "react";
import {
  Select,
  notification,
  Tooltip,
  Table,
  Button,
  Modal,
  Switch,
  Form,
  TimePicker,
  Input,
} from "antd";
import "./styles.less";
import Request from "../../request";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { confirmBox } from "../../utils";
import FormItem from "../../components/FormItem";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const Home = () => {
  const [dataSource, setState] = useState([]);
  const [dataDept, setDept] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [editId, setEditId] = useState();
  const [loading, setLoader] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [AHAtrack, setAHAtrack] = useState(" ");
  const [leadsetData, setLeadsetData] = useState([]);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const requiredRule = [{ required: true, message: "Please input field" }];

  const columns = [
    {
      title: "Name",
      dataIndex: "rule_name",
      key: "rule_name",
      width: 150,
      fixed: "left",
    },
    {
      title: "Department",
      dataIndex: "deptname",
      key: "deptname",
      width: 150,
    },
    // {
    //   title: "Department",
    //   dataIndex: "department",
    //   key: "department",
    // },
    {
      title: "Shift Start Time",
      dataIndex: "shift_start_time",
      key: "shift_start_time",
      render: (val, r) => {
        return <>{moment(val, "HH:mm:ss").format("HH:mm:ss")}</>;
      },
    },
    {
      title: "Shift End Time",
      dataIndex: "shift_end_time",
      key: "shift_end_time",
      render: (val, r) => {
        return <>{moment(val, "HH:mm:ss").format("HH:mm:ss")}</>;
      },
    },
    {
      title: "After Hour Action",
      dataIndex: "after_hour_action",
      key: "after_hour_action",
      render: (val, record) => {
        return <strong>{val}</strong>;
      },
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      render: (val, record) => {
        return (
          <Switch
            checkedChildren="On"
            unCheckedChildren="Off"
            checked={val === true ? true : false}
            onChange={() => {
              updateSwitch(record?.id, {
                customer: dataDept[0]?.userid,
                active: !val,
              });
            }}
          />
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "active",
      key: "active",
      render: (val, record) => {
        return (
          <div className="flex" style={{ justifyContent: "center" }}>
            <div>
              <Tooltip title="Delete deparment">
                <DeleteOutlined
                  style={{ color: "red", fontSize: 20 }}
                  onClick={() => deleteRoutingFunc(record?.id)}
                />
              </Tooltip>
            </div>
            <div style={{ marginLeft: 10 }}>
              <Tooltip title="Edit deparment">
                <EditOutlined
                  style={{ color: "primary", fontSize: 20 }}
                  onClick={() => {
                    setEditId(record);
                    showModal();
                  }}
                />
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  const updateSwitch = async (id, val) => {
    setLoader(true);
    const { data, success, error, message } = await Request.UpdateWorkingHours(
      id,
      val
    );
    setLoader(false);
    if (success) {
      notification.success({
        message: message,
      });
      apiFunction(selectedDepartment);
    } else {
      notification.error({
        message: message || "Failed",
        description: JSON.stringify(data)
          ?.replace("[", "")
          ?.replace("{", "")
          ?.replace("]", "")
          ?.replace("}", ""),
      });
    }
  };

  const apiFunction = async (valData) => {
    // if (valData?.id) {
    setLoader(true);
    let { user, token, success, message, data } =
      await Request.ListingWorkingHours({ department: valData?.id });
    setLoader(false);
    if (success) {
      if (!Array.isArray(data)) {
        return setState(() => []);
      }
      setState(() => data);
    } else {
      setState(() => []);
    }
    // } else {
    //   setState(() => []);
    // }
  };
  const apiFunction1 = async () => {
    setLoader(true);
    let { user, token, success, message, data } = await Request.allDept();
    setLoader(false);
    if (success && data.length) {
      setDept(data);
    } else {
      setDept([]);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const deleteRoutingFunc = (id) => {
    confirmBox(dispatch, {
      message: "Are you sure you want to this delete Work Hour Routing !!",
      onOk: async () => {
        setLoader(true);
        let { user, token, success, message, data } =
          await Request.DeleteWorkingHoursRouting(id);
        setLoader(false);
        if (success) {
          notification.success({
            message: message || "success",
          });
          apiFunction(selectedDepartment);
        } else {
          notification.error({
            message: message || "Failed",
          });
        }
      },
    });
  };
  const handleOk = () => {
    setIsModalVisible(false);
    setEditId();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditId();
  };

  useEffect(() => {
    if (editId) {
      let mainData = _.cloneDeep(editId);
      setAHAtrack(mainData.after_hour_action);
      form.setFieldsValue({
        rule_name: mainData?.rule_name,
        department: mainData?.department,
        after_hour_action: mainData?.after_hour_action,
        phonenumber: mainData?.phonenumber,
        leadfileid: mainData?.leadfileid,
        shift_start_time: dayjs(mainData?.shift_start_time, "HH:mm:ss"),
        shift_end_time: dayjs(mainData?.shift_end_time, "HH:mm:ss"),
      });
    } else {
      form.resetFields();
    }
  }, [editId]);

  useEffect(() => {
    apiFunction1();
    apiFunction();
  }, []);

  useEffect(() => {
    if (selectedDepartment) {
      apiFunction(selectedDepartment);
    }
  }, [selectedDepartment]);

  const onFinish = async (valData) => {
    if (editId?.id) {
      valData = {
        ...valData,
        customer: dataDept[0]?.userid,
        shift_start_time: valData.shift_start_time
          ? moment(valData.shift_start_time.$d).format("HH:mm:ss")
          : " ",
        shift_end_time: valData.shift_end_time
          ? moment(valData.shift_end_time.$d).format("HH:mm:ss")
          : " ",
      };
      setLoader(true);
      let { user, token, success, message, data } =
        await Request.UpdateWorkingHours(editId?.id, valData);
      setLoader(false);
      if (success) {
        notification.success({
          message: message,
        });
        form.resetFields();
        handleCancel();
        apiFunction(selectedDepartment);
      } else {
        notification.error({
          message: message || "Failed",
          description: JSON.stringify(data)
            ?.replace("[", "")
            ?.replace("{", "")
            ?.replace("]", "")
            ?.replace("}", ""),
        });
      }
    } else {
      valData = {
        ...valData,
        customer: dataDept[0]?.userid,
        shift_start_time: valData.shift_start_time
          ? moment(valData?.shift_start_time).format("HH:mm:ss")
          : " ",
        shift_end_time: valData.shift_end_time
          ? moment(valData.shift_end_time).format("HH:mm:ss")
          : " ",
      };
      setLoader(true);
      let { user, token, success, message, data } =
        await Request.defineWorkingHours(valData);
      setLoader(false);
      if (success) {
        notification.success({
          message: message,
        });
        form.resetFields();
        handleCancel();
        apiFunction(selectedDepartment);
      } else {
        notification.error({
          message: message || "Failed",
          description: JSON.stringify(data)
            ?.replace("[", "")
            ?.replace("{", "")
            ?.replace("]", "")
            ?.replace("}", ""),
        });
      }
    }
  };

  const afterHoursAction = [
    {
      id: 1,
      action: "Hangup",
    },
    {
      id: 2,
      action: "Voicemail",
    },
    {
      id: 3,
      action: "Forward to Number",
    },
    {
      id: 4,
      action: "Auto Dial List",
    },
  ];

  const getLeadSetListFunc = async () => {
    const { data, success } = await Request.leadsetData();
    if (success && data.length) {
      console.log(data);
      setLeadsetData(data);
    } else {
      setLeadsetData([]);
    }
  };

  return (
    <div className="">
      <h2>Working Hours</h2>
      <div className="flex jcsb">
        <div className="flex">
          <div style={{ fontSize: 16, marginRight: ".5rem" }}>
            Select Department :
          </div>
          <Select
            showSearch
            allowClear={true}
            // mode="multiple"
            // key={filters?.deptname}
            onChange={(val) => setSelectedDepartment({ id: val })}
            style={{
              width: 250,
              border: "1px solid #e1e3e8",
              borderRadius: "4px",
            }}
            placeholder="Select Department"
            // value={filters?.deptname}
            options={_.map(dataDept, (val) => {
              return { label: val?.deptname, value: val?.id };
            })}
          />
        </div>
        {selectedDepartment?.id && (
          <div className=" jcsb" style={{ float: "right", margin: 10 }}>
            <Button
              type="primary"
              className="buttonPrimaryStyle"
              loading={loading}
              onClick={() => showModal()}
            >
              Define Working Hours
            </Button>
          </div>
        )}
      </div>
      <div className="marginTop" style={{ width: "100%" }}>
        <Table
          pagination={false}
          dataSource={dataSource}
          columns={columns}
          loading={loading}
          rowKey="index"
          bordered
          size="small"
          scroll={{ x: "max-content" }}
        />
      </div>
      <Modal
        title={editId ? "Edit Working Hours" : "Define Working Hours"}
        destroyOnClose
        width={700}
        height={600}
        footer={null}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          {...layout}
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            remember: true,
            department: selectedDepartment?.id,
          }}
        >
          <FormItem label={"Name"} name="rule_name" rules={requiredRule} />
          <FormItem
            label={"Department"}
            name="department"
            inputType={"SELECT"}
            options={_.map(dataDept, (val) => {
              return { label: val?.deptname, value: val?.id };
            })}
            rules={requiredRule}
          />
          <Form.Item
            label="Shift Start Time"
            name="shift_start_time"
            rules={requiredRule}
          >
            <TimePicker format="HH:mm:ss" />
          </Form.Item>
          <Form.Item
            label="Shift End Time"
            name="shift_end_time"
            rules={requiredRule}
          >
            <TimePicker format="HH:mm:ss" />
          </Form.Item>
          <FormItem
            label={"After Hour Action"}
            name="after_hour_action"
            inputType={"SELECT"}
            options={_.map(afterHoursAction, (val) => {
              return { label: val?.action, value: val?.action };
            })}
            onChange={(e) => {
              setAHAtrack(e);
            }}
            rules={requiredRule}
          />
          {AHAtrack === afterHoursAction[2].action ? (
            <Form.Item
              label="Phone Number"
              name="phonenumber"
              rules={[
                {
                  pattern: new RegExp(/^(?!0)\d{10,15}$/),
                  message: "please enter a valid phone number",
                },
                { required: true, message: "Please fill the Phone number" },
              ]}
            >
              <Input
                style={{
                  width: "100%",
                }}
                type="number"
              />
            </Form.Item>
          ) : AHAtrack === afterHoursAction[3].action ? (
            <FormItem
              label={"Leadset"}
              name="leadfileid"
              inputType={"SELECT"}
              onFocus={getLeadSetListFunc}
              options={_.map(leadsetData, (val) => {
                return { label: val?.name, value: val?.id };
              })}
              rules={requiredRule}
            />
          ) : null}
          <FormItem
            label="Active"
            name="active"
            checkedChildren="On"
            unCheckedChildren="Off"
            defaultChecked={editId?.active || false}
            inputType={"SWITCH"}
          />
          <div className="marginTop">
            <FormItem
              inputType="BUTTON"
              tailLayout={tailLayout}
              type="primary"
              buttonStyle={{ width: 200, marginTop: 20 }}
              loading={loading}
              title="SUBMIT"
              htmlType="submit"
            />
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default Home;
