import { useState, lazy, useEffect } from 'react';
import { Card, Space, Tooltip, Image, Radio, List, Table, Tabs, Button, Input } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import './styles.less'
import {
  AudioOutlined, MenuUnfoldOutlined, DatabaseOutlined,
  CreditCardOutlined, MenuFoldOutlined, CloudUploadOutlined, TagsOutlined
} from '@ant-design/icons';
import { FieldTimeOutlined, InfoCircleOutlined, UserOutlined, SearchOutlined } from '@ant-design/icons';
import { ReactComponent as Information } from '../../assets/information.svg'
import Leadset from './leadset'
import Department from './list'
import LeadsetData from './leadsetData'
import Disposition from './disposition/list'
import WorkingHours from './WorkingHours'
import { Link, Route } from 'react-router-dom'

import _ from 'lodash'

import { confirmBox } from '../../utils';

const { Search } = Input;


const { TabPane } = Tabs;

let dept = false

const DeptList = (props) => {
  const dispatch = useDispatch()
  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser
  }))
  const [state, setState] = useState({})

  const LIST = [
    {
      name: 'Departments',
      active: true,
      icon: <CreditCardOutlined style={{ fontSize: 20 }} />,
      path: './department'
    },

    currentUser?.plan?.powerdialer || currentUser?.plan?.previewdialer || currentUser?.plan?.predictivedialer ?
      {
        name: 'Upload Leadset',
        active: false,
        icon: <CloudUploadOutlined style={{ fontSize: 20 }} />,
        path: './Leadset'
      } : { active: undefined, },

    currentUser?.plan?.powerdialer || currentUser?.plan?.previewdialer || currentUser?.plan?.predictivedialer ?
      {
        name: 'Leadset Data',
        active: false,
        icon: <DatabaseOutlined style={{ fontSize: 20 }} />,
        path: './LeadsetData',
      } : { active: undefined, },

    {
      name: 'Disposition',
      active: false,
      icon: <TagsOutlined style={{ fontSize: 20 }} />,
      path: '/Disposition'
    },
    {
      name: 'Working Hours',
      active: false,
      icon: <FieldTimeOutlined style={{ fontSize: 20 }} />,
      path: '/workingHours'
    },
  ];

  const suffix = (
    <AudioOutlined
      style={{
        fontSize: 16,
        color: '#1890ff',
      }}
    />
  );
  useEffect(() => {
    setState({ dataSource: _.cloneDeep(LIST), selectedKey: _.cloneDeep(LIST)[0] })
  }, [])

  const onChange = (index) => {
    let newData = _.map(state?.dataSource, (e) => {
      if (e.active == undefined) {
        e.active = undefined
      } else {
        e.active = false;
      }
      return e
    })
    if (index >= 0)
      newData[index].active = true

    setState((prev) => ({
      ...prev,
      categories: newData,
      selectedKey: newData[index]

    }))
  }

  const onSearch = value => console.log(value);
  const [open, setOpen] = useState(false);

  return <div>
    <div className='flex' style={{ alignItems: 'flex-start' }}>
      <div>
        <Button style={{ borderRadius: '6px', marginBottom: '5px', width: '58px' }} onClick={() => { setOpen(!open) }}>{!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}</Button>
        <Card className={`SettingMenu ${open ? null : 'open'}`}>
          <List
            // loading={categoryLoading}
            size="small"
            dataSource={state.dataSource}
            renderItem={(item, i) => (
              <List.Item
                key={item.id}
                title={item.name}
                onClick={() => onChange(i)}
                //  onClick={() => visit(i) } 
                className={`settingListItem ${item?.active && 'settingListItemSelected'}`}>
                <List.Item.Meta avatar={
                  item?.icon
                } title={item?.name}></List.Item.Meta>
              </List.Item>
            )}
          />
        </Card>
      </div>
      <div
        className='settingListWrapper'
      >
        {state?.selectedKey?.name == 'Departments' && <Department />}
        {state?.selectedKey?.name == 'Upload Leadset' && <Leadset />}
        {state?.selectedKey?.name == 'Leadset Data' && <LeadsetData />}
        {state?.selectedKey?.name == 'Disposition' && <Disposition />}
        {state?.selectedKey?.name == 'Working Hours' && <WorkingHours />}
      </div>
    </div>
  </div>
}

export default DeptList;