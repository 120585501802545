import react, { useEffect, useState } from 'react'
import { Form, Input, Button, notification, Card, Switch, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import FormItem from '../../components/FormItem'
import './styles.less'
import { useDispatch } from 'react-redux';
import Request from '../../request';
import _ from 'lodash'
import axios from 'axios'
import async from 'async'

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 8,
    },
};

const Leadset = (props) => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [loader, setLoader] = useState(false)
    const [loader1, setLoader1] = useState(false)
    const [loader2, setLoader2] = useState(false)
    const [file, setFile] = useState([])
    const [department, setDepartment] = useState([])
    const [countries, setCountries] = useState([])

    const getFunction = async () => {
        let { user, token, success, data } = await Request.getCountries()
        if (success) {
            let setOnlyCountries = []
            async.forEach(data, (value, cb) => {
                if (!value?.default_blocked) {
                    setOnlyCountries.push(value)
                }
                cb()
            }, () => {
                setCountries(() => setOnlyCountries)
            })
        } else {
            setCountries(() => [])

        }
    }
    const apiFunction = async () => {
        let { user, message, success, data } = await Request.allDept()

        if (success) {
            setDepartment(() => data)
        } else {
            setDepartment(() => [])

        }
    }

    const onFinish = async (valData, e) => {
        const MAINFORM = new FormData();
        // MAINFORM.append('file', valData?.file[0]?.originFileObj)
        // valData = {
        //     // file: fie,
        //     isdcode: parseInt(valData?.country[1]),
        //     country: _.upperCase(valData?.country[0]),
        //     departmentId: parseInt(valData?.departmentId),
        //     duplicateCheck: valData?.duplicateCheck,
        //     active: valData?.active ? valData?.active : false
        //     // name: valData?.leadsetname,
        //     // departmentId: valData?.department,
        //     // country: valData?.country,
        //     // duplicateCheck: valData?.duplicateCheck,
        //     // active: active
        // }
        _.forEach(valData, (value, key) => {
            if (key == 'file') {
                MAINFORM.append(key, value ? value[0]?.originFileObj ? value[0]?.originFileObj : null : null)
            }
            else if (key == 'country') {
                MAINFORM.append('country', value ? value[0] : null)
                MAINFORM.append('isdcode', value ? value[1] : null)
            }
            else if (key == 'active') {
                MAINFORM.append(key, value ? value : false)
            }
            else {
                MAINFORM.append(key, value ? value : null)
            }
        })
        if (MAINFORM) {
            setLoader2(true)

            let { user, token, success, message, Count } = await Request.addLeadset(MAINFORM)
            setLoader2(false)
            if (success) {
                setFile(Count)
                notification.success({
                    message: message || 'SUCCESS'
                    // <div>
                    //     <p> Total :{Count.Total}</p>
                    //     <p> Uploaded :{Count.Uploaded}</p>
                    //     <p> Not Uploaded :{Count.NotUploaded}</p>
                    //     <p>Duplicate :{Count.Duplicate}</p>
                    // </div>

                })
                form.resetFields()
            } else {
                notification.error({
                    message: `${message}` || "Failed",


                })
            }
        }

    }
    const requiredRule = [{ required: true, message: 'Please input field' }]
    const accept = ".csv,.xlsx"

    const onReset = () => {
        setLoader1(true)
        form.resetFields();
        setLoader1(false)
    };

    const normFile = (e) => {
        // console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    }

    const duplicateCheck = [
        {
            'id': 1,
            'type': 'None',
            'val': 'NO',
        },
        {
            'id': 1,
            'type': 'File Only',
            'val': 'YES',
        },
        //  {
        //     'id': 2,
        //     'type': 'File Only',
        //     'val': 'fileCheck',
        // },
        // {
        //     'id': 3,
        //     'type': 'Department',
        //     'val': 'department',
        // },
        // {
        //     'id': 4,
        //     'type': 'System',
        //     'val': 'system',
        // },

    ];


    useEffect(() => {
        getFunction()
        apiFunction()
    }, [])

    const width = window.innerWidth;

    return (<div style={{ width: 'auto', overflow: 'hidden' }}>

        <Card style={{ flex: 1 }}>
            <Form
                {...layout}
                form={form}
                layout='vertical'
                onFinish={onFinish}
            >

                <FormItem
                    label={'Department'}
                    name="departmentId"
                    inputType={"SELECT"}
                    rules={requiredRule}
                    options={_.map(department, val => { return ({ label: val?.deptname, value: val?.id }) })}
                />

                <FormItem
                    label={'Leadset Name'}
                    name="name"
                    rules={requiredRule}
                    maxLength={20}
                />

                <FormItem
                    label={'Leadset File'}
                    accept={accept}
                    maxCount={1}
                    name="file"
                    // beforeUpload={file => {
                    //     const reader = new FileReader();
                    //     reader.onload = e => {
                    //         console.log(e.target.result);
                    //     };
                    //     reader.readAsText(file);
                    //     // Prevent upload
                    //     return false;
                    // }}
                    getValueFromEvent={normFile}
                    inputType={"UPLOAD"}
                    multiple={false}
                    listType="picture"
                    rules={requiredRule}
                />

                <FormItem
                    label={'Duplicate Check'}
                    initialValue='NO'
                    showSearch='false'
                    name="duplicateCheck"
                    inputType={"SELECT"}
                    rules={requiredRule}
                    options={_.map(duplicateCheck, val => { return ({ label: val?.type, value: val?.val }) })}
                />
                <FormItem
                    label={'Active'}
                    inputType={'SWITCH'}
                    name="active"
                />
                <FormItem
                    label={'Country'}
                    name="country"
                    inputType={"SELECT"}
                    options={_.map(countries, val => {
                        return ({ label: val?.countryname, value: [val?.countryname, val?.phonecode] })
                    })}
                />

                <div >
                    {width > 425 ? <Form.Item
                        {...tailLayout}>

                        <Button htmlType="button" loading={loader1} onClick={onReset} style={{ width: 120, marginRight: 80, marginTop: 30 }}> Cancel </Button>
                        <Button type="primary" loading={loader2} htmlType="submit" style={{ width: 120, marginTop: 30 }}> Submit  </Button>
                    </Form.Item>
                        : <Form.Item
                        >
                            <Button htmlType="button" loading={loader1} onClick={onReset} style={{ width: 120, marginTop: 30, alignItems: 'center', paddingLeft: '20px', marginRight: 50, marginLeft: 30 }}> Cancel </Button>
                            <Button type="primary" loading={loader2} htmlType="submit" style={{ width: 120, marginTop: 30, paddingLeft: '20px' }}> Submit  </Button>
                        </Form.Item>}
                </div>
            </Form>
        </Card >
    </div>
    );
};

export default Leadset;