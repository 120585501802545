import { lazy, useEffect, useState } from 'react';
import { List, Collapse, Switch, Popover, Image, notification, Space, Spin, Input, Button, Form, Card } from 'antd';
import { EditOutlined, ShareAltOutlined, DeleteFilled, MoreOutlined } from '@ant-design/icons'
// import InfiniteScroll from 'react-infinite-scroller';
import './styles.less'
import { useDispatch, useSelector } from 'react-redux';
// import { getPushPathWrapper } from '../../routes';
import findIndex from 'lodash/findIndex'
import { groupBy, map } from 'lodash';
// import { ReactComponent as NoProducts } from '../../assets/noProducts.svg'
const LIST = [{}, {}, {}, {}, {}, {}]
const ProductList = (props) => {
    const { searchParams, productCategoryId, category, onCategoryChange, refreshCategory } = props
    const dispatch = useDispatch()
    const [state, setState] = useState({
        data: [],
        loading: false,
        showShare: false,
        showAll: true,
        productId: 0,
        productCategory: 0
    })
    const { currentUser } = useSelector((state) => ({
        currentUser: state.global.currentUser
    }))
    return (
        <div className="settingListWrapper">
            <div className="" style={{ overflow: 'auto' }}>
                <div className="titleStyle">Call Recording</div>
                <List
                    className='SettingListElem'
                    // loading={loading}
                    dataSource={LIST}

                >
                    <List.Item className='settingSdapList' >
                        <List.Item.Meta
                            title={
                                <div className="flex">
                                    <div className="Circle marginRightLarge">

                                    </div>
                                    <div>
                                        <div className="InnerStyleList"> {true ? 'Sales UK' : <Form size='large' className='flex'  >
                                            <Form.Item name={['Department Name']}>
                                                <Input placeholder='Product Name' />
                                            </Form.Item>
                                        </Form>}
                                        </div>
                                        <div className="InnerStyleSubHeading">Insert name of your department</div>
                                    </div>
                                </div>
                            }
                        />
                    </List.Item>
                    <List.Item className='settingSdapList' >
                        <List.Item.Meta

                            title={
                                <div className="flex">
                                <div className="Circle marginRightLarge">

                                </div>
                                <div>
                                    <div className="InnerStyleList"> {true ? '+445787634123' : <Form size='large' className='flex'  >
                                        <Form.Item name={['Department Name']}>
                                            <Input placeholder='Product Name' />
                                        </Form.Item>
                                    </Form>}
                                    </div>
                                    <div className="InnerStyleSubHeading">Your virtual phone number</div>
                                </div>
                            </div>
                            }
                        />
                    </List.Item>
                    <List.Item className='settingSdapList' >
                        <List.Item.Meta

                            title={<div className="flex">
                                <div className="marginRightLarge">
                                    <Switch defaultChecked
                                    // onChange={onChange}
                                    />
                                </div>
                                <div className="InnerStyleList">Custom Caller ID</div>
                            </div>
                            }
                        />
                    </List.Item>
                    <List.Item className='settingSdapList' >
                        <List.Item.Meta

                            title={<div className="flex">
                                <div className="marginRightLarge">
                                    <Switch defaultChecked
                                    // onChange={onChange}
                                    />
                                </div>
                                <div>
                                    <div className="InnerStyleList">Set custom ringing time for incoming calls</div>
                                    <div className="InnerStyleSubHeading">This allows you to  set ringing time as per your requirement for incoming calls</div>
                                    <div className="flex Duration">
                                        <div className="InnerStyleList ">Duration (seconds): </div>
                                        <div className="DurationSub">20</div>
                                    </div>
                                </div>
                            </div>
                            }
                        />
                    </List.Item>

                    <List.Item className='settingSdapList' >
                        <List.Item.Meta
                            title={<div className="flex">
                                <div className="marginRightLarge">
                                    <Switch defaultChecked
                                    // onChange={onChange}
                                    />
                                </div>
                                <div>
                                    <div className="InnerStyleList">Show XYZ number in incoming forwarded calls</div>
                                    <div className="InnerStyleSubHeading">Works with call forwarding in incoming calls only.Enabling it will show this XYZ number on your phone during an incoming call,
                                    helping you identify calls from XYZ.However,disabling it will display the caller’s name directly.</div>
                                </div>
                            </div>
                            }
                        />
                    </List.Item>
                    <List.Item className='settingSdapList' >
                        <List.Item.Meta
                            title={<div className="flex">
                                <div className="Circle marginRightLarge">

                                </div>
                                <div>
                                    <div className="InnerStyleList">Number Verification</div>
                                    <div className="InnerStyleSubHeading">Numbers must be verified.You can upload address proof if required.</div>
                                </div>
                            </div>
                            }
                        />
                    </List.Item>
                </List>
            </div>
        </div>
    );

}

export default ProductList;