import { useState, lazy, useEffect } from 'react';
import { Card, Space, Modal, notification, Switch, message, Table, Tabs, Button, Input, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import './styles.less'
import { AudioOutlined, DeleteColumnOutlined, SearchOutlined, DeleteOutlined, DeleteTwoTone, EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { confirmBox } from '../../utils';
import Request from '../../request'
import _, { isBoolean } from 'lodash'
import { getPushPathWrapper } from '../../routes';
import FilterTable from '../../components/Table'
const { Search } = Input;
const colors = ['#302b2b', '#FB743E', '#18CDCA', '#3c26b7', '#21e004', '#812349']
const Demo = () => {
    const dispatch = useDispatch()
    const { currentUser } = useSelector((state) => ({
        currentUser: state.global.currentUser
    }))
    const [dataSource, setDataSource] = useState([])
    const [dataSource1, setDataSource1] = useState([])
    const [loading, setLoader] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisible1, setIsModalVisible1] = useState(false);
    const [isModalVisible2, setIsModalVisible2] = useState(false);
    const [InputExtention, setInputExtention] = useState(false);
    const [buyPlan, setBuyPlan] = useState({});
    const [planSummaryDetails, setPlanSummaryDetails] = useState({});
    const [planType, setPlantype] = useState('');

    const apiFunction1 = async () => {
        setLoader(true)
        let { data, success } = await Request.getProfile()
        setLoader(false)
        if (success) {
            dispatch({
                type: 'SET_CURRENT_USER',
                payload: { ...data }
            })
            setDataSource1(() => data)
        } else {
            setDataSource1(() => { })
            // notification.error({
            //   message: message || "Failed"
            // })
        }
    }


    const apiFunction = async () => {
        setLoader(true)
        let { user, token, success, message, data } = await Request.getPlans()
        setLoader(false)
        if (success) {
            setDataSource(() => data)
        } else {
            setDataSource(() => [])

            // notification.error({
            //   message: message || "Failed"
            // })
        }
    }
    const onFinish = async () => {
        setLoader(true)
        let { user, token, success, message, data } = await Request.selectPlan({ plan: buyPlan?.id, plantype: planType, selectedcallercount: parseInt(InputExtention) })
        setLoader(false)
        if (success) {
            // console.log("in plan <<<<<<<<<<<<<<<<<<<<<<<<<<<<<")
            dispatch({
                type: 'SET_CURRENT_USER',
                payload: { ...data }
            })
            setIsModalVisible(false)
            setIsModalVisible1(false)
            setIsModalVisible2(false)
            notification.success({
                message: message || "success"
            })
        } else {

            notification.error({
                message: message || "Failed"
            })
        }
    }
    const selectPlan = async (type) => {
        setLoader(true)
        let { user, token, success, message, data } = await Request.getSummary({ plan: buyPlan?.id, plantype: type, selectedcallercount: parseInt(InputExtention) })
        setLoader(false)
        if (success) {
            setPlanSummaryDetails(data)
            setPlantype(type)
            setIsModalVisible2(true)
        }
        else {

            notification.error({
                message: message || "Failed"
            })
        }

    }

    const showModal = () => {
        setIsModalVisible(true);
    };

    const showModal1 = () => {
        setIsModalVisible2(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const handleOk1 = () => {
        setIsModalVisible1(true);
    };

    const handleCancel1 = () => {
        setIsModalVisible1(false);
    };
    const handleOk2 = () => {
        setIsModalVisible2(true);
    };

    const handleCancel2 = () => {
        setIsModalVisible2(false);
    };

    useEffect(() => {
        apiFunction()
        apiFunction1()
    }, [])

    useEffect(() => {
        if (dataSource1?.balance) {
            selectPlan(dataSource1?.balance?.plantype)
        }
    }, [buyPlan])

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'New Plan',
            dataIndex: 'new_value',
            key: 'new_value',
            render: (val, record) => {
                return <Tag color={val ? '#87d068' : '#f50'}>{isBoolean(val) ? val ? 'Available' : 'Unavailable' : val}</Tag>
            }
        },
        {
            title: 'Old Plan',
            dataIndex: 'prev_value',
            key: 'prev_value',
            render: (val, record) => {
                return <Tag color={val ? '#87d068' : '#f50'}>{isBoolean(val) ? val ? 'Available' : 'Unavailable' : val}</Tag>
            }
        },

    ];
    return (
        <div className="planFlex jcsa">
            {_.map(dataSource, (val, i) => {
                return <div className="PricingTable">
                    <div className="rightArrowDiv">
                        <div className="imagediv" style={{ background: colors[i] }}></div>
                    </div>
                    <div className="priceSubContainer">
                        <div className="priceSub">
                            <div className="pricingTitle">{_.capitalize(val?.planname)} Plan</div>
                            <div className="pricingLine"></div>
                            <div className="flex" style={{ justifyContent: 'center' }}>
                                <div className="price priceSub" style={{ color: colors[i] }}>${val?.yearprice}</div>
                                <div className="monthWise"> /year</div>
                            </div>
                            <div className="pricingValue">${val?.monthprice} /month</div>
                            <div className="pricingValue">{val?.freenumbers} Free Numbers</div>
                            <div className="pricingValue">
                                <div style={{ textAlign: "center", margin: '0px 10px 0px 10px' }}>
                                    {'Call rate starts from as low as 0.02$ per min'}</div>
                            </div>
                            <div className="pricingValue">{val?.freeminutes} Free Minutes</div>
                            <div className="pricingValue">Upto {val?.maximumallowedusers}  Extention</div>
                            <div className="pricingValue">
                                <div style={{ textAlign: "center", margin: '0px 10px 0px 10px' }}>
                                    {val?.plandescription}</div>
                            </div>
                        </div>
                    </div>
                    <div className="priceSub marginVertical">
                        <Button loading={loading} style={{ width: '60%', backgroundColor: colors[i], color: 'white' }} onClick={() => {
                            {
                                !dataSource1?.balance ? showModal() : <></>
                                // showModal1()
                                dataSource1?.balance ? setInputExtention(dataSource1?.balance?.selectedcallercount) : <></>
                            }
                            setBuyPlan(val)
                        }} >Buy</Button>
                    </div>
                </div>

            })}
            <Modal title="Enter Number of Extentions You Want" visible={isModalVisible} footer={null} onOk={handleOk} onCancel={handleCancel}>
                <div className="flex aic jcc" style={{ width: 400 }}>
                    <div style={{ marginRight: 10 }}>Enter Number </div>
                    <Input type="number" controls={false} min={0} value={InputExtention} onChange={(e) => setInputExtention(e?.target?.value)} style={{ width: 118 }} max={buyPlan?.maximumallowedusers} />
                </div>
                <div className="flex aic marginTop jcc" style={{ width: 400 }}>
                    <Button type="primary" onClick={() => handleOk1()}>Submit</Button>
                </div>
            </Modal>

            <Modal title="Confirm" visible={isModalVisible1} footer={null} onOk={handleOk1} onCancel={handleCancel1}>
                <div className="flex">
                    <div><InfoCircleOutlined style={{ fontSize: '25px', color: '#f9f340' }} /></div>
                    <div style={{ marginLeft: 10, fontSize: 16, fontWeight: 500 }}>
                        Select Monthly or Yearly plan
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
                    <div className="flex aic marginTop jcc" style={{ padding: 2, marginRight: 10 }}>
                        <Button type="secondary" onClick={() => selectPlan('month')}>Monthly</Button>
                    </div>

                    <div className="flex aic marginTop jcc" style={{ padding: 2 }}>
                        <Button type="primary" onClick={() => selectPlan('annual')}>Yearly</Button>
                    </div>
                </div>

            </Modal>
            <Modal title="Plan Update Features" visible={isModalVisible2} width={800} footer={null} onOk={handleOk2} onCancel={handleCancel2}>
                <div className="flex ">
                    <div className="marginRight3" style={{ fontSize: 18 }}>
                        New Plan Costing: {planSummaryDetails?.new_cost}
                    </div>

                    {dataSource1?.balance ? <div style={{ fontSize: 18 }}>
                        Old Plan Costing:
                        {planSummaryDetails?.old_cost}
                    </div> :
                        <></>}
                </div>
                <FilterTable pagination={false} bordered dataSource={planSummaryDetails?.features} size="small" columns={columns} loading={loading} />
                <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
                    <div className="flex aic marginTop jcc" style={{ padding: 2, marginRight: 10 }}>
                        <Button type="secondary" onClick={() => setIsModalVisible2(false)}>Cancel</Button>
                    </div>

                    <div className="flex aic marginTop jcc" style={{ padding: 2 }}>
                        <Button type="primary" onClick={() => onFinish()}>Submit</Button>
                    </div>
                </div>

            </Modal>
        </div>

    );
};
export default Demo