import { useState, useEffect } from "react";
import { DatePicker, Select, Button, Tag, notification } from "antd";
import { useSelector } from "react-redux";
import "./styles.less";
import Request from "../../request";
import moment from "moment";
import _ from "lodash";
import FilterTable from "../../components/Table";
import UpgradePlan from "../settings/upgradePlan";
const { RangePicker } = DatePicker;

const RealTimeScreen = (props) => {
  const [loader, setLoader] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [filters, setFilters] = useState({});
  const [department, setDepartment] = useState([]);

  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser,
  }));

  const apiFunction = async (filters) => {
    let newFilter = _.cloneDeep(filters);
    setLoader(true);
    let { user, token, success, message, data } =
      await Request.fetchRealTimeScreen(newFilter);
    setLoader(false);
    if (success && data.length) {
      setDataSource(data);
    } else {
      setDataSource([]);
    }
  };

  const getDepartentListing = async () => {
    const { error, success, message, data } = await Request.allDept();
    if (success) {
      setDepartment(data);
    }
  };

  useEffect(() => {
    apiFunction();
    getDepartentListing();
    const refreshPage = setInterval(() => {
      apiFunction();
    }, 7000);

    return () => {
      clearInterval(refreshPage);
    };
  }, []);

  const columns = [
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Caller",
      dataIndex: "caller",
      key: "caller",
      render: (val, r) => {
        return val.auth.firstname + " " + val.auth.lastname;
      },
    },
    {
      title: "Department",
      dataIndex: "queueName",
      key: "queueName",
    },
    {
      title: "Call Started At",
      dataIndex: "createdon",
      key: "createdon",
      render: (val, r) => {
        return <>{moment.utc(val).format("DD-MM-YYYY HH:mm:ss")}</>;
      },
    },
    {
      title: "Duration",
      dataIndex: "createdon",
      key: "createdon",
      render: (val, r) => {
        return (
          <strong>
            {moment.utc().subtract(moment.utc(val)).format("HH:mm:ss")}
          </strong>
        );
      },
    },
    {
      title: "Call Type",
      dataIndex: "calltype",
      key: "calltype",
    },
  ];

  //   const invDownlod = async (filters) => {
  //     let newFilter = _.cloneDeep(filters);
  //     if (newFilter.date) {
  //       newFilter = {
  //         ...newFilter,
  //         startDate: newFilter?.date[0],
  //         endDate: newFilter?.date[1],
  //       };
  //       delete newFilter.date;
  //     } else {
  //       delete newFilter.date;
  //     }
  //     setLoader(true);
  //     let { user, token, success, message, data } =
  //       await Request.downloadCallerJobTime(newFilter);
  //     setLoader(false);
  //   };

  if (!currentUser?.plan?.callrecording) {
    return <UpgradePlan />;
  }

  return (
    <div>
      <>
        <h2>Real Time Call Screen</h2>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "5px",
          }}
        >
          <Select
            showSearch
            allowClear={true}
            onChange={(val) => setFilters((prev) => ({ ...prev, deptid: val }))}
            style={{
              width: 200,
              border: "1px solid #e1e3e8",
              borderRadius: "4px",
            }}
            placeholder="Select Department"
            value={filters?.caller}
            options={_.map(department, (val) => {
              return { label: val?.deptname, value: val?.id };
            })}
          />
          <Button
            type="primary"
            className="buttonPrimaryStyle"
            onClick={() => apiFunction(filters)}
          >
            Search or Refresh
          </Button>
          {/* <Button
            type="primary"
            onClick={() => invDownlod(filters)}
            loading={loader}
          >
            Download
          </Button> */}
        </div>
        <div className="marginTop">
          <FilterTable
            pagination={false}
            bordered
            size="small"
            dataSource={dataSource}
            columns={columns}
            scroll={{ x: "max-content" }}
          />
        </div>
      </>
    </div>
  );
};

export default RealTimeScreen;
