import React, { useState, useEffect, lazy } from 'react';
import { Select, notification, Tooltip, Table, Button, Modal, Switch, Form } from 'antd';
import '../styles.less'
import Request from '../../../request'
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash'
import moment from 'moment'
import { confirmBox } from '../../../utils';
import FormItem from '../../../components/FormItem'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';


const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

const Home = () => {

    const [dataSource, setState] = useState([])
    const [dataDept, setDept] = useState([])
    const [selectedDepartment, setSelectedDepartment] = useState()
    const [editId, setEditId] = useState()
    const [loading, setLoader] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const requiredRule = [{ required: true, message: 'Please input field' }]
    const requiredRule1 = [{ required: true, message: 'Please input field', min: 0 }]
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 150,
            fixed: 'left',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (val, record) => {
                return <Switch checkedChildren="On" unCheckedChildren="Off" checked={val} onChange={(e) => onChange(e, record?.id, 'status')} />
            }
        },
        {
            title: 'Available On App',
            dataIndex: 'availableonapp',
            key: 'availableonapp',
            render: (val, record) => {
                return <Switch checkedChildren="On" unCheckedChildren="Off" checked={val} onChange={(e) => onChange(e, record?.id, 'availableonapp')} />
            }
        },
        {
            title: 'Redialable',
            dataIndex: 'redialable',
            key: 'redialable',
            render: (val, record) => {
                return <Switch checkedChildren="On" unCheckedChildren="Off" checked={val} onChange={(e) => onChange(e, record?.id, 'redialable')} />
            }
        },
        {
            title: 'Actions',
            dataIndex: 'Action',
            key: 'Action',
            // width: 180,
            // fixed: 'right',
            render: (val, record) => {
                return <div className="flex" style={{ justifyContent: 'center' }}>
                    <div>
                        <Tooltip title="Delete deparment">
                            <DeleteOutlined style={{ color: "red", fontSize: 20 }} onClick={() => deleteDisposition(record?.id)} />
                        </Tooltip>
                    </div>
                    <div style={{ marginLeft: 10 }}>
                        <Tooltip title="Edit deparment">
                            <EditOutlined style={{ color: "primary", fontSize: 20 }} onClick={() => { setEditId(record); showModal() }} />
                        </Tooltip>
                    </div>
                </div>
            },
        }
    ];
    const apiFunction = async (valData) => {
        if (valData?.id) {
            setLoader(true)
            let { user, token, success, message, data } = await Request.listDispositions({ department: valData?.id })
            setLoader(false)
            if (success) {
                setState(() => data)
            } else {
                setState(() => [])
            }
        }
        else {
            setState(() => [])
        }
    }
    const apiFunction1 = async () => {
        setLoader(true)
        let { user, token, success, message, data } = await Request.allDept()
        setLoader(false)
        if (success && data.length) {
            setDept(data)
        } else {
            setDept([])

            // notification.error({
            //   message: message || "Failed"
            // })
        }
    }

    const showModal = () => {
        setIsModalVisible(true);
    };
    const onChange = async (val, id, stat) => {
        setLoader(true)
        if (stat == 'status') {
            let { user, token, success, message, data } = await Request.updateDisposition({ status: val }, id)
            setLoader(false)
            if (success) {
                notification.success({
                    message: message || "success"
                })
                apiFunction(selectedDepartment)
            } else {
                notification.error({
                    message: message || "Failed"
                })
            }
        }
        else if (stat == 'redialable') {
            let { user, token, success, message, data } = await Request.updateDisposition({ redialable: val }, id)
            setLoader(false)
            if (success) {
                notification.success({
                    message: message || "success"
                })
                apiFunction(selectedDepartment)
            } else {
                notification.error({
                    message: message || "Failed"
                })
            }
        }
        else {
            let { user, token, success, message, data } = await Request.updateDisposition({ availableonapp: val }, id)
            setLoader(false)
            if (success) {
                notification.success({
                    message: message || "success"
                })
                apiFunction(selectedDepartment)
            } else {
                notification.error({
                    message: message || "Failed"
                })
            }
        }

    }
    const deleteDisposition = (id) => {
        confirmBox(dispatch, {
            message: 'Are you sure you want to delete disposition !!',
            onOk: async () => {
                setLoader(true)
                let { user, token, success, message, data } = await Request.deleteDisposition(id)
                setLoader(false)
                if (success) {
                    notification.success({
                        message: message || "success"
                    })
                    apiFunction(selectedDepartment)
                } else {
                    notification.error({
                        message: message || "Failed"
                    })
                }
            }
        })
    }
    const handleOk = () => {
        setIsModalVisible(false);
        setEditId()
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setEditId()
    };

    useEffect(() => {
        if (editId) {
            let mainData = editId
            mainData = {
                ...mainData,
            }
            form.setFieldsValue({ ...mainData })
        } else {
            form.resetFields()
        }

    }, [editId])
    useEffect(() => {
        apiFunction1()
    }, [])
    useEffect(() => {
        if (selectedDepartment) {
            apiFunction(selectedDepartment)
        }
    }, [selectedDepartment])
    const onFinish = async (valData) => {
        if (editId?.id) {
            valData = {
                ...valData,
                customer: dataDept[0]?.userid,
                department: selectedDepartment?.id
            }
            setLoader(true)
            let { user, token, success, message, data } = await Request.updateDisposition(valData, editId?.id)
            setLoader(false)
            if (success) {
                notification.success({
                    message: message
                })
                form.resetFields()
                handleCancel()
                apiFunction(selectedDepartment)

            } else {
                notification.error({
                    message: message || "Failed",
                    description: JSON.stringify(data)?.replace('[', '')?.replace('{', '')?.replace(']', '')?.replace('}', '')

                })
            }
        }
        else {
            valData = {
                ...valData,
                customer: dataDept[0]?.userid,
                department: selectedDepartment?.id
            }
            setLoader(true)
            let { user, token, success, message, data } = await Request.addDisposition(valData)
            setLoader(false)
            if (success) {
                notification.success({
                    message: message
                })
                form.resetFields()
                handleCancel()
                apiFunction(selectedDepartment)

            } else {
                notification.error({
                    message: message || "Failed",
                    description: JSON.stringify(data)?.replace('[', '')?.replace('{', '')?.replace(']', '')?.replace('}', '')

                })
            }
        }
    }

    return (
        <div className="">
            <h2>Disposition</h2>
            <div className="flex jcsb">
                <div className="flex">
                    <div style={{ fontSize: 16, marginRight: '.5rem' }}>Select Department :</div>
                    <Select
                        showSearch
                        allowClear={true}
                        // mode="multiple"
                        // key={filters?.deptname}
                        onChange={(val) => setSelectedDepartment({ id: val })}
                        style={{ width: 250, border: '1px solid #e1e3e8', borderRadius: '4px' }}
                        placeholder="Select Department"
                        // value={filters?.deptname}
                        options={_.map(dataDept, val => { return ({ label: val?.deptname, value: val?.id }) })}

                    />
                </div>
                {selectedDepartment?.id && <div className=" jcsb" style={{ float: 'right', margin: 10 }} >
                    <Button type="primary" className="buttonPrimaryStyle" loading={loading} onClick={() => showModal()}>Add Disposition</Button>
                </div>}
            </div>
            <div className="marginTop" style={{ width: '100%' }}>
                <Table
                    pagination={false}
                    dataSource={dataSource}
                    columns={columns}
                    loading={loading}
                    rowKey="index"
                    bordered
                    size="small"
                    scroll={{ x: 'max-content' }}
                />
            </div>
            <Modal title={(editId) ? "Edit Disposition" : "Add Disposition"} destroyOnClose width={700} height={600} footer={null} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <Form
                    {...layout}
                    form={form}
                    layout='vertical'
                    onFinish={onFinish}
                >
                    <FormItem
                        label={'Name'}
                        name="name"
                        rules={requiredRule}
                    />
                    {!editId?.id && <><FormItem
                        label={'Status'}
                        name="status"
                        inputType={'SWITCH'}
                    />
                        <FormItem
                            label={'Available on app'}
                            name="availableonapp"
                            inputType={'SWITCH'}
                        />
                        <FormItem
                            label={'Redialable'}
                            name="redialable"
                            inputType={'SWITCH'}
                            defaultChecked={true}
                        />
                    </>}
                    <div className="marginTop">
                        <FormItem
                            inputType="BUTTON"
                            tailLayout={tailLayout}
                            type="primary"
                            buttonStyle={{ width: 200, marginTop: 20 }}
                            loading={loading}
                            title="SUBMIT"
                            htmlType="submit" />
                    </div>
                </Form>
            </Modal>
        </div >
    );
}

export default Home
