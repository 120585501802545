import { combineReducers } from 'redux'
import global from './global'
import theme from './theme'
import putData from './put'
import putData2 from './dept'
import call from './calls'
import { connectRouter, routerMiddleware } from 'connected-react-router'

export default (history) => combineReducers({
  theme,
  global,
  call,
  putData,
  putData2,
  router: connectRouter(history)
})
