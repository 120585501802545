import { useState, useEffect } from 'react';
import { Card, DatePicker, Tooltip, Image, Select, message, Table, Tabs, Button, Input, Tag, notification } from 'antd';
import { useSelector } from 'react-redux';
import './styles.less'
import { AudioOutlined, DownloadOutlined } from '@ant-design/icons';
import FilterTable from '../../components/Table'
import Request from '../../request'
import { DateFormat } from '../../settings'
import _ from 'lodash'

import moment from 'moment'
const { RangePicker } = DatePicker;

const HomeView = (props) => {

  const [state, setState] = useState({
    data: [],
    loading: false,
    hasMore: true,
    orderStatus: 'PENDING',
    showQr: false
  })
  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser
  }))
  const [dataSource, setDataSource] = useState([])
  const [loading, setLoader] = useState(false)
  const [filters, setFilters] = useState({})

  const apiFunction = async (filter) => {
    setLoader(true)
    let { user, token, success, message, data } = await Request.getPaymentReports({ startDate: filter?.date[0], endDate: filter?.date[1] })
    setLoader(false)
    if (success && data.length) {
      setDataSource(data)
    } else {
      setDataSource([])

      // notification.error({
      //   message: message || "Failed"
      // })
    }
  }
  useEffect(() => {
    setFilters({ date: [moment().subtract('days', 7), moment()] })
    apiFunction({ date: [moment().subtract('days', 7), moment()] })
  }, [])

  const downloadInvoice = async (orderid) => {
    setLoader(true)
    let { data, success } = await Request.downloadInvoicePDF({ orderid: orderid })
    setLoader(false)
  }

  const downloadXls = async (filters) => {
    setLoader(true)
    let { user, token, success, message, data } = await Request.downloadXlsForPaymentHistory({ startDate: filters?.date[0], endDate: filters?.date[1] })
    setLoader(false)
  }

  const emailMe = async (filter) => {
    setLoader(true)
    let { data, message, success } = await Request.emailmepayment({startDate: filters?.date[0], endDate: filters?.date[1], email: "email" })
    if (success) {
      notification.success({
        message: message || 'Email sent successfully'
      })
    } else {
      notification.error({
        message: message || 'Failed'
      })
    }
    setLoader(false)
  }

  const columns = [
    {
      title: 'Date',
      dataIndex: 'createdon',
      key: 'createdon',
      render: (val, r) => {
        return <>{moment.utc(val, 'YYYY-MM-DD').format(DateFormat)}</>
      }
    },
    {
      title: 'Invoice Number',
      dataIndex: 'orderId',
      key: 'orderId',
      render: (val, r) => {
        return <>{r?.transactions?.gateway_response?.orderId}</>
      }
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (val, r) => {
        return <>{r?.transactions?.amount}</>
      }
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (val, r) => {
        return <>{r?.description?.description}</>
      }
    },
    {
      title: 'Payment Status',
      dataIndex: 'status',
      key: 'status',
      render: (val, r) => {
        return <>{r?.transactions?.status}</>
      }
    },
    {
      title: 'Download Invoice',
      dataIndex: 'Download Invoice',
      key: 'downloadinvoice',
      render: (val, record) => {
        return <div className="flex" style={{ justifyContent: 'center' }}>
          <div>
            <Tooltip title="Download Invoice">
              <div><DownloadOutlined style={{ fontSize: 24, color: 'darkblue' }} onClick={() => downloadInvoice(record?.transactions?.gateway_response?.orderId)} /></div>
            </Tooltip>
          </div>
        </div>
      }
    },

  ];
  return <div>
    <h2>Payment History</h2>
    <div className="flex marginRight jcsb ">

      <div className="flex marginRight ">
        <div className="marginRight">
          <RangePicker onChange={(v) => setFilters((prev) => ({ ...prev, date: v }))} value={filters?.date} />
        </div>
        <Button type="primary" className="buttonPrimaryStyle" loading={loading} onClick={() => apiFunction(filters)}>
          Search
        </Button>
      </div>
      <Button type="secondary" className="buttonPrimaryStyle" loading={loading} onClick={() => downloadXls(filters)}>
        Download .xls/.csv
      </Button>
      <Button type="secondary" className="buttonPrimaryStyle" loading={loading} style={{ marginLeft: '15px' }} onClick={() => emailMe(filters)}>
        Email Me
      </Button>
    </div>
    <div className="marginTopLarge">
      <FilterTable size="large" bordered dataSource={dataSource} columns={columns} loading={loading} />

    </div>
  </div>
}

export default HomeView;