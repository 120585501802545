import { lazy, useEffect, useState } from 'react';
import { List, Collapse, Switch, Modal, Radio, Image, notification, Space, Spin, Input, InputNumber, Button, Form, Card } from 'antd';
import './styles.less'
import { AudioOutlined, DeleteColumnOutlined, SearchOutlined, DeleteOutlined, DeleteTwoTone, EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { ReactComponent as ImageThumb } from '../../assets/LoginPage.svg'
import Request from '../../request'

const AddCredit = () => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [form] = Form.useForm()

    const requiredRule = [{ required: true, message: 'Please input field' }]

    const onLoginFinish = async (valData) => {
        {
            setLoader(true)
            let { user, token, success, message: loginMessage, data } = await Request.login(valData)
            if (success) {
                dispatch({
                    type: 'SET_AUTH_TOKEN',
                    payload: data.auth_token
                })
                Request.getProfile().then(({ error, data: profileData, message }) => {
                    if (!error && profileData) {
                        dispatch({
                            type: 'SET_CURRENT_USER',
                            payload: { ...profileData }
                        })
                        dispatch(push('/addCredit'))
                        notification.success({
                            message: loginMessage
                        })

                    } else {
                        notification.error({
                            message: message || "Failed",
                            description: JSON.stringify(profileData).replace('[', '').replace('{', '').replace(']', '').replace('}', '')

                        })
                    }
                })
            }
            else {
                notification.error({
                    message: loginMessage || "Failed"
                })
            }
            setLoader(false)
        }
    }

    return (<>
        <div className="LoginParentDiv">
            <div className="LoginSubDiv">
                <div className="LeftSubDiv">
                    <div className="loginPageCustomer">
                        Single Platform for all Your Business Telephony Requirements!
                    </div>
                    <div style={{ fontSize: '12px' }}>
                        Empower your business and employees globally with our integrated contact center application.
                    </div>
                    <ImageThumb height="50vh" />
                </div>
            </div>
            <div className='loginWrapper'>
                <div className='loginContainer' style={{paddingTop:'40px'}}>
                    <div className="displayFlex">
                        <Form className='form' form={form} onFinish={onLoginFinish} size='large'>
                            <div className='inputWidth'>
                                <Form.Item name='email' rules={requiredRule}>
                                    <Input type="email" placeholder='Enter Email ID' />
                                </Form.Item>
                                <Form.Item name='password' rules={requiredRule}>
                                    <Input maxLength={16} type="password" placeholder='Enter Password' />
                                </Form.Item>
                            </div>
                            <Form.Item style={{width:'80%'}}>
                                <Button size='large' loading={loader} htmlType='submit' type='primary' style={{ width: '90%', marginTop: '3rem' }}>Sign in</Button>
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="TermsAndCond">
                    <div className="TermData">By clicking the button above,you agree to our <Space />
                        <span className="termsOfUse">terms of use</span>
                        <Space /> <span>and</span>
                        <span className="termsOfUse"> privacy policy</span>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </>
    )
}

export default AddCredit
