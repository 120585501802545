import { useState, lazy } from 'react';
import { Card, Space, Typography , Result, Radio, message, Table, Tabs, Button, Input } from 'antd';
import { useSelector,useDispatch } from 'react-redux';
import './styles.less'
import { AudioOutlined } from '@ant-design/icons';
import { InfoCircleOutlined, UserOutlined, SearchOutlined } from '@ant-design/icons';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useParams } from 'react-router';
import { apiUrl } from '../../settings';
import { push } from 'connected-react-router';

const { Paragraph, Text } = Typography;

const HomeView = (props) => {
  const { id } = useParams()
  const dispatch = useDispatch()

  const [state, setState] = useState({
    data: [],
    loading: false,
    hasMore: true,
    orderStatus: 'PENDING',
    showQr: false
  })
  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser
  }))

  return <div>
    <Result
    status="error"
    title="Payment Failed"
    subTitle="Please check and modify the following information before resubmitting."
    extra={[
      <Button type="primary" key="console"
      onClick={()=>{
        dispatch(push(''))
      }}>
        Go Back to Dashboard
      </Button>,
      <Button key="buy" onClick={()=>{
        window.location.href = `${apiUrl}/payments/${id}`
      }}>Pay Again</Button>,
    ]}
  >
    <div className="desc">
      <Paragraph>
        <Text
          strong
          style={{
            fontSize: 16,
          }}
        >
          The content you submitted has the following error:
        </Text>
      </Paragraph>
      <Paragraph>
        <CloseCircleOutlined className="site-result-demo-error-icon" /> Please check your internet Speed.
      </Paragraph>
      <Paragraph>
        <CloseCircleOutlined className="site-result-demo-error-icon" /> Your account/Bank declines the Payment.
      </Paragraph>
    </div>
  </Result>
  </div>
}

export default HomeView;