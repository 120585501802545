import React, { useState, useEffect, lazy } from 'react';
import { notification, Table, Tag, Modal, Switch, Form } from 'antd';
import './styles.less'
import Request from '../../request'
import _ from 'lodash'
import moment from 'moment'
import FormItem from '../../components/FormItem'
import { DeleteOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';


const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

const planKeyMapping = {
    'afterhourrouting': 'After Hour Routing',
    'agentblocking': 'Agent Blocking',
    'automaticvoicemail': 'Automatic Voicemail on Power Dialer',
    'callbarging': 'Call Barging',
    'callblocking': 'Call Blocking',
    'callconference': 'Call Conference',
    'callcostlimit': 'Call Cost Limit',
    'callholdwithmusic': 'Call Hold With Music',
    'calllogs': 'Call Logs',
    'callnotes': 'Call Notes',
    'callpriority': 'Call Priority Queues',
    'callqueues': 'Call Queues',
    'callrecording': 'Call Recording',
    'callreminder': 'Call Reminder',
    'callscheduling': 'Call Scheduling',
    'callwhispering': 'Call Whispering',
    'carrierswitch': 'Carrier Switch',
    'cdrreport': 'CDR Report',
    'cli': 'CLI',
    'concurrentcalls': 'Concurrent Calls',
    'contact': 'Contact Management',
    'countryblocking': 'Country Blocking',
    'customcarriersetup': 'Custom Carrier Setup',
    'customreport': 'Custom Reports',
    'customwelcomemessage': 'Custom Welcome Message',
    'dashboard': 'Dashboard',
    'defaultcli': 'Default CLI',
    'defaultcountrysetting': 'Default Country Setting',
    'deletecalllog': 'Delete Call Logs',
    'departments': 'Departments',
    'dnc': 'DNC List',
    'emailnotificationforvoicemail': 'Email Notification for Voicemail',
    'forwardtodevice': 'Forward to Device',
    'forwardtomultipledevice': 'Forward to Multiple Devices',
    'globalcontact': 'Global Connect',
    'holidayrouting': 'Holiday Routing',
    'intelligentreporting': 'Intelligent Reports/Analytics',
    'internalteamcalling': 'Internal Team calling',
    'ipwhitelisting': 'IP Whitelisting',
    'lastcallsummary': 'Last Call Summary',
    'mobileapp': 'Mobile App',
    'postcallsurvey': 'Post Call Survey',
    'powerdialer': 'Power Dialer',
    'reportsonemail': 'Reports on Email',
    'sms': 'SMS',
    'twofactorauthentication': 'Two Factor Authentication',
    'unlimitedcalls': 'Unlimited Calls',
    'userrightscustomization': 'User Rights Customization',
    'voicemailwithcustomgreeting': 'Voicemail with Custom Greeting',
    'warmandblindtransfer': 'Warm and Blind Transfer',
}


const Home = () => {
    const { currentUser } = useSelector((state) => ({
        currentUser: state.global.currentUser
    }))
    const [dataSource, setState] = useState([])
    const [filters, setFilters] = useState({})
    const [loading, setLoader] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm()
    const requiredRule = [{ required: true, message: 'Please input field' }]
    const columns = [
        {
            title: 'Country Code',
            dataIndex: 'countrycode',
            key: 'countrycode',
        },
        {
            title: 'Phone Code',
            dataIndex: 'phonecode',
            key: 'phonecode',
            // search: true
        },
        {
            title: 'Country Name',
            dataIndex: 'countryname',
            key: 'countryname',
            search: true
        },
        {
            title: 'Blocked',
            dataIndex: 'default_blocked',
            key: 'default_blocked',
            render: (val, r) => {
                return <div className="flex jcc">
                    <Tag style={{ borderRadius: 3, width: 80, display: "flex", justifyContent: "center" }}
                        color={'#FA424A'}>{val ? 'True' : 'False'}</Tag>
                </div>
            }
        },

    ];
    const apiFunction = async () => {
        setState(() => currentUser?.balance)
    }
    useEffect(() => {
        apiFunction()
    }, [])

    return (<div className="">
        {/* <Table
               dataSource={dataSource}
                columns={columns}
                rowKey="index"
                title={()=><>Blocked Countries</>}
                bordered
                size="small"
            /> */}
        <div className="flex">
            <div className="flex marginRight3">
                <Tag color={'#87d068'} width={5}></Tag>
                <div>Available Features</div>
            </div>
            <div className="flex">
                <Tag color={'#f50'} width={5}></Tag>
                <div>Unavailable Features</div>
            </div>
        </div>

        {
            dataSource ? <div className="marginTop">
                <div className="flex" style={{ flexWrap: "wrap", justifyContent: "center", overflow: "auto" }}>
                    {_.map(dataSource, (val, key) => {
                        if (key == 'balance' || key == 'totalfreeminutes' || key == 'selectedcallercount'
                            || key == 'planexpiry' || key == 'callrecordingstorage'
                            || key == 'plantype' || key == 'freenumbers'
                            || key == 'maximumallowedusers' || key == 'workinghours' || key == 'customer'
                            || key == 'datastorage' || key == 'freeminutes') return
                        // console.log(dataSource, "%%%%%%%%")
                        let i = 0
                        return <Tag style={{
                            margin: 8,
                            padding: '1vh 1vh 1vh 1vh',
                            minWidth: '19em',
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 4, height: '6vh',
                            border: '1px solid #ebebeb'
                        }} color={val ? 'green' : 'red'}>{planKeyMapping[key]}</Tag>
                    })}
                </div>

            </div> : null
        }
    </div>
    );
}

export default Home
