import { useState, lazy, useEffect } from 'react';
import { Card, Space, Tooltip, Result, Radio, message, Table, Tabs, Button, Input } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import './styles.less'
import { AudioOutlined } from '@ant-design/icons';
import { InfoCircleOutlined, UserOutlined, SearchOutlined } from '@ant-design/icons';
import { useParams } from 'react-router';
import { push } from 'connected-react-router';
import { Link, useHistory } from 'react-router-dom';


const HomeView = (props) => {
  const { id } = useParams()
  const dispatch = useDispatch()

  const history = useHistory()
  const [state, setState] = useState()
  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser
  }))
  useEffect(() => {

  }, [id])
  return <div>
    <Result
      status="success"
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column"
      }}
      title={<div style={{ fontSize: 35, margin: 10 }}>Your Payment is Successfully Proceeded !</div>}
      subTitle={<div style={{ fontSize: 20, margin: 10 }}>Order number: #{id} Your New Plan is implemented !</div>}
      extra={[
        <Button type="primary" onClick={() => {
          dispatch(push('/dashboard'))
        }} key="console" style={{ margin: 10 }}>
          Go to Dashboard
        </Button>
      ]}
    />
    {setTimeout(() => {
      history.push('/dashboard')
    }, 5000)}
  </div>
}

export default HomeView;