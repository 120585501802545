import { useState, lazy, useEffect } from 'react';
import { Card, DatePicker, Tooltip, Image, Select, message, Table, Tabs, Button, Input, Tag, notification } from 'antd';
import { useSelector } from 'react-redux';
import './styles.less'
import { AudioOutlined, DownloadOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { InfoCircleOutlined, UserOutlined, SearchOutlined } from '@ant-design/icons';
import Request from '../../request';
import moment from 'moment'
import _ from 'lodash'
import Modal from 'antd/lib/modal/Modal';
import async, { auto } from 'async'
import FilterTable from '../../components/Table'
import { AudioDownloadFormat, DateFormat } from '../../settings'
import UpgradePlan from '../settings/upgradePlan'

const { Search } = Input;
const { RangePicker } = DatePicker;

const callType = [
  {
    value: 'Inbound',
    call_type: 'Inbound'
  },
  {
    value: 'Outbound',
    call_type: 'Outbound'
  },
  {
    value: 'Missed',
    call_type: 'MISSED'
  }
]

const HomeView = (props) => {

  const [loader, setLoader] = useState(false)
  const [dataSource, setDataSource] = useState([])

  const [filters, setFilters] = useState({})
  const [department, setDeptName] = useState([])
  const [callers, setCaller] = useState([])
  const [pageTotal, setPageTotal] = useState();
  const [showModal, setShowModal] = useState(false)
  const [media, setmedia] = useState('')


  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser
  }))

  const apiFunction = async (filters) => {
    // console.log(filters)
    setLoader(true)
    let { user, token, success, message, data, total } = await Request.callHistory(filters)
    setLoader(false)
    let newData = []
    if (success && data.length) {
      async.forEach(data, (val, cb) => {
        let obj = {
          // ...val?.caller?.auth,
          ...val,
        }
        newData.push(obj)
        cb()
      }, () => {
        setDataSource(newData)
        setPageTotal(total)
      })
    } else {
      setDataSource([])
    }
  }

  const getDepartment = async () => {
    let { user, token, success, message, data } = await Request.allDept()
    if (success && data.length) {
      setDeptName(data)
    } else {
      setDeptName([])
    }
  }
  const getCaller = async () => {
    let { user, token, success, message, data } = await Request.allCaller()
    if (success && data.length) {
      setCaller(data)
    } else {
      setCaller([])
    }
  }
  useEffect(() => {
    apiFunction()
    getDepartment()
    getCaller()
  }, [])
  const suffix = (
    <AudioOutlined
      style={{
        fontSize: 16,
        color: '#1890ff',
      }}
    />
  );
  const setShowModalFxn = async (id) => {
    setLoader(true)
    let { data, success } = await Request.getFiles({ context: 'recording', id: id })
    if (data?.file && success) {
      setmedia(() => data?.file)
      setShowModal(true)
    }
    setLoader(false)

  }
  const downloadRecording = async (id) => {
    setLoader(true)
    let { data, success } = await Request.getFiles({ context: 'recording', id: id })
    if (data?.file && success) {
      const linkSource = 'data:' + `application/${AudioDownloadFormat}` + ';base64, ' + data?.file;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = data?.type;
      // downloadLink.download = `recordings.${AudioDownloadFormat}`;
      downloadLink.click();
    }
    setLoader(false)

  }
  const downloadRecordingXls = async (id) => {
    setLoader(true)
    let { data, success } = await Request.downloadXlCalls(filters)
    setLoader(false)

  }
  const emailMe = async () => {
    const filter = {
      "email": "email",
      ...filters
    }
    setLoader(true)
    let { data, message, success } = await Request.emailme(filter)
    if (success) {
      notification.success({
        message: message || 'Email sent successfully'
      })
    } else {
      notification.error({
        message: message || 'Failed'
      })
    }
    setLoader(false)
  }
  const onSearch = value => console.log(value);

  const columns = [
    {
      title: 'Caller',
      dataIndex: 'firstname',
      key: 'firstname',
      width: 150,
      fixed: 'left',
      render: (val, r) => {
        return <>{r?.caller?.auth?.firstname ? r?.caller?.auth?.firstname : ""} {r?.caller?.auth?.lastname ? r?.caller?.auth?.lastname : ""}</>
      }
    },
    {
      title: 'Department',
      dataIndex: 'deptname',
      key: 'deptname',
      width: 150,
      search: true
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      width: 140,

    },
    {
      title: 'Call Type',
      dataIndex: 'calltype',
      key: 'calltype',
      render: (val, r) => {
        return <Tag style={{ borderRadius: 3, display: "flex", fontWeight: 600, justifyContent: "center", backgroundColor: 'transparent', border: 'none', color: `${val?.toLowerCase() == 'outbound' ? '#108ee9' : val?.toLowerCase() == 'inbound' ? "#46C35F" : '#FA424A'}` }}>{_.startCase(val)}</Tag>
      }
    },
    {
      title: 'DID Number',
      dataIndex: 'did_number',
      key: 'did_number',
      width: 140,
    },
    {
      title: '3rd Party Number',
      dataIndex: 'thirdpartynumber',
      key: 'thirdpartynumber',
      width: 140,
    },
    {
      title: 'Date',
      dataIndex: 'createdon',
      key: 'createdon',
      render: (val, r) => {
        return <>{moment.utc(val).format(DateFormat)}</>
      }
    },

    {
      title: 'Time',
      dataIndex: 'createdon',
      key: 'createdon',
      render: (val, r) => {
        return <>{moment.utc(val).utc().format('LTS')}</>
      }
    },

    {
      title: 'Cost',
      dataIndex: 'cost',
      key: 'cost',
    },
    {
      title: 'Call Duration',
      dataIndex: 'callduration',
      key: 'callduration',
    },
    // {
    //   title: 'Active',
    //   dataIndex: 'active',
    //   key: 'active',
    //   render: (val, r) => {
    //     return <Tag style={{borderRadius:30,width:60,display:"flex",justifyContent:"center"}}  color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },
    // {
    //   title: 'Created At',
    //   dataIndex: 'createdon',
    //   key: 'createdon',
    //   width: 180,
    //   defaultSortOrder: 'descend',
    //   sorter: (a, b) => a.createdon - b.createdon,
    //   render: (val, r) => {
    //     return <Tag>{moment.utc(val).format('DD-MM-YYYY')}</Tag>
    //   }
    // },
    {
      title: 'Actions',
      dataIndex: 'Action',
      key: 'Action',
      // width: 180,
      // fixed: 'right',
      render: (val, r) => {
        return <div className="flex jcc aic">
          {r.recording ? <div style={{ marginRight: 10 }}><PlayCircleOutlined style={{ fontSize: 24, color: '#008b8b' }} onClick={() => setShowModalFxn(r?.id)} /></div> : <div></div>}
          {r.recording ? <div style={{ marginRight: 10 }}><DownloadOutlined style={{ fontSize: 24, color: 'darkblue' }} onClick={() => downloadRecording(r?.id)} /></div> : <div></div>}
        </div>
      }
    },


  ];
  if (!currentUser?.plan?.planname) {
    return <UpgradePlan />
  }

  return <div>
    <h2>Call History</h2>
    <div style={{ gridGap: '5px', flexWrap: 'wrap', display: 'flex' }}>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
        <div className="marginRight">
          <RangePicker onChange={(v) => setFilters((prev) => ({ ...prev, date: v }))} value={filters?.date} />
        </div>
        <div className="marginRight">
          <Select
            showSearch
            allowClear={true}
            onChange={(val) => setFilters((prev) => ({ ...prev, dept: val }))}
            style={{ width: 200, border: '1px solid #e1e3e8', borderRadius: '4px' }}
            placeholder="Select Dept"
            value={filters?.dept}
            options={_.map(department, val => { return ({ label: val?.deptname, value: val?.id }) })}

          />
        </div>
        <div className="marginRight">
          <Select
            showSearch
            allowClear={true}
            onChange={(val) => setFilters((prev) => ({ ...prev, calltype: val }))}
            style={{ width: 200, border: '1px solid #e1e3e8', borderRadius: '4px' }}
            placeholder="Select Call Type"
            value={filters?.calltype}
            options={_.map(callType, val => { return ({ label: val?.value, value: val?.call_type }) })}

          />
        </div>
        <div className="marginRight">
          <Select
            showSearch
            allowClear={true}
            onChange={(val) => setFilters((prev) => ({ ...prev, caller: val }))}
            style={{ width: 200, border: '1px solid #e1e3e8', borderRadius: '4px' }}
            placeholder="Select Callers"
            value={filters?.caller}
            options={_.map(callers, val => { return ({ label: val?.auth?.firstname, value: val?.id }) })}

          />
        </div>
        <Button type="primary" className="buttonPrimaryStyle" loading={loader} onClick={() => apiFunction(filters)}>
          Search
        </Button>

      </div>
      <Button type="secondary" className="buttonPrimaryStyle" loading={loader} onClick={() => downloadRecordingXls(filters)}>
        Download Csv/Xls
      </Button>
      <Button type="secondary" className="buttonPrimaryStyle" style={{}} loading={loader} onClick={() => emailMe(filters)}>
        Email Me
      </Button>
    </div>
    <div className="marginTop" style={{ width: '100%' }}>
      <FilterTable pagination={{
        onChange: page => {
          apiFunction({ ...filters, page: page, })
        },
        pageSize: 20,
        total: pageTotal,
        hideOnSinglePage: true,
        showSizeChanger: false
      }}
        bordered dataSource={dataSource} columns={columns} loading={loader} scroll={{ x: 'max-content' }} />

    </div>
    <Modal visible={showModal}
      destroyOnClose={true}
      onCancel={() => {
        setShowModal(false)
        setmedia('')
      }} footer={null}>
      <div className="recording">Play Recording</div>
      <div className="marginTop">
        <audio
          controls
          type="audio/wav"
          src={"data:audio/wav;base64," + media}>
          Your browser does not support the
          <code>audio</code> element.
        </audio>
      </div>
    </Modal>
  </div>
}

export default HomeView;