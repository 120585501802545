import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Radio, Form, Modal, notification, Switch, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import FormItem from '../../../components/FormItem';
import Request from '../../../request';
import '../styles.less';
import async from 'async'
import _ from 'lodash'
import { ConsoleView } from 'react-device-detect';

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

const Home = () => {

    const [dataSource, setState] = useState([])
    const [editId, setEditId] = useState()
    const [loading, setLoader] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [countries, setCountries] = useState([])
    const [form] = Form.useForm()
    const requiredRule = [{ required: true, message: 'Please input field' }]
    const requiredRule1 = [{ required: true, message: 'Please input field', min: 0 }]
    const [value1, setValue] = useState()
    const columns = [
        {
            title: 'DID Number',
            dataIndex: 'phonenumber',
            key: 'phonenumber',
            fixed: 'left',
            // width: 150
        },
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country',
            search: true
        },
        {
            title: 'Authentication Type',
            dataIndex: 'method',
            key: 'method',
            // fixed: 'left',
            width: 200,
            render: (val, r) => {
                return <>{r?.gateway?.method}</>
            }
        },
        {
            title: 'IP',
            dataIndex: 'ip',
            key: 'ip',
            render: (val, r) => {
                return <>{r?.gateway?.ip}</>
            }
        },
        {
            title: 'Port',
            dataIndex: 'port',
            key: 'port',
            render: (val, r) => {
                return <>{r?.gateway?.port}</>
            }
        },
        // {
        //     title: 'Tollfree',
        //     dataIndex: 'tollfree',
        //     key: 'tollfree'
        // },
        // {
        //     title: 'Active',
        //     dataIndex: 'active',
        //     key: 'active',
        //     render: (val, record) => {
        //         return <Switch checkedChildren="On" unCheckedChildren="Off" checked={val} onChange={(e) => onChange(e, record?.id)} />
        //     }
        // },
        {
            title: 'Actions',
            dataIndex: 'Action',
            key: 'Action',
            // width: 180,
            // fixed: 'right',
            render: (val, r) => {
                return <div className="flex" style={{ justifyContent: 'center' }}>
                    <div>
                        <Tooltip title="Edit">
                            <EditOutlined style={{ color: "primary", fontSize: 20 }} onClick={() => {
                                setEditId(r)
                                showModal(r?.id)
                                apiFunction()
                                setValue(r?.gateway?.method)

                            }} />
                        </Tooltip>
                    </div>
                    <div style={{ marginLeft: 10 }}>
                        <Tooltip title="Delete" color='#ff0000a1' >
                            <DeleteOutlined style={{ color: "red", fontSize: 20 }} onClick={() => deleteContacts(r?.id)} />
                        </Tooltip>
                    </div>
                </div>
            },
        }
    ];
    const apiFunction = async () => {
        setLoader(true)
        let { user, token, success, message, data } = await Request.allDID()
        setLoader(false)
        if (success) {
            setState(() => data)
        } else {
            setState(() => [])
        }

    }

    useEffect(() => {
        if (editId) {
            let mainData = editId
            mainData = {
                ...mainData,
                ...mainData.gateway,
                method: mainData?.gateway?.method == 'user' ? 'username/password' : 'ip based',
                tollfree: mainData?.tollfree == true ? 'true' : 'false',
                gusername: mainData?.gateway?.username,
            }
            form.setFieldsValue({ ...mainData })
        } else {
            form.resetFields()

        }

    }, [editId])

    const showModal = () => {
        setIsModalVisible(true);
    };
    const deleteContacts = async (id) => {
        setLoader(true)
        let { user, token, success, message, data } = await Request.deleteDID(id)
        setLoader(false)
        if (success) {
            notification.success({
                message: message || "success"
            })
            apiFunction()
        } else {
            notification.error({
                message: message || "Failed"
            })
        }
    }
    const handleOk = () => {
        setIsModalVisible(false);
        setEditId()
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setEditId()
    };
    useEffect(() => {
        apiFunction()
        getFunction()
    }, [])
    const getFunction = async () => {
        let { user, token, success, data } = await Request.getCountries()
        if (success) {
            let setOnlyCountries = []
            async.forEach(data, (value, cb) => {
                if (!value?.default_blocked) {
                    setOnlyCountries.push(value)
                }
                cb()
            }, () => {
                setCountries(() => setOnlyCountries)
            })
        } else {
            setCountries(() => [])
        }
    }
    const onFinish = async (valData) => {
        var tollfree = valData?.tollfree == 'true' ? true : false
        if (editId?.id) {
            setLoader(true)
            valData = {
                phonenumber: valData?.phonenumber,
                ...valData,
                gateway: {
                    method: (valData?.method == undefined ? 'ip' : (valData?.method == 'ip based' || valData?.method == 'ip') ? 'ip' : 'user'),
                    ip: valData?.ip,
                    dns: valData?.dns,
                    username: valData?.gusername,
                    password: valData?.password,
                    port: valData?.port,
                },
                country: valData?.country,
                tollfree: tollfree
            }
            let { user, token, success, message, data } = await Request.updateGateway(editId?.gateway?.id, { ...valData.gateway })
            setLoader(false)
            var dataId = data?.id;
            if (success) {
                setLoader(true)
                valData = {
                    ...valData,
                    gateway: dataId,
                }
                let { user, token, success, message, data } = await Request.updateDID(editId?.id, { ...valData })
                setLoader(false)
                if (success) {
                    setValue()
                    notification.success({
                        message: message
                    })
                }
                else {
                    notification.error({
                        message: `${message}` || "Failed"
                    })
                }
                form.resetFields()
                apiFunction()
                setIsModalVisible(false);
            } else {
                notification.error({
                    message: `${message}` || "Failed"
                })
            }
        } else {
            setLoader(true)
            var phonenumber = valData.phonenumber
            var country = valData.country
            var tollfree = tollfree

            valData = {
                method: (valData?.method == undefined ? 'ip' : valData?.method == 'ip based' ? 'ip' : 'user'),
                ip: valData?.ip,
                dns: valData?.dns,
                username: valData?.gusername,
                password: valData?.password,
                port: valData?.port,
            }
            let { user, token, success, message, data } = await Request.addGateway(valData)
            setLoader(false)
            var dataId = data?.id;

            if (success) {
                setLoader(true)
                valData = {
                    // ...valData,
                    phonenumber: phonenumber,
                    country: country,
                    tollfree: tollfree,
                    gateway: dataId,
                }
                let { user, token, success, message, data } = await Request.addDID(valData)
                setLoader(false)
                if (success) {
                    setValue()
                    notification.success({
                        message: message
                    })
                }
                else {
                    notification.error({
                        message: `${message}` || "Failed"
                    })
                }
                form.resetFields()
                apiFunction()
                setIsModalVisible(false);
            } else {
                notification.error({
                    message: `${message}` || "Failed",
                })
            }
        }
    }


    return (<div >
        <div className="flex jcfe aic">
            <Button type="primary" className="buttonPrimaryStyle" loading={loading} onClick={() => {
                showModal()
                setValue('ip')
            }}>Add DID</Button>

        </div>
        <div className="marginTop" style={{ width: '100%' }}>
            <Table
                pagination={false}
                dataSource={dataSource}
                columns={columns}
                loading={loading}
                rowKey="index"
                bordered
                size="small"
                scroll={{ x: 'max-content' }}

            />
        </div>
        <div style={{ justifyItems: 'center' }} >
            <Modal title={(editId) ? "Edit DID" : "Add DID"} destroyOnClose={true} width={700} height={600} footer={null} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <Form
                    {...layout}
                    form={form}
                    layout='vertical'
                    onFinish={onFinish}

                >
                    <FormItem
                        label={'Country'}
                        name="country"
                        initialValues=""
                        rules={requiredRule}
                        inputType={"SELECT"}
                        options={_.map(countries, val => { return ({ label: val?.countryname, value: val?.countrycode }) })}
                    />
                    <FormItem
                        label={'DID Number'}
                        name="phonenumber"
                        rules={requiredRule}
                    />
                    <FormItem
                        label={'Gateway IP'}
                        name="ip"
                        rules={[
                            {
                                required: true,
                                message: 'please input field',
                            }, {
                                pattern: /^[A-Za-z0-9.]+$/,
                                message: 'Please enter a valid format',
                            }
                        ]}
                    />
                    <FormItem
                        label={'Gateway DNS'}
                        name="dns"
                        rules={requiredRule}
                    />
                    <FormItem
                        label={'Port'}
                        name="port"
                        rules={requiredRule}
                    />
                    <FormItem
                        label={'Authentication Method'}
                        name="method"
                        // defaultValue={'ip'}
                        inputType="RADIO"
                        // value={value1}
                        defaultValue={editId?.gateway?.method == 'ip' ? 'ip based' : 'user'}
                        value={'ip based'}
                        radioOptions={['IP Based', 'Username/Password']}
                        onChange={(e) => { setValue(e.target.value == 'ip based' ? 'ip' : 'user') }}

                    />
                    {!(value1 == 'ip') &&
                        <><FormItem
                            label={'Gateway Username'}
                            name="gusername"
                            rules={requiredRule}
                        />
                            {<FormItem
                                label={'Gateway Password'}
                                name="password"
                                inputType="PASSWORD"
                            />}
                        </>}
                    <FormItem
                        label={'Tollfree'}
                        name="tollfree"
                        inputType={'RADIO'}
                        radioOptions={['true', 'false']}
                        rules={requiredRule}
                    />
                    <div className="marginTop" >
                        <FormItem
                            inputType="BUTTON"
                            tailLayout={tailLayout}
                            type="primary"
                            buttonStyle={{ width: 100, marginTop: 20, }}
                            loading={loading}
                            title="SUBMIT"
                            htmlType="submit" />
                    </div>
                </Form>
            </Modal>
        </div>
    </div>
    );
}

export default Home
