import React, { useState, useEffect, lazy } from 'react';
import { Select, Table, Button, notification, Input } from 'antd';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { MenuOutlined, DeleteOutlined, FileTwoTone } from '@ant-design/icons';
import { arrayMoveImmutable } from 'array-move';
import './styles.less'
import Request from '../../request'
import _ from 'lodash'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux';
import UpgradePlan from './upgradePlan'
import { confirmBox } from '../../utils'
const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

const columns = [
  {
    title: 'Sort',
    dataIndex: 'sort',
    width: 100,
    fixed: 'left',
    className: 'drag-visible',
    render: () => <DragHandle />,
  },
  {
    title: 'Name',
    dataIndex: 'firstname',
    key: 'firstname',
    width: 150,
    render: (val, r) => {
      return <>{val} {r?.lastname}</>
    }
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    width: 250,
    search: true

  },
  {
    title: 'Allocated Phone',
    dataIndex: 'allocatedphone',
    key: 'allocatedphone',
    render: (val, r) => {
      return val?.phonenumber
    }
  },
  {
    title: 'Phone',
    dataIndex: 'mobile',
    key: 'mobile',
  },
];
const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

const Home = () => {
  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser
  }))
  const dispatch = useDispatch()
  const [dataSource, setState] = useState([])
  const [allNumbers, setNumbers] = useState([])
  const [allDept, setDept] = useState([])
  const [callers, setCallers] = useState([])
  const [filters, setFilters] = useState({})
  const [loading, setLoader] = useState(false)

  const apiFunction = async () => {
    setLoader(true)
    let { user, token, success, message, data } = await Request.getAllNumbers()
    setLoader(false)
    if (success) {
      setNumbers(() => data)
    } else {
      setNumbers(() => [])

      // notification.error({
      //   message: message || "Failed"
      // })
    }
  }
  const apiFunction1 = async () => {
    setLoader(true)
    let { user, token, success, message, data } = await Request.allDept()
    setLoader(false)
    if (success) {
      setDept(() => data)
    } else {
      setDept(() => [])

      // notification.error({
      //   message: message || "Failed"
      // })
    }
  }
  const apiFunction2 = async () => {
    setLoader(true)
    let { user, token, success, message, data } = await Request.allCaller()
    setLoader(false)
    if (success) {
      setCallers(() => data)
    } else {
      setCallers(() => [])

      // notification.error({
      //   message: message || "Failed"
      // })
    }
  }
  const onAddFxn = (fields) => {
    confirmBox(dispatch, {
      message: 'Are you sure you want to Add',
      onOk: async () => {
        onAdd()
      }
    })
  }
  useEffect(() => {
    apiFunction()
    apiFunction1()
    apiFunction2()
  }, [])
  const onAdd = async () => {
    if (!(filters?.number) && !(filters?.dept)) {
      notification.warning({
        message: 'Required Fields',
        description: 'Choose number or dept'
      })
      return
    }
    if (!(dataSource.length)) {
      notification.warning({
        message: 'Required Fields',
        description: 'Choose Callers'
      })
      return
    }
    let itemArr = _.map(dataSource, val => ({ caller: val?.id }))
    let obj = {
      items: itemArr
    }
    if (filters?.dept) {
      obj = {
        ...obj,
        department: filters?.dept
      }
    } else {
      obj = {
        ...obj,
        number: filters?.number
      }
    }
    setLoader(true)
    let { user, token, success, message, data } = await Request.addQueue({ ...obj })
    setLoader(false)
    if (success) {
      notification.success({
        message: message || "added"
      })
      setState([])
      setFilters({
        dept: undefined,
        number: undefined,
        caller: undefined
      })
    } else {
      notification.error({
        message: message || "Failed",
        description: JSON.stringify(data)?.replace('[', '')?.replace('{', '')?.replace(']', '')?.replace('}', '')

      })
    }
  }
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
      // console.log('Sorted items: ', newData);
      setState(newData);
    }
  };

  const DraggableContainer = props => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(x => x.index === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };
  const setCallersFxn = (val) => {
    let arr = []
    let i = 0
    _.forEach(val, search => {
      let obj = _.find(callers, call => call?.id == search)
      arr.push({
        ...obj?.auth,
        ...obj,
        index: i++
      })
    })
    setState(arr)
    setFilters((prev) => ({ ...prev, caller: val }))
  }
  if (!currentUser?.balance?.callqueues) {
    return <UpgradePlan />
  }

  return (<div style={{ width: '100%' }}>

    <div className="flex marginRight" >

      <div style={{ fontSize: 16, marginRight: '4rem', }}>Select  Number :</div>
      <div className="marginRight">
        {/* <Select
          showSearch
          allowClear={true}
          onChange={(val) => setFilters((prev) => ({ ...prev, dept: val, number: undefined }))}
          style={{ width: 200, border: '1px solid #e1e3e8', borderRadius: '4px' }}
          placeholder="Select Dept"
          value={filters?.dept}
          options={_.map(allDept, val => { return ({ label: val?.deptname, value: val?.id }) })}

        /> */}
      </div>
      {/* <div className="marginRight">{'/'}</div> */}
      <div className="marginRight">
        <Select
          showSearch
          allowClear={true}
          key={filters?.number}
          onChange={(val) => setFilters((prev) => ({ ...prev, number: val, dept: undefined }))}
          style={{ width: 200, border: '1px solid #e1e3e8', borderRadius: '4px' }}
          placeholder="Select Number"
          value={filters?.number}
          options={_.map(allNumbers, val => { return ({ label: val?.phonenumber, value: val?.id }) })}

        />
      </div>
    </div>
    <div>
      <div className="flex">

        <div style={{ fontSize: 16, marginRight: '.5rem' }}>Select Callers for Queue :</div>
        <Select
          showSearch
          allowClear={true}
          mode="multiple"
          key={filters?.caller}
          onChange={(val) => setCallersFxn(val)}
          style={{ width: 250, border: '1px solid #e1e3e8', borderRadius: '4px' }}
          placeholder="Select Callers"
          value={filters?.caller}
          options={_.map(callers, val => { return ({ label: val?.auth?.firstname, value: val?.id }) })}

        />
      </div>
    </div>
    <div className="marginTop">

      <Table

        style={{ width: '100%' }}
        pagination={false}
        dataSource={dataSource}
        columns={columns}
        rowKey="index"
        scroll={{ x: 'max-content' }}
        bordered
        title={() => <div style={{ fontSize: 16, marginRight: '1rem', fontWeight: "bold" }}>Callers in the Queue</div>}
        size="small"
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      />
    </div>
    <div className="flex jcc aic marginTopLarge">
      <Button type="primary" className="buttonPrimaryStyle" loading={loading} onClick={() => onAddFxn()}>Add Queue</Button>

    </div>
  </div>
  );
}

export default Home
