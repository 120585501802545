import { lazy, useEffect, useState } from 'react';
import { List, Collapse, Switch, Radio, Image, Modal, InputNumber, notification, Space, Spin, Input, Button, Form, Card } from 'antd';
import './styles.less'
import Request from '../../request'
import { useDispatch, useSelector } from 'react-redux';
import UpgradePlan from './upgradePlan'

const LIST = [{}, {}, {}, {}, {}, {}]
const ProductList = (props) => {
    const { searchParams, productCategoryId, category, onCategoryChange, refreshCategory } = props
    const dispatch = useDispatch()
    const [limit, setLimit] = useState({})
    const [dataSource, setDataSource] = useState({})
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loader, setLoader] = useState(false)
    const [state, setState] = useState({
        data: [],
        loading: false,
        showShare: false,
        showAll: true,
        productId: 0,
        productCategory: 0
    })

    const apiFunction = async () => {
        setLoader(true)
        let { data, success } = await Request.getCostLimit()
        setLoader(false)
        if (success) {
            setDataSource(() => data[0])
        } else {
            setDataSource(() => { })
        }
    }

    useEffect(() => {
        apiFunction()
    }, [])

    // const addCostLimit = async () => {
    //     if (!(limit?.pricelimit)) {
    //         notification.warning({
    //             message: 'Required Fields',
    //             description: 'Choose number'
    //         })
    //         return
    //     }
    //     setLoader(true)
    //     let { user, token, success, message, data } = await Request.addCostLimit({ ...limit })
    //     setLoader(false)
    //     if (success) {
    //         notification.success({
    //             message: message || "added"
    //         })
    //         setLimit({ number: undefined })
    //         setIsModalVisible(false)
    //         apiFunction()
    //     } else {
    //         notification.error({
    //             message: message || "Failed",
    //             description: JSON.stringify(data)?.replace('[', '')?.replace('{', '')?.replace(']', '')?.replace('}', '')

    //         })
    //     }
    // }

    const updateCostLimit = async () => {
        if (!(limit?.pricelimit)) {
            notification.warning({
                message: 'Required Fields',
                description: 'Choose number'
            })
            return
        }
        setLoader(true)
        let { user, token, success, message, data } = await Request.updateCostLimit({ ...limit }, dataSource?.id)
        setLoader(false)
        if (success) {
            notification.success({
                message: message || "added"
            })
            setLimit({ number: undefined })
            setIsModalVisible(false)
            apiFunction()
        } else {
            notification.error({
                message: message || "Failed",
                description: JSON.stringify(data)?.replace('[', '')?.replace('{', '')?.replace(']', '')?.replace('}', '')

            })
        }
    }

    const removeCostLimit = async () => {
        setLoader(true)
        let { user, token, success, message, data } = await Request.removeCostLimit({ pricelimit: null }, dataSource?.id)
        setLoader(false)
        if (success) {
            notification.success({
                message: message || "Removed Successfully"
            })
            setLimit({ number: undefined })
            apiFunction()
        } else {
            notification.error({
                message: message || "Failed",
                description: JSON.stringify(data)?.replace('[', '')?.replace('{', '')?.replace(']', '')?.replace('}', '')

            })
        }
    }

    const { currentUser } = useSelector((state) => ({
        currentUser: state.global.currentUser
    }))
    if (!currentUser?.balance?.callcostlimit) {
        return <UpgradePlan />

    }

    const width = window.innerWidth;

    return (
        <div className="settingListWrapper" style={{ width: '100%' }}>
            <div className="" style={{ overflow: 'auto' }}>
                <div className="titleStyle">Price Limit</div>
                <List
                    className='SettingListElem'
                    loading={loader}
                    dataSource={LIST}
                >
                    <List.Item className='settingSdapList' >
                        <List.Item.Meta

                            title={
                                <div className="flex" style={{ width: '100%' }}>
                                    {width > 768 && <div className="Circle marginRightLarge"></div>}
                                    <div>
                                        <div className="InnerStyleList">Outgoing Call Cost Limit </div>
                                        <div className="InnerStyleSubHeading">All calls costing more than the below mentioned per minute call rates will be blocked. Through this, you can now restrict calls to destinations with high call rates.</div>
                                    </div>
                                </div>
                            }
                        />

                    </List.Item>
                    <List.Item className='settingSdapList' >
                        <List.Item.Meta
                            title={<div className="flex PriceLimitBox">
                                <div className="borderPlan marginRight3" style={{ width: "auto" }}>
                                    {dataSource?.pricelimit ? <div className="InnerStyleListNumber"> {dataSource?.pricelimit}</div> :
                                        <div className="InnerStyleSubHeading" style={{ textAlign: "center" }}>0.0</div>}
                                </div>
                                <div className="PriceLimitBox1" style={{ marginRight: "10px" }}>
                                    <Button className="PriceLimitButton" type="primary" style={{ marginRight: '10px' }} loading={loader} onClick={() => setIsModalVisible(true)}>{dataSource?.pricelimit == null ? 'Set Price Limit' : 'Update Price Limit'}</Button>
                                    <Button className="PriceLimitButton1" type="primary" loading={loader} onClick={() => {
                                        removeCostLimit();
                                    }}>Remove Price Limit</Button>
                                </div>
                            </div>
                            }
                        />

                    </List.Item>
                    <Modal title={<b>Set Price Limit </b>} destroyOnClose={true} visible={isModalVisible} footer={null} onOk={() => setIsModalVisible(false)} onCancel={() => setIsModalVisible(false)}>
                        <div className="flex aic jcc">
                            <div style={{ marginRight: 10 }}>Set Price Limit </div>
                            <InputNumber placeholder="Enter Price Limit between 0.01 to 100"
                                value={limit?.pricelimit}
                                onChange={(value) => setLimit(() => ({ pricelimit: value }))}
                                style={{ borderRadius: '4px', width: '20vw', marginRight: '10px' }}
                                min={0}
                                precision={3}
                                defaultValue={dataSource?.pricelimit}
                                max={100} />
                        </div>
                        <div className="flex aic marginTop jcc" style={{ width: 'auto', justifyContent: 'center' }}>
                            <Button type="primary" onClick={() => {
                                updateCostLimit()
                            }}>Proceed</Button>
                        </div>
                    </Modal>
                </List>
            </div>
        </div>
    );

}

export default ProductList;