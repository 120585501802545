import { useState, lazy, useEffect } from 'react';
import { Card, Modal, Drawer, Tooltip, notification, Radio, message, Table, Tabs, Button, Input, Tag, Switch } from 'antd';
import {VerticalAlignBottomOutlined, OrderedListOutlined, AudioOutlined, CustomerServiceOutlined, SearchOutlined, DeleteOutlined, DeleteTwoTone, EditOutlined, InfoOutlined, UndoOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import './styles.less'
import Request from '../../request'
import moment from "moment";
import { confirmBox } from '../../utils';
import FilterTable from '../../components/Table'

const HomeView = (props) => {

  const dispatch = useDispatch()
  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser
  }))

  const [dataSource, setDataSource] = useState([])
  const [dataSource2, setDataSource2] = useState([])
  const [loading, setLoader] = useState(false)
  const [deptId, setDeptId] = useState('')
  const [visible1, setVisible1] = useState(false)

  const apiFunction = async () => {
    setLoader(true)
    let { user, token, success, message, data } = await Request.leadsetData()
    setLoader(false)
    if (success) {
      setDataSource(() => data)
    } else {
      setDataSource([])

      // notification.error({
      //   message: message || "Failed"
      // })
    }
  }
  const apiFunction1 = async (id) => {
    setLoader(true)
    let { data } = await Request.deptLeadset(id)
    setLoader(false)
    if (data) {
      setDataSource2(data)
    } else {
      setDataSource2([])

      // notification.error({
      //   message: message || "Failed"
      // })
    }
  }

  const deleteLeadsetData = (fields) => {
    confirmBox(dispatch, {
      message: 'Are you sure you want to Delete',
      onOk: async () => {
        setLoader(true)
        let { user, token, success, message, data } = await Request.deleteLeadset(fields?.id)
        setLoader(false)
        apiFunction()
        if (success) {
          notification.success({
            message: message || "Failed"
          })
        } else {
          notification.error({
            message: message || "Failed",
            description: JSON.stringify(data)?.replace('[', '')?.replace('{', '')?.replace(']', '')?.replace('}', '')
          })
        }
      }
    })
  }

  const resetCalledData = (fields) => {
    confirmBox(dispatch, {
      message: 'Are you sure you want to reset called data',
      onOk: async () => {
        setLoader(true)
        let { user, token, success, message, data } = await Request.resetCalledData(fields?.id, { called: false })
        setLoader(false)
        apiFunction()
        if (success) {
          notification.success({
            message: message || "Failed"
          })
        } else {
          notification.error({
            message: message || "Failed",
            description: JSON.stringify(data)?.replace('[', '')?.replace('{', '')?.replace(']', '')?.replace('}', '')
          })
        }
      }
    })
  }

  //*original
  const onChange = async (val, id) => {
    setLoader(true)
    let { user, token, success, message, data } = await Request.updateLeadsetData(id, { active: val })
    setLoader(false)
    if (success) {
      notification.success({
        message: message || "success"
      })
      apiFunction()
    } else {
      notification.error({
        message: message || "Failed"
      })
    }
  }

  // const deptFunction=(props)=>{
  //   props.deptname(dept)
  // }

  const widthMobile = window.innerWidth;

  const downloadLeads = async (record) => {
    if(record.id){
      setLoader(true);
      const data = await Request.downloadLeadset(record.id);
      setLoader(false);
    }
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left'

    },
    {
      title: 'No. of Leads',
      dataIndex: 'leadsetdatacount',
      key: 'leadsetdatacount',

    },
    {
      title: 'Total Calls Count',
      dataIndex: 'totalcallcount',
      key: 'totalcallcount',

    },
    {
      title: 'Department Name',
      dataIndex: 'deptname',
      key: 'deptname',

    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      render: (val, record) => {
        return <Switch checkedChildren="On" unCheckedChildren="Off" checked={val} onChange={(e) => onChange(e, record?.id)} />
      }


    },
    {
      title: 'Uploaded At',
      dataIndex: 'createdon',
      key: 'createdon',
      render: (val, r) => {

        return <>{moment.utc(val).format('DD-MM-YYYY')} &nbsp;&nbsp; {moment.utc(val).format('hh:mm:ss')}</>
      }

    },

    {
      title: 'Last Call Date',
      dataIndex: 'modifiedon',
      key: 'modifiedon',
      render: (val, r) => {
        return <>{moment.utc(val).format('DD-MM-YYYY')}  &nbsp;&nbsp; {moment.utc(val).format('hh:mm:ss')}</>
      }

    },

    {
      title: 'Action',
      dataIndex: 'Action',
      key: 'Action',
      width: '150px',
      render: (val, record) => {
        return <div className="flex" style={{ justifyContent: 'space-between' }}>
          <Tooltip title="Delete LeadsetData">
            <DeleteOutlined style={{ color: "red", fontSize: 20 }} onClick={() => deleteLeadsetData(record)} />
          </Tooltip>
          <Tooltip title="Edit LeadsetData">
            <EditOutlined style={{ color: "primary", fontSize: 20 }}
            // onClick={() => editDept(record)}
            />
          </Tooltip>
          <Tooltip title="Reset Called Data">
            <UndoOutlined style={{ color: "primary", fontSize: 20 }} onClick={() => resetCalledData(record)} />
          </Tooltip>
          <Tooltip title="Download Leadset">
            <VerticalAlignBottomOutlined style={{ color: "primary", fontSize: 20 }} onClick={() => downloadLeads(record)} />
          </Tooltip>
          {/* <div>
            <Tooltip title=" LeadsetData Information">
              <InfoOutlined style={{ color: "primary", fontSize: 20 }} onClick={() => { apiFunction1(record.id); setVisible1(true) }} />
            </Tooltip>
          </div> */}
        </div>
      }
    },
  ];

  const column2 = [

    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (val, record) => {
        return record?.extra_detail?.name
      }

    },
    {
      title: 'Address',
      dataIndex: 'addres',
      key: 'total',
      render: (val, record) => {
        return record?.extra_detail?.addres
      }

    },
    {
      title: 'Call Count',
      dataIndex: 'callcount',
      key: 'callcount',
    },
    {
      title: 'Called',
      dataIndex: 'called',
      key: 'called',
      render: (val, record) => {
        return val.toString()
      }
    },
    {
      title: 'Phone Number',
      dataIndex: 'phonenumber',
      key: 'phonenumber',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    }
  ];

  useEffect(() => {
    apiFunction()
    //  deptFunction()
  }, [])
  return <div>
    <div className="marginTop" style={{ width: '100%' }}>
      <FilterTable bordered dataSource={dataSource} size="large" columns={columns} loading={loading} pagination="true" scroll={{ x: 'max-content' }} />

    </div>
    <Drawer
      title="Leadset Data"
      placement="right"
      closable={true}
      className='drawerCross'
      width={widthMobile < 768 ? 400 : 700}
      onClose={() => setVisible1(false)}
      visible={visible1}
    >
      <FilterTable bordered dataSource={dataSource2} size="large" columns={column2} loading={loading} scroll={{ x: 'max-content' }} />
    </Drawer>
  </div>
}

export default HomeView;