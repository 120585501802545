import { useState, lazy, useEffect } from 'react';
import { Card, DatePicker, Tooltip, Image, Select, message, Table, Tabs, Button, Input, Tag } from 'antd';
import { useSelector } from 'react-redux';
import './styles.less'
import { AudioOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { InfoCircleOutlined, UserOutlined, SearchOutlined } from '@ant-design/icons';
import Request from '../../../request';
import moment from 'moment'
import _ from 'lodash'
import Modal from 'antd/lib/modal/Modal';
import FilterTable from '../../../components/Table'
import { DateFormat } from '../../../settings'
import UpgradePlan from '../../settings/upgradePlan'
const { Search } = Input;
const { RangePicker } = DatePicker;

const HomeView = (props) => {

  const [loader, setLoader] = useState(false)
  const [dataSource, setDataSource] = useState([])
  const [filters, setFilters] = useState({})
  const [callers, setCaller] = useState([])


  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser
  }))
  const apiFunction = async (filters) => {
    setLoader(true)
    let { user, token, success, message, data } = await Request.contacts(filters)
    setLoader(false)
    if (success && data.length) {
      setDataSource(data)
    } else {
      setDataSource([])
    }
  }

  const getCaller = async () => {
    let { user, token, success, message, data } = await Request.allCaller()
    if (success && data.length) {
      setCaller(data)
    } else {
      setCaller([])
    }
  }
  useEffect(() => {
    apiFunction()
    getCaller()
  }, [])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 150,
      fixed: 'left',
      search: true
    },
    {
      title: 'Phone Number',
      dataIndex: 'mobile',
      key: 'mobile',
      width: 150,
      search: true
    },
    // {
    //   title: 'Phone',
    //   dataIndex: 'phone',
    //   key: 'phone',
    //   width: 140,

    // },
    // {
    //   title: 'Calltype',
    //   dataIndex: 'calltype',
    //   key: 'calltype',
    //   render: (val, r) => {
    //     return <Tag color={val == 'Outbound' ? 'blue' : "green"}>{val}</Tag>
    //   }
    // },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Added By',
      dataIndex: 'callername',
      key: 'callername',
    },
    {
      title: 'Added On',
      dataIndex: 'createdon',
      key: 'createdon',
      render: (val, r) => {
        return <>{moment.utc(val).format(DateFormat)}</>
      }
    },

  ];
  if (!currentUser?.balance?.departments) {
    return <UpgradePlan />
  }
  return <div>
    <div className="flex marginRight">
      <div className="marginRight">
        <Select
          showSearch
          allowClear={true}
          onChange={(val) => setFilters((prev) => ({ ...prev, caller: val }))}
          style={{ width: 200, border: '1px solid #e1e3e8', borderRadius: '4px' }}
          placeholder="Select Callers"
          value={filters?.caller}
          options={_.map(callers, val => { return ({ label: val?.auth?.firstname, value: val?.id }) })}

        />
      </div>
      <Button type="primary" className="buttonPrimaryStyle" loading={loader} onClick={() => apiFunction(filters)}>
        Search
      </Button>
    </div>
    <div className="marginTop" style={{ width: '100%' }} >
      <FilterTable bordered dataSource={dataSource} columns={columns} loading={loader} scroll={{ x: 'max-content' }} />

    </div>
  </div>
}

export default HomeView;