import JsSIP from 'jssip';

export const makeCall = async (UA, destination, dispatch) => {
  if (UA) {
    var options = {
      mediaConstraints: { audio: true, video: false },
      // pcConfig: {
      //   'iceServers': [
      //     { "url": "stun:stun.l.google.com:19302" },
      //     { "url": "stun:stun.counterpath.net:3478" },
      //     { "url": "stun:numb.viagenie.ca:3478" }
      //   ]
      // },
      // mediaStream: localStream,
      // iceTransportPolicy: 'all',
      // rtcpMuxPolicy: 'negotiate',
      rtcOfferConstraints: { 'offerToReceiveAudio': true },
      mandatory: [{
        OfferToReceiveAudio: true,
        OfferToReceiveVideo: true
      },
      { 'DtlsSrtpKeyAgreement': true }]
    };

    UA?.call(destination, options);
    dispatch({
      type: 'SET_NUMBER',
      payload: destination
    })
  }
}

export const hangupCall = async (UA, session, dispatch) => {
  if (session) {
    session.terminate();
    dispatch({ type: 'SET_NUMBER', payload: null })
    dispatch({ type: 'SET_CALL', payload: null })
    dispatch({ type: 'IS_CONFIRMED', payload: null })
    dispatch({ type: 'IN_PROGRESS', payload: null })
    dispatch({ type: 'INCOMING', payload: null })
    dispatch({ type: 'INCOMING_CONFIRMED', payload: null })
    console.log("call terminated successfully")
  }
}


export const CreateAccount = (user, data, success, dispatch) => {
  // console.log(user, "$#$$$$$$$$$")
  let endpointNumber = user?.customer_barging[0]?.custextensionregid
  let endpointPassword = user?.customer_barging[0]?.custextenpassword
  let domainName = user?.cutomer_server[0]?.fqdn
  // let endpointNumber = '905'
  // let endpointPassword = 'a89c237c219c4162c'
  // let domainName = 'td.dialmantra.biz'

  // let serverPort = user?.server_port?.serverport
  let serverPort = 8089

  var socket = new JsSIP.WebSocketInterface(`wss://${domainName}:${serverPort}/ws`);
  socket.via_transport = "tcp";
  var configuration = {
    uri: `sip:${endpointNumber}@${domainName}`,
    authorization_user: endpointNumber,
    port: serverPort,
    register: true,
    username: `${endpointNumber}@${domainName}`,
    name: endpointNumber,
    id: endpointNumber,
    registrar_server: `${domainName}:${serverPort}`,
    session_timers: true,
    password: endpointPassword,
    sockets: [socket],
    display_name: endpointNumber,
    'session_timers': true,
    'use_preloaded_route': false,
    debug: true
  };

  JsSIP.debug.enable('JsSIP:*');
  var UA = new JsSIP.UA(configuration);

  const remoteAudio = new Audio()
  const localStream = new MediaStream();


  UA.start();

  UA.on('registered', function (e) {
    if (UA.isRegistered() && UA.isConnected()) {
      // console.log(UA, "THIS IS UA")
      dispatch({ type: 'TERMINATE', payload: null })
      dispatch({ type: 'FAILED', payload: null })
      dispatch({
        type: 'SET_ENDPOINT',
        payload: UA
      })
      dispatch({ type: 'REGISTERED', payload: e })
      dispatch({ type: 'UNREGISTERED', payload: null })
      dispatch({ type: 'REGIS_FAILED', payload: null })
      // console.log("Registered", e);
    } else {
      // console.log("Not Registered", e)
    }
  });

  UA.on('unregistered', function (e) {
    // console.log("UA has been unregistered periodic registeration fails or UA.unregister()", e);
    dispatch({ type: 'UNREGISTERED', payload: e })
    dispatch({ type: 'SET_ENDPOINT', payload: null })
  });

  UA.on('registrationFailed', function (e) {
    // console.log("register failed", e);
    dispatch({ type: 'REGIS_FAILED', payload: e })
    dispatch({ type: 'SET_ENDPOINT', payload: null })
  });

  // UA.on('registrationExpiring', function (e) {
  //   UA.register()
  // });

  UA.on('newRTCSession', (data) => {
    // console.log('New RTC Session')
    const session = data.session;

    // if (session.direction === 'incoming') {
    //   console.log('stream incoming  -------->');
    //   dispatch({ type: 'TERMINATE', payload: null })
    //   dispatch({ type: 'FAILED', payload: null })
    //   console.log(`session - incoming call from ${session.remote_identity}`);
    //   session.on('progress', (e) => {
    //     console.log('Incoming call', e, session)
    //     dispatch({ type: 'INCOMING', payload: { session } })
    //   })
    //   session.on('accepted', (e) => {
    //     console.log('incoming call accepted', e)
    //   })
    //   session.on('confirmed', (e) => {
    //     console.log('incoming call confirmed', e)
    //     if (session.isEstablished()) {
    //       dispatch({ type: 'SET_CALL', payload: session })
    //       dispatch({ type: 'INCOMING_CONFIRMED', payload: e })
    //     }
    //     else {
    //       dispatch({ type: 'SET_CALL', payload: null })
    //     }
    //   })
    //   session.on('ended', (e) => {
    //     console.log('incoming call ended', e)
    //     dispatch({ type: 'TERMINATE', payload: e })
    //     dispatch({ type: 'SET_CALL', payload: null })
    //     dispatch({ type: 'INCOMING', payload: null })
    //     dispatch({ type: 'INCOMING_CONFIRMED', payload: null })
    //   })
    //   session.on('failed', (e) => {
    //     console.log('incoming call failed', e)
    //     dispatch({ type: 'FAILED', payload: e })
    //     dispatch({ type: 'SET_CALL', payload: null })
    //     dispatch({ type: 'INCOMING', payload: null })
    //     dispatch({ type: 'INCOMING_CONFIRMED', payload: null })

    //   })
    //   session.on('peerconnection', () => {
    //     session.connection.addEventListener("addstream", (e) => {
    //       console.log(' incoming - adding audiostream ', e)
    //       console.log(' incoming - adding audiostream1 ', e.stream)
    //       remoteAudio.srcObject = e.stream;
    //       remoteAudio.play()
    //     })
    //   })

    //   // setTimeout(() => {
    //   //   session.answer(callOptions)
    //   //   console.log('call answered')
    //   // }, 2000);
    // }

    if (session.direction === 'outgoing') {
      // console.log('stream outgoing  -------->');
      // console.log(session, "session")
      // session.on('progress', (e) => {
      //   console.log('call is in progress', e)
      //   dispatch({ type: 'IN_PROGRESS', payload: e })
      // })
      if (session.isInProgress()) {
        dispatch({ type: 'TERMINATE', payload: null })
        dispatch({ type: 'FAILED', payload: null })
        dispatch({ type: 'IN_PROGRESS', payload: 'inProgress' })
        dispatch({ type: 'SET_CALL', payload: session })
      }
      session.on('failed', (e) => {
        // console.log('call failed with cause: ' + e.cause, e)
        dispatch({ type: 'FAILED', payload: e })
        dispatch({ type: 'SET_CALL', payload: null })
        dispatch({ type: 'IN_PROGRESS', payload: null })
        dispatch({ type: 'IS_CONFIRMED', payload: null })
        if (e.cause === JsSIP.C.causes.SIP_FAILURE_CODE) {
          // console.log('called party may not be reachable')
        }
      })
      session.on('confirmed', (e) => {
        // console.log('outgoing call confirmed')
        let localTracks = session.connection.getSenders();
        let streams = session.connection.getRemoteStreams();
        let localStream = session.connection.getLocalStreams()
        let streamslength = session.connection.getRemoteStreams().length;
        // console.log(localTracks, 'tracks', streams, "streams>>>>><<<<<<", streamslength, "streamsLength>>>>>><<<<<<<<", localStream)
        // console.log(session, "sessionbefore.............................")
        if (session.isEstablished()) {
          // console.log(session, "session1.............................")
          dispatch({ type: 'SET_CALL', payload: session })
          dispatch({ type: 'IS_CONFIRMED', payload: e })
        } else {
          dispatch({ type: 'SET_CALL', payload: null })
        }
      })

      session.on('ended', (e) => {
        // console.log('outgoing call ended with cause :' + e.cause, e)
        dispatch({ type: 'TERMINATE', payload: e })
        dispatch({ type: 'SET_CALL', payload: null })
        dispatch({ type: 'IN_PROGRESS', payload: null })
        dispatch({ type: 'INCOMING_CONFIRMED', payload: null })
        dispatch({ type: 'IS_CONFIRMED', payload: null })
      })

      session.on('peerconnection', () => console.log('outgoing - Peer connection'))

      session.connection.onaddstream = (e) => {
        // console.log('outgoing - addStream 1', e);
        remoteAudio.srcObject = session.connection.getRemoteStreams()[0];
        remoteAudio.play();
      }

      session.on('getusermediafailed', function (DOMError) {
        // console.log('Get User Media Failed Call Event :' + DOMError)
      })
    }
    if (session?.isEnded()) {
      dispatch({ type: 'SET_CALL', payload: null })
      dispatch({ type: 'INCOMING_CONFIRMED', payload: null })
      dispatch({ type: 'IN_PROGRESS', payload: null })
    }
  })
}