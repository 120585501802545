import { useState, lazy, useEffect } from 'react';
import { Card, Form, Modal, Tooltip, notification, Radio, message, Table, Tabs, Button, Input, Tag, Switch } from 'antd';
import FormItem from '../../components/FormItem'
import { useDispatch, useSelector } from 'react-redux';
import './styles.less'
import { getPushPathWrapper } from '../../routes';
import { Route, Link } from 'react-router-dom'
import Request from '../../request'
import moment from "moment";
import { confirmBox } from '../../utils';
import FilterTable from '../../components/Table'
import { OrderedListOutlined, AudioOutlined, CustomerServiceOutlined, SearchOutlined, DeleteOutlined, DeleteTwoTone, EditOutlined, PhoneOutlined } from '@ant-design/icons';
import Music from './musiconhold'
import UpgradePlan from '../settings/upgradePlan'

import getDept from './deptLeadset'
import { putData, putData2 } from '../../action';
const HomeView = (props) => {
  const dispatch = useDispatch()
  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser
  }))
  const [dataSource, setDataSource] = useState([])
  const [dataSource1, setDataSource1] = useState([])
  const [loading, setLoader] = useState(false)
  const [deptId, setDeptId] = useState('')
  const [defaultChecked1, setDefaultChecked] = useState('')
  const [form] = Form.useForm()
  const [radioPlan, setRadioPlan] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [RadioType, setRadioType] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)

  const suffix = (
    <AudioOutlined
      style={{
        fontSize: 16,
        color: '#1890ff',
      }}
    />
  );

  useEffect(() => {
    if (RadioType != 'predictive') {
      const mainData = {
        maxdialcount: 1,
        maxdialratio: 1,
        maxdropcalls: 0.1,
      }
      form.setFieldsValue(mainData)
    }
  }, [RadioType])

  const deleteQueue = (fields) => {
    confirmBox(dispatch, {
      message: 'Are you sure you want to Delete Queue',
      onOk: async () => {
        setLoader(true)
        let { user, token, success, message, data } = await Request.deleteQueue({ department: fields?.id })
        setLoader(false)
        if (success) {
          notification.success({
            message: message || "success"
          })
          apiFunction()
        } else {
          notification.error({
            message: message || "Failed"
          })
        }
      }
    })
  }

  const onFinish = async (valData) => {
    const valData1 = {
      ...valData,
      customerId: currentUser?.id
    }
    setLoader(true)
    let { user, token, success, message, data } = await Request.updateAutoDialer(defaultChecked1?.id, { ...valData1 })
    setLoader(false)
    if (success) {
      notification.success({
        message: message || "success"
      })
      form.resetFields()
      apiFunction()
      // apiFunction1()
      setIsModalVisible(false);
    }
    else {
      notification.error({
        message: `${message}` || "Failed",
        description: JSON.stringify(data)?.replace('[', '')?.replace('{', '')?.replace(']', '')?.replace('}', '')
      })
    }
  }

  const setShowModalOfProps = (filter) => {
    setShowModal(filter)

    apiFunction()
  }
  const setShowModalFxn = (record) => {
    setShowModal(true)
    setDeptId(record)
    // request
  }

  const deleteDept = (fields) => {
    confirmBox(dispatch, {
      message: 'Are you sure you want to Delete',
      onOk: async () => {
        setLoader(true)
        let { user, token, success, message, data } = await Request.deleteDept(fields?.id)
        setLoader(false)
        apiFunction()
        if (success) {
          notification.success({
            message: message || "Failed"
          })
        } else {
          notification.error({
            message: message || "Failed",
            description: JSON.stringify(data)?.replace('[', '')?.replace('{', '')?.replace(']', '')?.replace('}', '')
          })
        }
      }
    })
  }

  const onChange = async (val, id) => {
    setLoader(true)
    let { user, token, success, message, data } = await Request.updateDept({ deptid: id, active: val })
    setLoader(false)
    if (success) {
      notification.success({
        message: message || "success"
      })
      apiFunction()
    } else {
      notification.error({
        message: message || "Failed"
      })
    }
  }

  const onChange1 = async (val, id) => {
    setLoader(true)
    let { user, token, success, message, data } = await Request.updateDept({ deptid: id, isdispositions: val })
    setLoader(false)
    if (success) {
      notification.success({
        message: message || "success"
      })
      apiFunction()
    } else {
      notification.error({
        message: message || "Failed"
      })
    }
  }


  const editDept = (record) => {
    dispatch(getPushPathWrapper('editDepartment', record))
  }

  const SendData = (id, userid, deptname) => {
    dispatch(putData(id))

    dispatch(putData2(deptname))
  }

  const columns = [
    {
      title: 'Department Name',
      dataIndex: 'deptname',
      key: 'deptname',
      search: false,
      fixed: 'left',
      render: (i, val) => {
        return <>
          {val.deptname} <span style={{ textTransform: "capitalize", }}>({val?.audodialer?.autodialtype ? val?.audodialer?.autodialtype : 'Manual'})</span>
          {/* <Link to="deptLeadset/" onClick={() => SendData(val.id, val.userid, val.deptname)} >{val.deptname} </Link> */}

        </>
      }
    },
    {
      title: 'Allocated Phone',
      dataIndex: 'allocatedphone',
      key: 'allocatedphone',
      render: (val, r) => {
        return val?.phonenumber
      }
    },
    {
      title: 'Comments',
      dataIndex: 'comments',
      key: 'comments',
    },
    {
      title: 'Disposition',
      dataIndex: 'isdispositions',
      key: 'isdispositions',
      render: (val, record) => {
        return <Switch checkedChildren="On" unCheckedChildren="Off" checked={val} onChange={(e) => onChange1(e, record?.id)} />
      }
    },
    {
      title: 'Default Country',
      dataIndex: 'defaultcountry',
      key: 'defaultcountry',
      render: (val, r) => {
        return val?.countryname
      }
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      render: (val, record) => {
        return <Switch checkedChildren="On" unCheckedChildren="Off" checked={val} onChange={(e) => onChange(e, record?.id)} />
      }
    },
    {
      title: 'Created At',
      dataIndex: 'createdon',
      key: 'createdon',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.createdon.localeCompare(b.createdon),
      render: (val, r) => {
        return (moment.utc(val).format('DD-MM-YYYY'))
      }
    },

    {
      title: 'Actions',
      dataIndex: 'Action',
      key: 'Action',
      render: (val, record) => {
        return <div className="flex" style={{ justifyContent: 'center' }}>
          <div>
            <Tooltip title="Delete deparment">
              <DeleteOutlined style={{ color: "red", fontSize: 20 }} onClick={() => deleteDept(record)} />
            </Tooltip>
          </div>
          <div style={{ marginLeft: 10 }}>
            <Tooltip title="Edit deparment">
              <EditOutlined style={{ color: "primary", fontSize: 20 }} onClick={() => editDept(record)} />
            </Tooltip>
          </div>
          {/* {(record.ivrenabled || record?.musicenabled) ? */}
          <div style={{ marginLeft: 10 }}>
            <Tooltip title="Music & Ivr">
              <CustomerServiceOutlined style={{ color: "black", fontSize: 20 }} onClick={() => setShowModalFxn(record)} />
            </Tooltip></div>
          {/* : null} */}
          {((currentUser?.plan?.powerdialer || currentUser?.plan?.previewdialer || currentUser?.plan?.predictivedialer) && (record?.autodialerstatus == 'enabled')) && <div style={{ marginLeft: 10 }}>
            <Tooltip title="Edit Auto Dialer ">
              <PhoneOutlined style={{ color: "primary", fontSize: 20 }} onClick={() => {
                apiFunction1(record?.id)
                // setIsModalVisible(true)
              }} />
            </Tooltip>
          </div>}
        </div>
      }
    },
  ];
  const apiFunction = async () => {
    setLoader(true)
    let { user, token, success, message, data } = await Request.allDept()
    setLoader(false)
    if (success && data.length) {
      setDataSource(data)
    } else {
      setDataSource([])

      // notification.error({
      //   message: message || "Failed"
      // })
    }
  }
  const apiFunction1 = async (depId) => {
    setLoader(true)
    let { user, token, success, message, data } = await Request.getAutoDialer({ 'departmentId': depId })
    setLoader(false)
    if (success && data.length) {
      if (data[0]?.id) {
        let mainData = data[0]
        setRadioType(mainData?.autodialtype)
        setDefaultChecked(mainData)
        form.setFieldsValue({ ...mainData })
        setIsModalVisible(true)
      }
      else {
        form.resetFields()
      }
    } else {
      setDefaultChecked([])

      // notification.error({
      //   message: message || "Failed"
      // })
    }
  }

  const radioCheck = () => {
    const arr = []

    if (currentUser?.plan?.powerdialer) {
      arr.push('Power')
    }
    if (currentUser?.plan?.previewdialer) {
      arr.push('Preview')
    }
    if (currentUser?.plan?.predictivedialer) {
      arr.push('Predictive')
    }
    setRadioPlan(arr)
  }

  useEffect(() => {
    apiFunction()
    radioCheck()
  }, [])

  const requiredRule = [{ required: true, message: 'Please input field' }]

  if (!currentUser?.balance?.departments) {
    return <UpgradePlan />
  }
  return <div>
    <div className="flex jcsb">
      <div>
        {/* <Input
          placeholder="Search"
          suffix={
            <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
          }
          style={{ borderRadius: '4px' }}
        /> */}
      </div>

      <Button type="primary" className="buttonPrimaryStyle" onClick={() => dispatch(getPushPathWrapper('addDepartment'))}>
        Add department
      </Button>
    </div>
    <div className="marginTop" style={{ width: '100%' }}>
      <FilterTable bordered dataSource={dataSource} size="large" columns={columns} loading={loading} scroll={{ x: 'max-content' }} />
      {showModal ? <Music showModal={showModal} setShowModal={setShowModalOfProps} deptname={deptId?.deptname} id={deptId?.id} /> : null}
    </div>
    <Modal title="Auto Dialer" destroyOnClose width={700} height={400} footer={null} visible={isModalVisible} onOk={() => { setIsModalVisible(false) }} onCancel={() => { setIsModalVisible(false) }}>
      <div className="flex aic jcc autoDialerNumber">
        <Form style={{ width: '100%' }}
          onFinish={onFinish}
          form={form}
          layout='vertical'
        >
          <FormItem
            label={'Caller ID'}
            min={0}
            inputType={'NUMBER'}
            rules={requiredRule}
            name="clallerID"
          />
          <FormItem
            label={'Auto Dial Type'}
            name="autodialtype"
            inputType="RADIO"
            rules={requiredRule}
            onChange={(e) => { setRadioType(e.target.value) }}
            radioOptions={radioPlan}
          />
          <FormItem
            label={'Dial Time Out'}
            inputType={'NUMBER'}
            min={20}
            max={60}
            name="dialtimeout"
          />
          <FormItem
            label={'Max Dial Count'}
            inputType={'NUMBER'}
            controls={true}
            min={1}
            step={1}
            max={RadioType != 'predictive' && 1}
            name="maxdialcount"
          />
          <FormItem
            label={'Max Dial Ratio'}
            inputType={'NUMBER'}
            controls={true}
            min={1}
            step={0.5}
            max={RadioType != 'predictive' ? 1 : 5}
            // max={!currentUser?.plan?.predictivedialer ? 1 : 5}
            name="maxdialratio"
          />
          <FormItem
            label={'Max Drop Calls(%)'}
            inputType={'NUMBER'}
            controls={true}
            min={0.1}
            step={0.1}
            max={RadioType != 'predictive' ? 0.1 : 5}
            // max={5}
            name="maxdropcalls"
          />
          <FormItem
            label={'Answering Machine Detection'}
            inputType={'SWITCH'}
            name="answeringmachnedetection"
            defaultChecked={defaultChecked1?.answeringmachnedetection}
          />
          <FormItem
            label={'Use DNC'}
            inputType={'SWITCH'}
            name="usednc"
            defaultChecked={defaultChecked1?.usednc}
          />
          <div className="" style={{ justifyContent: 'center', display: 'flex' }}>
            <FormItem
              inputType="BUTTON"
              type="primary"
              buttonStyle={{ width: 200 }}
              loading={loading}
              title="SUBMIT"
              htmlType="submit" />
          </div>
        </Form>
      </div>
    </Modal>
  </div>
}

export default HomeView;