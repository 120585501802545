import { useState, lazy, useEffect } from 'react';
import { Card, Modal, notification, Avatar, Upload, Switch, Tabs, Button, Input, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import './styles.less'
import { UploadOutlined, DeleteColumnOutlined, SearchOutlined, DeleteOutlined, DeleteTwoTone, EditOutlined, ContactsOutlined } from '@ant-design/icons';
import { getPushPathWrapper } from '../../routes';
import Request from '../../request'
import moment from "moment";
import { confirmBox } from '../../utils';
import { AudioUploadFormat, MaxAudioSize } from '../../settings';
import UpgradePlan from '../settings/upgradePlan'

const { Meta } = Card;

const { Search } = Input;


const { TabPane } = Tabs;

const HomeView = (props) => {
    const dispatch = useDispatch()
    const { currentUser } = useSelector((state) => ({
        currentUser: state.global.currentUser
    }))
    const [dataSource, setDataSource] = useState({})
    const [loading, setLoader] = useState(false)
    const [formFileData, setData] = useState({})
    const [formLoader, setFormLoader] = useState(false)

    const onFinish = async () => {
        const MAINFORM = new FormData();

        formFileData?.musiconhold && MAINFORM.append('musiconhold', formFileData?.musiconhold ? formFileData?.musiconhold?.originFileObj : null)
        formFileData?.ivrmusic && MAINFORM.append('ivrmusic', formFileData?.ivrmusic ? formFileData?.ivrmusic?.originFileObj : null)

        MAINFORM.append('ivrenabled', dataSource?.ivrenabled ? dataSource?.ivrenabled : false)
        MAINFORM.append('musicenabled', dataSource?.musicenabled ? dataSource?.musicenabled : false)

        setFormLoader(true)
        let { user, success, message, data } = await Request.updateCustomerSettings(MAINFORM)
        if (success) {
            notification.success({
                message: message || "success",
                // description: 'Successfully Registered Please Login To Continue'
            })
            apiFunction()
            setData(() => ({ musiconhold: undefined, ivrmusic: undefined }))
        }
        else {
            notification.error({
                message: message || "Failed",
                description:JSON.stringify(data)?.replace('[','')?.replace('{','')?.replace(']','')?.replace('}','')                

            })
        }
        setFormLoader(false)
    }

    const onChangeFile = (info, key) => {
        const nextState = {};
        switch (info.file.status) {
            case "uploading":
                nextState.selectedFile = info.file;
                nextState.id = [info.file];
                setData((prev) => ({ ...prev, [key]: info.file }))
                break;
            case "done":
                nextState.selectedFile = info.file;
                nextState.id = [info.file];
                setData((prev) => ({ ...prev, [key]: info.file }))
                break;

            default:
                // error or removed
                nextState.selectedFile = null;
                nextState.id = [];
                setData((prev) => ({ ...prev, [key]: null }))
        }
    };
    const apiFunction = async () => {
        setLoader(true)
        let { user, token, success, message, data } = await Request.getCustomerSettings()
        setLoader(false)
        if (success) {
            setDataSource(() => data)
        } else {
            setDataSource(() => { })

            // notification.error({
            //   message: message || "Failed"
            // })
        }
    }
    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };
    const beforeUpload = (file) => {
        const isLt2M = file.size / 1024 / 1024 < MaxAudioSize;
        // console.log(isLt2M, file.size,)
        if (!isLt2M) {
            notification.warning({
                message: `File must smaller than ${MaxAudioSize}MB!`
            })
            return false;
        }
        return true
    };

    useEffect(() => {
        apiFunction()
    }, [])
    if (!currentUser?.balance?.callholdwithmusic) {
        return <UpgradePlan />
    }
    return <div className="marginTop">
        <Card style={{ border: 0 }} loading={loading}>
            <div className="musiconhold">
                <div className="flex aic">
                    <div className="marginRightLarge" style={{ fontWeight: "bold", fontSize: 18 }}>Hold Music</div>
                    <Switch checked={dataSource?.musicenabled}
                        onChange={(musicenabled) => setDataSource((prev) => ({ ...prev, musicenabled: musicenabled }))}
                    />
                </div>
                <div className="flex jcsb aic marginTop ">
                    <audio
                        controls
                        type={dataSource?.musiconhold?.ext}
                        src={"data:audio/wav;base64," + dataSource?.musiconhold?.file}>
                        Your browser does not support the
                        <code>audio</code> element.
                    </audio>
                    <div className="flex ">
                        <div className="marginRightLarge">
                            <Upload name="logo" onChange={(p) => onChangeFile(p, 'musiconhold')}
                                beforeUpload={beforeUpload}
                                fileList={formFileData?.musiconhold ? [formFileData?.musiconhold] : []}
                                customRequest={dummyRequest} accept={AudioUploadFormat}>
                                <Button icon={<UploadOutlined />}>Upload File</Button>
                            </Upload>
                        </div>

                    </div>

                </div>

            </div>
            {(!currentUser?.balance?.customwelcomemessage) ? <></>
                :
                <div className="musiconhold marginTop">
                    <div className="flex aic">
                        <div className="marginRightLarge" style={{ fontWeight: "bold", fontSize: 18 }}>IVR</div>
                        <Switch checked={dataSource?.ivrenabled}
                            onChange={(ivrenabled) => setDataSource((prev) => ({ ...prev, ivrenabled: ivrenabled }))}
                        />
                    </div>
                    <div className="flex jcsb aic marginTop ">
                        <audio
                            controls
                            type={dataSource?.ivrmusic?.ext}
                            src={"data:audio/wav;base64," + dataSource?.ivrmusic?.file}
                        >
                            Your browser does not support the
                            <code>audio</code> element.
                        </audio>
                        <div className="flex ">
                            <div className="marginRightLarge">
                                <Upload name="logo" onChange={(p) => onChangeFile(p, 'ivrmusic')}
                                    beforeUpload={beforeUpload}
                                    fileList={formFileData?.ivrmusic ? [formFileData?.ivrmusic] : []}
                                    customRequest={dummyRequest} accept={AudioUploadFormat}>
                                    <Button icon={<UploadOutlined />}>Upload File</Button>
                                </Upload>
                            </div>

                        </div>

                    </div>

                </div>
            }
        </Card>
        <div className="marginTopLarge flex jcc">
            {<Button type="primary" className="buttonPrimaryStyle"
                loading={formLoader} onClick={() => onFinish()}>
                Update IVR/Music</Button>}

        </div>
    </div>
}

export default HomeView;