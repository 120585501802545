import React, { useState, useEffect, lazy } from 'react';
import { notification, Table, Button, Modal, Switch, Form } from 'antd';
import './styles.less'
import Request from '../../request'
import _ from 'lodash'
import moment from 'moment'
import FormItem from '../../components/FormItem'
import { DeleteOutlined } from '@ant-design/icons';


const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

const Home = () => {

    const [dataSource, setState] = useState([])
    const [filters, setFilters] = useState({})
    const [loading, setLoader] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm()
    const requiredRule = [{ required: true, message: 'Please input field' }]
    const requiredRule1 = [{ required: true, message: 'Please input field', min: 0 }]
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 150,
            fixed: 'left',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: 250,
            search: true
        },
        {
            title: 'Company Name',
            dataIndex: 'companyname',
            key: 'companyname',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Active',
            dataIndex: 'active',
            key: 'active',
            render: (val, record) => {
                return <Switch checkedChildren="On" unCheckedChildren="Off" checked={val} onChange={(e) => onChange(e, record?.id)} />
            }
        },
        {
            title: 'Actions',
            dataIndex: 'Action',
            key: 'Action',
            // width: 180,
            // fixed: 'right',
            render: (val, r) => {
                return <div className="flex" style={{ justifyContent: 'center' }}>
                    <div>
                        <DeleteOutlined style={{ fontSize: 24 }} onClick={() => deleteContacts(r?.id)} />
                    </div>
                </div>
            },
        }
    ];
    const apiFunction = async () => {
        setLoader(true)
        let { user, token, success, message, data } = await Request.getBillingContacts()
        setLoader(false)
        if (success) {
            setState(() => data)
        } else {
            setState(() => [])

            // notification.error({
            //   message: message || "Failed"
            // })
        }
    }
    const showModal = () => {
        setIsModalVisible(true);
    };
    const onChange = async (val, id) => {
        setLoader(true)
        let { user, token, success, message, data } = await Request.updateBillingContacts(id, { active: val })
        setLoader(false)
        if (success) {
            notification.success({
                message: message || "success"
            })
            apiFunction()
        } else {
            notification.error({
                message: message || "Failed"
            })
        }
    }
    const deleteContacts = async (id) => {
        setLoader(true)
        let { user, token, success, message, data } = await Request.deleteBillingContacts(id)
        setLoader(false)
        if (success) {
            notification.success({
                message: message || "success"
            })
            apiFunction()
        } else {
            notification.error({
                message: message || "Failed"
            })
        }
    }
    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    useEffect(() => {
        apiFunction()
    }, [])
    const onFinish = async (valData) => {
        setLoader(true)
        let { user, token, success, message, data } = await Request.addBillingContacts(valData)
        // console.log(success, data, message)
        setLoader(false)
        if (success) {
            notification.success({
                message: message
            })
            form.resetFields()
            handleCancel()
            apiFunction()

        } else {
            notification.error({
                message: message || "Failed",
                description: JSON.stringify(data)?.replace('[', '')?.replace('{', '')?.replace(']', '')?.replace('}', '')

            })
        }
    }

    return (<div className="">
        <div className=" jcsb" style={{ float: 'right', margin: 10 }} >
            <Button type="primary" className="buttonPrimaryStyle" loading={loading} onClick={() => showModal()}>Add Billing Contacts</Button>


        </div>
        <div className="marginTop" style={{ width: '100%' }}>
            <Table
                pagination={false}
                dataSource={dataSource}
                columns={columns}
                loading={loading}
                rowKey="index"
                bordered
                size="small"
                scroll={{ x: 'max-content' }}
            />
        </div>
        <Modal title="Add Billing Contacts" destroyOnClose width={700} height={600} footer={null} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
            <Form
                {...layout}
                form={form}
                layout='vertical'
                onFinish={onFinish}
            >
                <FormItem
                    label={'Name'}
                    name="name"
                    rules={requiredRule}
                />
                <FormItem
                    label={'Company Name'}
                    name="companyname"
                    rules={requiredRule}
                />
                <FormItem
                    label={'Email'}
                    name="email"
                    inputType={'EMAIL'}
                    rules={requiredRule}
                />
                <FormItem
                    label={'Phone'}
                    name="phone"
                    maxLength={11}
                    width={325}
                    rules={requiredRule}
                    inputType={'NUMBER'}
                    controls={false}
                    min={1}
                />
                <FormItem
                    label={'Address'}
                    name="address"
                // rules={requiredRule}
                // inputType={'NUMBER'}
                />
                <FormItem
                    label={'Set As Default'}
                    name="active"
                    inputType={'SWITCH'}
                />


                <div className="marginTop">
                    <FormItem
                        inputType="BUTTON"
                        tailLayout={tailLayout}
                        type="primary"
                        buttonStyle={{ width: 200, marginTop: 20 }}
                        loading={loading}
                        title="SUBMIT"
                        htmlType="submit" />
                </div>
            </Form>

        </Modal>
    </div>
    );
}

export default Home
