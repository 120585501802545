import { useState, lazy, useEffect } from 'react';
import { Card, DatePicker, Tooltip, Image, Select, message, Table, Tabs, Button, Input, Tag, notification } from 'antd';
import { useSelector } from 'react-redux';
import './styles.less'
import { AudioOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { InfoCircleOutlined, UserOutlined, SearchOutlined } from '@ant-design/icons';
import Request from '../../request';
import moment from 'moment'
import _ from 'lodash'
import Modal from 'antd/lib/modal/Modal';
import FilterTable from '../../components/Table'
import { DateFormat } from '../../settings'
import UpgradePlan from '../settings/upgradePlan'
const { Search } = Input;
const { RangePicker } = DatePicker;

const HomeView = (props) => {

    const [loader, setLoader] = useState(false)
    const [dataSource, setDataSource] = useState([])
    const [pageTotal, setPageTotal] = useState();
    const [filters, setFilters] = useState({})
    const [department, setDeptName] = useState([])
    const [callers, setCaller] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [media, setmedia] = useState('')
    const [selectedRowKeys, setSelectedRowKeys] = useState([])


    const { currentUser } = useSelector((state) => ({
        currentUser: state.global.currentUser
    }))
    const apiFunction = async (filters) => {
        setLoader(true)
        let { user, token, success, message, data, total } = await Request.callHistory(filters)
        setLoader(false)
        if (success && data.length) {
            let arr = []
            let key = 0
            _.forEach(data, val => {
                arr.push({
                    ...val,
                    // ...val?.caller?.auth,
                    key: key
                })
                key++
            })
            setDataSource(arr)
            setPageTotal(total)
        } else {
            setDataSource([])
        }
    }
    const getDepartment = async () => {
        let { user, token, success, message, data } = await Request.allDept()
        if (success && data.length) {
            setDeptName(data)
        } else {
            setDeptName([])
        }
    }
    const getCaller = async () => {
        let { user, token, success, message, data } = await Request.allCaller()
        if (success && data.length) {
            setCaller(data)
        } else {
            setCaller([])
        }
    }
    useEffect(() => {
        setFilters({ date: [moment().subtract('days', 7), moment()] })
        // apiFunction({ date: [moment().subtract('days', 7), moment()] })
        getDepartment()
        getCaller()
    }, [])
    const setShowModalFxn = async (id) => {
        setLoader(true)
        let { data, success } = await Request.getFiles({ context: 'recording', id: id })
        if (data?.file && success) {
            setmedia(() => data?.file)
            setShowModal(true)
        }
        setLoader(false)

    }
    const hasSelected = selectedRowKeys.length > 0;
    const columns = [
        {
            title: 'Caller',
            dataIndex: 'firstname',
            key: 'firstname',
            width: 150,
            fixed: 'left',
            render: (val, r) => {
                return <>{r?.caller?.auth?.firstname} {r?.caller?.auth?.lastname}</>
            }
        },
        {
            title: 'Department',
            dataIndex: 'deptname',
            key: 'deptname',
            width: 150,
            search: true
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            width: 140,

        },
        {
            title: 'Calltype',
            dataIndex: 'calltype',
            key: 'calltype',
            render: (val, r) => {
                return <Tag color={val?.toLowerCase() == 'outbound' ? 'blue' : "green"}>{_.startCase(val)}</Tag>
            }
        },
        {
            title: 'Date',
            dataIndex: 'createdon',
            key: 'createdon',
            render: (val, r) => {
                return <>{moment.utc(val).format(DateFormat)}</>
            }
        },

        {
            title: 'Time',
            dataIndex: 'createdon',
            key: 'createdon',
            render: (val, r) => {
                return <>{moment.utc(val).format('LTS')}</>
            }
        },

        // {
        //   title: 'Bill Time',
        //   dataIndex: 'billtime',
        //   key: 'billtime',
        // },
        {
            title: 'Call Duration',
            dataIndex: 'callduration',
            key: 'callduration',
        },
        // {
        //   title: 'Active',
        //   dataIndex: 'active',
        //   key: 'active',
        //   render: (val, r) => {
        //     return <Tag style={{borderRadius:30,width:60,display:"flex",justifyContent:"center"}}  color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
        //   }
        // },
        // {
        //   title: 'Created At',
        //   dataIndex: 'createdon',
        //   key: 'createdon',
        //   width: 180,
        //   defaultSortOrder: 'descend',
        //   sorter: (a, b) => a.createdon - b.createdon,
        //   render: (val, r) => {
        //     return <Tag>{moment.utc(val).format('DD-MM-YYYY')}</Tag>
        //   }
        // },
        {
            title: 'Actions',
            dataIndex: 'Action',
            key: 'Action',
            // width: 180,
            // fixed: 'right',
            render: (val, r) => {
                return <>
                    <div className="flex" style={{ justifyContent: 'center' }}>
                        <div>
                            {r.recording ? <PlayCircleOutlined style={{ fontSize: 24 }} onClick={() => setShowModalFxn(r?.id)} /> : <div></div>}
                        </div>
                    </div>
                </>
            }
        },


    ];


    const start = async () => {
        if (selectedRowKeys?.length > 20) {
            notification.warning({
                message: 'Maximum 20 recording can be downloaded'
            })
            return
        }
        let arr = []
        _.forEach(selectedRowKeys, doc => {
            arr.push(dataSource[doc]?.id)
        })
        setLoader(true)
        let { user, token, success, message, data } = await Request.downloadRecording({ id: arr })
        setTimeout(() => {
            setSelectedRowKeys([])
            setLoader(false)

        }, 1000);
    };
    const onSelectChange = selectedRowKeys => {
        // console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(() => (selectedRowKeys));
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    if (!currentUser?.plan?.callrecording) {
        return <UpgradePlan />
    }


    return <div>
        <>
            <h2>Recordings</h2>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: '5px' }}>
                <RangePicker onChange={(v) => setFilters((prev) => ({ ...prev, date: v }))} value={filters?.date} />
                <Select
                    showSearch
                    allowClear={true}
                    onChange={(val) => setFilters((prev) => ({ ...prev, dept: val }))}
                    style={{ width: 200, border: '1px solid #e1e3e8', borderRadius: '4px' }}
                    placeholder="Select Dept"
                    value={filters?.dept}
                    options={_.map(department, val => { return ({ label: val?.deptname, value: val?.id }) })}

                />
                <Select
                    showSearch
                    allowClear={true}
                    onChange={(val) => setFilters((prev) => ({ ...prev, caller: val }))}
                    style={{ width: 200, border: '1px solid #e1e3e8', borderRadius: '4px' }}
                    placeholder="Select Callers"
                    value={filters?.caller}
                    options={_.map(callers, val => { return ({ label: val?.auth?.firstname, value: val?.id }) })}

                />
                <div>
                    <Input placeholder="Called Phone" maxLength={14} allowClear value={filters?.phone} onChange={(e) => setFilters((prev) => ({ ...prev, phone: e.target.value }))} />
                </div>
                <Button type="primary" className="buttonPrimaryStyle" loading={loader} onClick={() => apiFunction(filters)}>
                    Search
                </Button>
            </div>
            {dataSource?.length ? <div className="marginTop">
                <div style={{ marginBottom: 16 }}>
                    <Button type="primary" onClick={start} disabled={!hasSelected}
                        // loading={loading}
                        loading={loader}
                    >
                        Download Recording
                    </Button>
                    <span style={{ marginLeft: 8 }}>
                        {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
                    </span>
                </div>
                <FilterTable pagination={{
                    onChange: page => {
                        apiFunction({ ...filters, page: page, })
                    },
                    pageSize: 20,
                    total: pageTotal,
                    hideOnSinglePage: true,
                    showSizeChanger: false
                }}
                    bordered rowSelection={rowSelection} size="small" dataSource={dataSource} columns={columns} loading={loader} scroll={{ x: 'max-content' }} />

            </div>
                : null}
            <Modal visible={showModal}
                destroyOnClose={true}
                onCancel={() => {
                    setShowModal(false)
                    setmedia('')
                }} footer={null}>
                <div className="recording">Play Recording</div>
                <div className="marginTop">
                    <audio
                        controls
                        type="audio/wav"
                        src={"data:audio/wav;base64," + media}>
                        Your browser does not support the
                        <code>audio</code> element.
                    </audio>
                </div>
            </Modal>

        </>

    </div>
}

export default HomeView;