import { useState, lazy, useEffect } from 'react';
import { Card, Modal, Drawer, Tooltip, notification, Radio, message, Table, Tabs, Button, Input, Tag, Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import './styles.less'
import Request from '../../request'
import moment from "moment";
import { Link, Route } from 'react-router-dom'

import FilterTable from '../../components/Table'

const HomeView = (props) => {

  const dispatch = useDispatch()
  const { currentUser, token } = useSelector((state) => ({
    currentUser: state.global.currentUser,
    token: state.global.token
  }))

  const [dataSource, setDataSource] = useState([])
  const [dataSource1, setDataSource1] = useState([])
  const [dataSource3, setDataSource3] = useState([])
  const [dataSource4, setDataSource4] = useState([])
  const [loading, setLoader] = useState(false)
  const [deptId, setDeptId] = useState('')

  let dataSource2 = [];
  let Id = useSelector((state) => state.putData)
  let dept = useSelector((state) => state.putData2)

  const onChange = async (val, id) => {
    setLoader(true)
    let { user, token, success, message, data } = await Request.updateLeadsetData(id, { active: val })
    setLoader(false)
    if (success) {
      notification.success({
        message: message || "success"
      })
      apiFunction()
    } else {
      notification.error({
        message: message || "Failed"
      })
    }
  }

  const apiFunction = async () => {
    setLoader(true)
    let { user, token, success, message, data } = await Request.leadsetData()
    setLoader(false)
    if (success) {
      setDataSource(() => data)
    } else {
      setDataSource([])

      // notification.error({
      //   message: message || "Failed"
      // })
    }
  }

  dataSource2 = dataSource.filter((data, i) => {
    return data.departmentId == Id
  })


  let fileCount = dataSource2.filter((data, i) => { return data.name }).length
  let fileData = dataSource2.reduce((total, currentValue) => total = total + currentValue.total, 0)

  const apiFunction1 = async () => {
    setLoader(true)
    let data = await Request.depttCallSummary(Id)
    setLoader(false)
    if (data) {
      setDataSource1(() => data)
    } else {
      setDataSource1([])

      // notification.error({
      //   message: message || "Failed"
      // })
    }
  }


  // const apiFunction2 = async () => {
  //   setLoader(true)
  //   let data = await Request.fileCallSummary(id1)
  //   setLoader(false)
  //   if (data) {
  //     setDataSource3(() => data)
  //     console.log(data, "data1")
  //     console.log(dataSource3, "dataSource2")
  //   } else {
  //     setDataSource3([])

  //     // notification.error({
  //     //   message: message || "Failed"
  //     // })
  //   }
  // }

  // const apiFunction3 = async () => {
  //   setLoader(true)
  //   let { data } = await Request.deptLeadset(id1)
  //   setLoader(false)
  //   if (data) {
  //     setDataSource4(data)
  //     console.log(data, "data4")
  //     console.log({ dataSource4 }, "dataSource4")
  //   } else {
  //     setDataSource4([])

  //     // notification.error({
  //     //   message: message || "Failed"
  //     // })
  //   }
  // }

  let id1;
  let callerName;
  function SendData(id, caller) {
    id1 = id
    callerName = caller
    return <>
      apiFunction2(id1)
      apiFunction3(id1) </>
  }


  useEffect(() => {
    apiFunction()
    apiFunction1(Id)
    //  deptFunction()
  }, [])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (val, r) => {
        return <Link to='#' onClick={() => SendData(r.id, r.name)} >{r.name} </Link>
      }

    },
    {
      title: 'No. of Leads',
      dataIndex: 'total',
      key: 'total',

    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      render: (val, record) => {
        return <Switch checkedChildren="On" unCheckedChildren="Off" checked={val} onChange={(e) => onChange(e, record?.id)} />
      }


    },
    {
      title: 'Uploaded At',
      dataIndex: 'uploadDate',
      key: 'uploadDate',
      render: (val, r) => {
        return <>{moment.utc(val).format('DD-MM-YYYY')} &nbsp;&nbsp; {moment.utc(val).format('hh:mm:ss')}</>
      }

    },

    {
      title: 'Last Call Date',
      dataIndex: 'lastCallDate',
      key: 'lastCallDate',
      render: (val, r) => {
        return <>{moment.utc(val).format('DD-MM-YYYY')}  &nbsp;&nbsp; {moment.utc(val).format('hh:mm:ss')}</>
      }

    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
  ];




  return <div>
    <div className="marginTop">
      <FilterTable bordered dataSource={dataSource2} size="large" columns={columns} loading={loading} scroll={{ x: 'max-content' }} />
      <div>
        <h3>Total Leadset Files : {fileCount}</h3>
        <h3>Total  Data in Leadset Files : {fileData}</h3>
        <h3>Total called no. : {dataSource1.isCalled}</h3>
        <h3>Total not called no. : {dataSource1.notCalled}</h3>
      </div>
      <div >
        <h3>{dataSource4.name ? `${dataSource4.name}'s` : "File's"} called no.  : {dataSource3.isCalled}</h3>
        <h3>{dataSource4.name ? `${dataSource4.name}'s` : "File's"} not called no. : {dataSource3.notCalled}</h3>
      </div>

    </div>

  </div>
}

export default HomeView;