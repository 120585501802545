import React, { useState, useEffect, lazy } from 'react';
import { notification, Table, Tag, Modal, Switch, Form } from 'antd';
import './styles.less'
import Request from '../../request'
import _ from 'lodash'
import moment from 'moment'
import FormItem from '../../components/FormItem'
import { DeleteOutlined } from '@ant-design/icons';


const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

const Home = () => {

    const [dataSource, setState] = useState([])
    const [filters, setFilters] = useState({})
    const [loading, setLoader] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm()
    const requiredRule = [{ required: true, message: 'Please input field' }]
    const columns = [
        {
            title: 'Country Code',
            dataIndex: 'countrycode',
            key: 'countrycode',
        },
        {
            title: 'Phone Code',
            dataIndex: 'phonecode',
            key: 'phonecode',
            // search: true
        },
        {
            title: 'Country Name',
            dataIndex: 'countryname',
            key: 'countryname',
            search: true
        },
        {
            title: 'Blocked',
            dataIndex: 'default_blocked',
            key: 'default_blocked',
            render: (val, r) => {
                return <div className="flex jcc">
                    <Tag style={{ borderRadius: 3, width: 80, display: "flex", justifyContent: "center" }}
                        color={'#FA424A'}>{val ? 'True' : 'False'}</Tag>
                </div>
            }
        },

    ];
    const apiFunction = async () => {
        setLoader(true)
        let { user, token, success, message, data } = await Request.getCountries({
            "default_blocked": true
        })
        setLoader(false)
        if (success) {
            setState(() => data)
        } else {
            setState(() => [])

            // notification.error({
            //   message: message || "Failed"
            // })
        }
    }
    useEffect(() => {
        apiFunction()
    }, [])

    return (<div className="marginTop">
        {/* <Table
               dataSource={dataSource}
                columns={columns}
                rowKey="index"
                title={()=><>Blocked Countries</>}
                bordered
                size="small"
            /> */}
        {
            dataSource?.length ? <div className="marginTop">
                <div className="flex" style={{ flexWrap: "wrap", justifyContent: "center" }}>
                    {_.map(dataSource, val => <div style={{
                        margin: 8,
                        padding: '1vh 1vh 1vh 1vh',
                        minWidth: '13em',
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 4, height: '6vh',
                        border: '1px solid #ebebeb'
                    }}>
                        <div style={{ fontSize: 14 }}>{val?.countryname}</div></div>)}
                </div>

            </div> : null
        }
    </div>
    );
}

export default Home
