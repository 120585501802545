import React from 'react'
import { useDispatch, useSelector } from 'react-redux';


function Deptname(){

  
    let dept= useSelector((state)=> state.putData2)  
   

    return  <>Leadsets in {dept}</>
    

    }
    export default Deptname;