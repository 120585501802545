import { useState, useEffect } from 'react';
import { Card, DatePicker, Tooltip, Image, Select, message, Table, Tabs, Button, Input, Tag, notification } from 'antd';
import { useSelector } from 'react-redux';
import './styles.less'
import { AudioOutlined } from '@ant-design/icons';
import FilterTable from '../../components/Table'
import Request from '../../request'
import { DateFormat } from '../../settings'
import _ from 'lodash'

import moment from 'moment'
const { RangePicker } = DatePicker;

const HomeView = (props) => {

  const [state, setState] = useState({
    data: [],
    loading: false,
    hasMore: true,
    orderStatus: 'PENDING',
    showQr: false
  })
  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser
  }))
  const [dataSource, setDataSource] = useState([])
  const [loading, setLoader] = useState(false)
  const [filters, setFilters] = useState({})

  const apiFunction = async (filter) => {
    setLoader(true)
    let { user, token, success, message, data } = await Request.getReportOfAbandonCalls({ date: [filter.date[0].add(1, 'days'), filter.date[1]] })
    setLoader(false)
    if (success && data.length) {
      setDataSource(data)
    } else {
      setDataSource([])

      // notification.error({
      //   message: message || "Failed"
      // })
    }
  }
  useEffect(() => {
    setFilters({ date: [moment().startOf('month'), moment().endOf('month')] })
    apiFunction({ date: [moment().startOf('month'), moment().endOf('month')] })
  }, [])

  const downloadXls = async (filters) => {
    setLoader(true)
    let { user, token, success, message, data } = await Request.downloadXlsForDailyCalls(filters)
    setLoader(false)
  }
  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (val, r) => {
        return (moment.utc(val, 'YYYY-MM-DD').format(DateFormat))
      }
    },
    {
      title: 'Total Calls',
      dataIndex: 'total',
      key: 'total',
    },
    {
      title: 'Incoming Calls',
      dataIndex: 'incoming',
      key: 'incoming',
    },
    {
      title: 'Missed Calls',
      dataIndex: 'missed',
      key: 'missed',
    },
    {
      title: 'Outgoing Calls',
      dataIndex: 'outgoing',
      key: 'outgoing',
    },

  ];


  return <div>
    <h2>Daily Call Summary</h2>
    <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: '5px' }}>
      <div style={{ display: 'flex', gap: '5px' }}>
        <RangePicker onChange={(v) => setFilters((prev) => ({ ...prev, date: v }))} value={filters?.date} showTime format="YYYY/MM/DD" dropdownClassName='myCustomPicker' />
        <Button type="primary" className="buttonPrimaryStyle" loading={loading} onClick={() => apiFunction(filters)}>
          Search
        </Button>
      </div>
      <Button type="secondary" className="buttonPrimaryStyle" loading={loading} onClick={() => downloadXls(filters)}>
        Download .xls/.csv
      </Button>
    </div>
    <div className="marginTopLarge">
      <FilterTable size="small" bordered dataSource={dataSource} columns={columns} loading={loading} scroll={{ x: 'max-content' }} />

    </div>
  </div>
}

export default HomeView;