import React, { useState } from 'react'
import { Button, Typography, Input, Space, notification, Select, Switch, Divider } from 'antd';
import './styles.less'
import { useDispatch } from 'react-redux';
import { Form } from 'antd';
import Request from '../../request';
import { push } from 'connected-react-router';
import { ArrowRightOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import _ from 'lodash'
import { isNormalInteger } from '../../utils';
import { ReactComponent as ImageThumb } from '../../assets/LoginPage.svg'
import { ReactComponent as Arrow } from '../../assets/arrow.svg'
import logo from '../../assets/call-center-signin.png'
import logo1 from '../../assets/dialmantra-logo-icon.png'
import logo2 from '../../assets/signinimg.png'
import Signup from '../signup'
import { CreateAccount } from '../constant/utils.js'
import ForgotPassword from './ForgotPassword'
const { Text } = Typography
const { Option } = Select

const Login = () => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [loader, setLoader] = useState(false)
    const [login, setLoginRun] = useState(true)
    const [getImg, setImg] = useState(true)
    const [forgotPassWord, setForgotPassword] = useState(false)

    const onFinish = async (valData) => {
        const { mobile } = valData
        setLoader(true)
        let { user, token, success, message: loginMessage, data } = await Request.login(valData)
        if (success) {
            dispatch({
                type: 'SET_AUTH_TOKEN',
                payload: data.auth_token
            })
            Request.getProfile().then(({ error, data: profileData, message }) => {
                if (!error && profileData) {
                    // console.log("in login <<<<<<<<<<<<<<<<<<<<<<<<<<<<<")
                    dispatch({
                        type: 'SET_CURRENT_USER',
                        payload: { ...profileData }
                    })
                    Request.checkEndpointStatus().then(({ data, success }) => {
                        if (data.state == 'offline') {
                            sessionStorage.removeItem("reloading");
                            if (profileData?.customer_barging[0]?.custextensionregid && profileData?.customer_barging[0]?.custextenpassword) {
                                CreateAccount(profileData, data, success, dispatch)
                            }
                            dispatch(push('/dashboard'))
                            notification.success({
                                message: loginMessage
                            })
                        } else {
                            // notification.error({
                            //     message: "registering on sip server failed please logout and login again"
                            // })
                        }
                    })
                } else {
                    notification.error({
                        message: message || "Failed",
                        description: JSON.stringify(profileData).replace('[', '').replace('{', '').replace(']', '').replace('}', '')

                    })
                }
            })
        }
        else {
            notification.error({
                message: loginMessage || "Failed"
            })
        }
        setLoader(false)
    }

    const emailRule = [{ type: 'email', message: 'The input is not valid E-mail!' }, { required: true, message: 'Please input your E-mail!' }]
    const requiredRule = [{ min: 6, message: 'The input should be minimum 6 characters' }, { required: true, message: 'Please input your password' }]

    return (
        <div className="LoginParentDiv">
            <div className="LoginSubDiv">
                <div className="LeftSubDiv">
                    <div className="loginPageCustomer">
                        Single Platform for all Your Business Telephony Requirements!
                    </div>
                    <div style={{ fontSize: '1vw', color: 'white', width: '30vw', zIndex: 1, padding: '0px 0 0 35px', height: '30vh' }}>
                        Empower your business and employees globally with our integrated contact center application.
                    </div>
                    <ImageThumb height="65vh" style={{ alignSelf: 'end', width: '33vw', zIndex: 0, bottom: '10vh', position: 'absolute', left: '22vw' }} />
                    {getImg ? <div className='loginFigure' style={{ height: '100vh', overflow: 'hidden', alignSelf: 'end', zIndex: 1 }}><img src={logo} className='callCenterLogo' style={{ zIndex: 1, alignSelf: 'end', width: '28vw', marginRight: '4vw', verticalAlign: 'bottom' }} /></div>
                        : <div className='loginFigure' style={{ height: '100vh', overflow: 'hidden', alignSelf: 'end', zIndex: 1 }}><img src={logo2} className='callCenterLogo' style={{ zIndex: 1, alignSelf: 'end', width: '28vw', marginRight: '4vw', verticalAlign: 'bottom' }} /></div>}
                    <Arrow height="65vh" style={{ alignSelf: 'start', width: '2vw', zIndex: 0, bottom: '0vh', position: 'absolute', left: '12.5vw', height: '30vh' }} />
                    <Arrow height="65vh" style={{ alignSelf: 'start', width: '2vw', zIndex: 0, bottom: '0vh', position: 'absolute', left: '9.5vw', height: '30vh' }} />
                    <Arrow height="65vh" style={{ alignSelf: 'start', width: '2vw', zIndex: 0, bottom: '0vh', position: 'absolute', left: '6.5vw', height: '30vh' }} />
                </div>
            </div>
            <div className='loginWrapper'>
                {!forgotPassWord ? <div className='loginContainer'>
                    <img src={logo1} className='callCenterLogo' style={{ zIndex: 1, width: '5vw' }} />
                    <div className='logoName' style={{ fontSize: '2.1vw', color: '#293275' }}>
                        Dialmantra
                    </div>
                    <div className='logoName1' type={'primary'} style={{ fontSize: '0.6vw', color: '#293275' }}>
                        Powered by Collaberus Technologies
                    </div>
                    <div className="buttonDiv">
                        <div className={login ? 'selectedDiv1' : 'unselectedDiv1'}>
                            <Button ghost={!login} onClick={() => { setLoginRun(true); setImg(true) }} style={{ boxShadow: 'none', border: 'none!important', backgroundColor: 'transparent' }} className={login ? 'selectedDivButton1' : 'unselectedDivButton1'}>sign in</Button>
                        </div>
                        <div className={!login ? 'selectedDiv1' : 'unselectedDiv1'}>
                            <Button ghost={login} onClick={() => { setLoginRun(() => false); setImg(false) }} style={{ boxShadow: 'none', border: 'none!important', backgroundColor: 'transparent' }} className={!login ? 'selectedDivButton1' : 'unselectedDivButton1'}>sign up</Button>
                        </div>
                    </div>
                    <div className="displayFlex">
                        {login ? <div >
                            <Form className='form' form={form} onFinish={onFinish} size='large' layout='vertical'>
                                <div className='inputWidth'>
                                    <Form.Item name='email' style={{ marginBottom: '1.5rem' }} rules={emailRule}>
                                        <Input className="formFieldUnderline" prefix={<UserOutlined className="login-signup-icon" />} type="email" placeholder='Enter Email ID' />
                                    </Form.Item>
                                    <Form.Item name='password' style={{ marginBottom: '3rem' }} rules={requiredRule} hasFeedback>
                                        <Input className="formFieldUnderline" prefix={<LockOutlined className="login-signup-icon" />} maxLength={16} type="password" placeholder='Enter Password' />
                                    </Form.Item>
                                    <a style={{ textAlign: 'end', lineHeight: '16px', color: '#AAB2BC' }} onClick={() => setForgotPassword(true)}>
                                        Forgot Password?
                                    </a>
                                </div>
                                <Form.Item style={{ marginBottom: '1rem' }}>
                                    <Button size='large' loading={loader} htmlType='submit' type='secondary' style={{ backgroundColor: '#EA5B21', color: 'white', width: '20vw', marginTop: '2rem', border: 0, borderRadius: '7px' }}>Sign in</Button>
                                </Form.Item>
                            </Form>
                            <div style={{ width: '60%', position: 'relative', margin: '0 auto' }}>
                                <Divider style={{ border: '1px solid #dbdcdc' }} />
                            </div>
                            <div style={{ color: 'black' }}> Don’t have an account?<Button ghost={login} onClick={() => { setLoginRun(() => false); setImg(false) }} style={{ color: '#EA5B21', boxShadow: 'none', border: 0, fontWeight: 600, paddingLeft: '5px' }}>sign up</Button>
                            </div>
                        </div> : <Signup />}

                        <div className="TermsAndCond">
                            <div className="TermData">By clicking the button above, you agree to our <Space />
                                <span className="termsOfUse">terms of use</span>
                                <Space /> <span>and</span>
                                <span className="termsOfUse"> privacy policy</span>
                            </div>
                        </div>
                    </div>
                </div> : <div className='loginContainer'>
                    <ForgotPassword forgotpass={forgotPassWord} setForgotPassword={setForgotPassword} />
                </div>}
            </div>
        </div>
    );
}

export default Login