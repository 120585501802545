import React, { useState, useEffect } from 'react'
import { Button, Typography, Input, Space, notification, Select, Switch } from 'antd';
import './styles.less'
import { useDispatch } from 'react-redux';
import { Form } from 'antd';
import Request from '../../request';
import { push } from 'connected-react-router';
import { ArrowRightOutlined, UserOutlined } from '@ant-design/icons';
import _ from 'lodash'
import { isNormalInteger } from '../../utils';
import { ReactComponent as ImageThumb } from '../../assets/LoginPage.svg'

const { Text } = Typography
const { Option } = Select

const Login = () => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [loader, setLoader] = useState(false)
    const [countries, setCountries] = useState([])
    const [login, setLoginRun] = useState(true)

    const onFinish = async (valData) => {
        const { mobile } = valData
        if (!isNormalInteger(mobile) || mobile.length !== 10) {
            notification.error({
                message: "Invalid Mobile Number"
            })
            return;
        }
        setLoader(true)
        let obj = {}
        obj = {
            auth: {
                ...valData
            },
            businessname: valData?.businessname
        }
        let { user, token, success, message, data } = await Request.updateProfile(obj)
        setLoader(false)
        if (success) {
            notification.success({
                message: message
            })
        }
        else {
            notification.error({
                message: message || "Failed",
                description: JSON.stringify(data)?.replace('[', '')?.replace('{', '')?.replace(']', '')?.replace('}', '')

            })
        }
        setLoader(false)
    }
    const onChange = (value) => {
        form.setFieldsValue({ countrycode: value });
    };
    const getFunction = async () => {
        let { user, token, success, data } = await Request.getProfile()
        if (success) {
            form.setFieldsValue({ ...data, ...data.auth });
        } else {

        }
    }
    useEffect(() => {
        getFunction()
    }, [])
    const requiredRule = [{ required: true, message: 'Please input field' }]


    return (<div>
        <div className="flexbox" >
            <div className="flex" style={{ marginTop: 10, marginBottom: 20, justifyContent: 'center' }}>
                <UserOutlined style={{ fontSize: '50px', color: '#293275' }} />
                <div style={{ marginLeft: 20, fontSize: '30px', color: '#293275' }}>
                    Update Profile
                </div>
            </div>
            <Form className='profilePage' form={form} onFinish={onFinish} hideRequiredMark={false} layout='vertical'>
                <div className='inputWidthNew'>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ width: "45%" }}>
                            <Form.Item name='firstname' rules={requiredRule}>
                                <Input placeholder='Enter Name' />
                            </Form.Item>
                        </div>
                        <div style={{ width: "50%" }}>

                            <Form.Item name='lastname' rules={requiredRule}>
                                <Input placeholder='Last Name' />
                            </Form.Item>
                        </div>

                    </div>
                    <Form.Item name='businessname' rules={requiredRule}>
                        <Input placeholder='Business Name' />
                    </Form.Item>

                    <Form.Item name='mobile' rules={requiredRule}>
                        <Input maxLength={10} type="mobile" placeholder='Enter Mobile No.' />
                    </Form.Item>
                    {/* <Form.Item name='initialextension' rules={requiredRule}>
                        <Input placeholder='Enter Initial extension ' />
                    </Form.Item> */}
                    <Form.Item name='email' rules={requiredRule}>
                        <Input type="email" disabled placeholder='Enter Email ID' />
                    </Form.Item>
                </div>
                <Form.Item >
                    <Button loading={loader} htmlType='submit' type='primary' style={{ width: 'fit-content', marginTop: '1rem' }}>Update Profile</Button>
                </Form.Item>
            </Form>
        </div>
    </div>
    );
}

export default Login