import { useState, lazy, useEffect } from 'react';
import { Card, Space, Carousel, Image, Radio, message, Modal, Tabs, Table, Tag } from 'antd';
import { useSelector } from 'react-redux';
import './styles.less'
import FilterTable from '../../components/Table'
import CallReport from './agentscallSummary'
import _ from 'lodash'
import Abandon from './dailyCalls'
import Recordings from './recording'
import UpgradePlan from '../settings/upgradePlan'
import Report from './list'
import Request from '../../request'
import async from 'async'
import moment from 'moment'
import { RightCircleFilled } from '@ant-design/icons';
const { TabPane } = Tabs;

const HomeView = (props) => {

  const [dataSource, setDataSource] = useState([])
  const [dataSource1, setDataSource1] = useState([])
  const [loader, setLoader] = useState(false)

  const apiFunction = async () => {
    setLoader(true)
    let { user, token, success, message, data } = await Request.graph()
    setLoader(false)
    if (success) {
      setDataSource(data)
      // setSecondGraphData(data)

    } else {
    }
  }

  const apiFunction1 = async () => {
    setLoader(true)
    let newData = []
    let { user, token, success, message, data } = await Request.callHistory({ recent: true })
    setLoader(false)
    if (success) {
      async.forEach(data, (val, cb) => {
        let obj = {
          ...val?.caller?.auth,
          ...val,
        }
        newData.push(obj)
        cb()
      }, () => {
        setDataSource1(newData)
      })
    } else {
      setDataSource1([])
    }
  }

  // console.log(dataSource1, "dataSource1")

  const callData = [
    {
      total: dataSource.total,
      missed: dataSource.missed,
      outgoing: dataSource.outgoing,
      incoming: dataSource.incoming,

    }
  ]

  // console.log(callData, "callData")
  useEffect(() => {
    apiFunction()
    apiFunction1()

  }, [])

  const columns = [
    {
      title: 'Total Calls',
      dataIndex: 'total',
      key: 'total',

    },
    {
      title: 'Missed Calls',
      dataIndex: 'missed',
      key: 'missed',
    },

    {
      title: 'Outgoing Calls',
      dataIndex: 'outgoing',
      key: 'outgoing',
    },
    {
      title: 'Incoming Calls',
      dataIndex: 'incoming',
      key: 'incoming',
    },

  ]

  // const columns1=
  const columns1 = [
    {
      title: 'Status',
      dataIndex: 'calltype',
      key: 'calltype',
      render: (val, r) => {
        return <div className="flex jcc">
          <Tag style={{ borderRadius: 3, width: 80, display: "flex", justifyContent: "center", fontWeight: 600, backgroundColor: 'transparent', border: 'none', color: `${val?.toLowerCase() == 'outbound' ? '#108ee9' : val?.toLowerCase() == 'inbound' ? "#46C35F" : '#FA424A'}` }}>{_.startCase(val)}</Tag>
          {/* {r?.callstatus&&<Tag style={{ borderRadius: 3, width: 90, display: "flex", justifyContent: "center" }} color={'#46C35F'}>{r?.callstatus}</Tag>} */}
        </div>
      }
    },
    {
      title: 'Caller',
      dataIndex: 'callername',
      key: 'callername',
      // width: 250,/
      // fixed: 'left',
      // search:true,
      render: (val, record) => {
        return <>{record.firstname} {record.lastname}</>
      }
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'active',
    //   key: 'active',
    //   render: (val, r) => {
    //     return <Tag style={{borderRadius:30,width:60,display:"flex",justifyContent:"center"}}  color={val == true ? '#2fd838' : "#f50"}>{val?'Yes':'No'}</Tag>
    //   }
    // },    
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Time',
      dataIndex: 'createdon',
      key: 'createdon',
      render: (val, r) => {
        return (moment.utc(val).fromNow())
      }
    },

  ];
  //   {
  //     title: 'Status',
  //     dataIndex: 'status',
  //     key: 'status',
  //     render:(val,r)=>{
  //       return r.active
  //     }

  //   },
  //   {
  //     title: 'Caller',
  //     dataIndex: 'caller',
  //     key: 'caller',
  //   },

  //   {
  //     title: 'Phone',
  //     dataIndex: 'phone',
  //     key: 'phone',
  //   },
  //   {
  //     title: 'Time',
  //     dataIndex: 'time',
  //     key: 'time',
  //     fixed: 'right',
  //   },

  // ]


  return <div>
    <h2>Today's Call Summary</h2>
    <div className="flex marginRight jcsb " style={{ width: 'auto' }}>
      <div className="marginTop" style={{ width: '-webkit-fill-available' }}>
        <FilterTable bordered dataSource={callData} loading={loader} columns={columns} pagination={false} scroll={{ x: 'max-content' }} />
        <div
          style={{ marginTop: 20, width: 'auto' }}>
          <h2>Recent Calls</h2>
          <FilterTable bordered dataSource={dataSource1} loading={loader} columns={columns1} pagination={false} scroll={{ x: 'max-content' }} />
        </div>
      </div>
    </div>
  </div>
}

export default HomeView;