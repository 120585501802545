import { useState, lazy, useEffect } from 'react';
import { Card, Space, Tooltip, Image, Radio, List, Table, Tabs, Button, Input } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import './styles.less'
import {
  FieldTimeOutlined, AudioOutlined, MenuUnfoldOutlined, DatabaseOutlined, DollarOutlined,
  CreditCardOutlined, MenuFoldOutlined, CloudUploadOutlined, SoundOutlined, BarChartOutlined, LineChartOutlined
} from '@ant-design/icons';
import { InfoCircleOutlined, UserOutlined, SearchOutlined } from '@ant-design/icons';
import { ReactComponent as Information } from '../../assets/information.svg'
import Reports from './report'
import ScheduledCalls from '../scheduledCalls/list'
import History from '../callHistory/history'
import AgentSummary from './agentscallSummary'
import DailyCallSummary from './dailyCalls'
import Recordings from './recording'
import PreviousPayments from './paymentsSummery'
import CallerDisposition from './callerDisposition'
import CallerJobTime from './callerJobTime'
import RealTimeScreen from './realTimeScreen'
import DIDCDR from './didCdr'
// import {putData2} from '../../action'
import { ReactComponent as Activity } from '../../assets/activity.svg'
import { ReactComponent as ReportsSvg } from '../../assets/reports.svg'
import { ReactComponent as Phone } from '../../assets/sscheduled.svg'

// import Department from './list'
// import LeadsetData from './leadsetData'
import { Link, Route } from 'react-router-dom'

import _ from 'lodash'

import { confirmBox } from '../../utils';

const { Search } = Input;

const { TabPane } = Tabs;

const DeptList = (props) => {
  const dispatch = useDispatch()
  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser
  }))

  const LIST = [

    {
      name: 'Call History',
      active: false,
      icon: <Phone style={{ height: 20, width: 20 }} />,
    },

    {
      name: 'Agent Call Summary',
      active: false,
      icon: <LineChartOutlined style={{ fontSize: 20 }} />,
    },
    {
      name: 'Daily Call Summary',
      active: false,
      icon: <BarChartOutlined style={{ fontSize: 20 }} />,
    },
    !currentUser?.balance?.callrecording ? { active: undefined } :
      {
        name: 'Recordings',
        active: false,
        icon: <SoundOutlined style={{ fontSize: 20 }} />,
      },

    {
      name: 'Scheduled Calls',
      active: false,
      icon: <Activity style={{ fontSize: 20, height: 20, width: 20 }} />,
    },
    {
      name: 'Disposition Report',
      active: false,
      icon: <Activity style={{ fontSize: 20, height: 20, width: 20 }} />,
    },
    {
      name: 'Payment History',
      active: false,
      icon: <DollarOutlined style={{ fontSize: 20 }} />,
    },
    {
      name: 'Login Logout Report',
      active: false,
      icon: <FieldTimeOutlined style={{ fontSize: 20 }} />,
    },
    {
      name: 'Real Time Screen',
      active: false,
      icon: <FieldTimeOutlined style={{ fontSize: 20 }} />,
    },
    {
      name: 'DID CDR',
      active: false,
      icon: <Activity style={{ fontSize: 20, height: 20, width: 20 }} />,
    },
  ];

  const [state, setState] = useState({})
  const [visible, setVisible] = useState(false)

  const suffix = (
    <AudioOutlined
      style={{
        fontSize: 16,
        color: '#1890ff',
      }}
    />
  );
  useEffect(() => {
    setState({ dataSource: _.cloneDeep(LIST), selectedKey: _.cloneDeep(LIST)[0] })

  }, [])
  const onChange = (index) => {
    setVisible(true)
    let newData = _.map(state?.dataSource, (e) => {
      if (e.active == undefined) {
        e.active = undefined
      } else {
        e.active = false;
      }
      return e
    })
    if (index >= 0)
      newData[index].active = true
    setState((prev) => ({
      ...prev,
      categories: newData,
      selectedKey: newData[index]

    }))
  }
  const onSearch = value => console.log(value);
  const [open, setOpen] = useState(false);

  // const visit= (deptname) =>{
  // dispatch(putData2(deptname))}


  const defaultPage = () => {
    return visible ? <History /> : <Reports />
  }

  return <div>
    <div className='flex' style={{ alignItems: 'flex-start' }}>
      <div>
        <Button style={{ borderRadius: '6px', marginBottom: '5px', width: '58px' }} onClick={() => { setOpen(!open) }}>{!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}</Button>
        <Card className={`SettingMenu ${open ? null : 'open'}`}>
          <List
            // loading={categoryLoading}
            size="small"
            dataSource={state.dataSource}
            renderItem={(item, i) => (
              <List.Item
                key={item.id}
                title={item.name}
                onClick={() => onChange(i)}
                // onClick={() => visit(i.name) }
                className={`settingListItem ${item?.active && 'settingListItemSelected'}`}>
                <List.Item.Meta avatar={item?.icon} title={item?.name}></List.Item.Meta>
              </List.Item>
            )}
          />
        </Card>
      </div>
      <div className='settingListWrapper'>
        {/* {state?.selectedKey?.name ==   && <Reports /> }  */}

        {state?.selectedKey?.name == 'Agent Call Summary' && <AgentSummary />}
        {state?.selectedKey?.name == 'Daily Call Summary' && <DailyCallSummary />}
        {state?.selectedKey?.name == 'Recordings' && <Recordings />}
        {state?.selectedKey?.name == 'Scheduled Calls' && <ScheduledCalls />}
        {state?.selectedKey?.name == 'Payment History' && <PreviousPayments />}
        {state?.selectedKey?.name == 'Disposition Report' && <CallerDisposition />}
        {state?.selectedKey?.name == 'Login Logout Report' && <CallerJobTime />}
        {state?.selectedKey?.name == 'Real Time Screen' && <RealTimeScreen />}
        {state?.selectedKey?.name == 'DID CDR' && <DIDCDR />}
        {state?.selectedKey?.name == 'Call History' && defaultPage()}

      </div>
    </div>
  </div>
}
export default DeptList;