import React, { useState, useEffect } from 'react'
import { Button, Typography, Input, Space, notification, Select, Switch } from 'antd';
import './styles.less'
import { useDispatch } from 'react-redux';
import { Form } from 'antd';
import Request from '../../request';
import async from 'async'
import { push } from 'connected-react-router';
import { ArrowRightOutlined, MailOutlined, MobileOutlined, UserOutlined, WalletOutlined, LockOutlined } from '@ant-design/icons';
import _ from 'lodash'
import { isNormalInteger } from '../../utils';
import { ReactComponent as ImageThumb } from '../../assets/LoginPage.svg'

const { Text } = Typography
const { Option } = Select

const Login = () => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [loader, setLoader] = useState(false)
    const [countries, setCountries] = useState([])
    const [login, setLoginRun] = useState(true)
    const onFinish = async (valData) => {
        const { mobile } = valData
        if (!isNormalInteger(mobile) || mobile.length !== 10) {
            notification.error({
                message: "Invalid Mobile Number"
            })
            return;
        }
        setLoader(true)
        let obj = {}
        obj = {
            auth: {
                ...valData
            },
            businessname: valData?.businessname
        }
        let { user, token, success, message, data } = await Request.signup(obj)
        setLoader(false)
        if (success) {
            notification.success({
                message: message
            })
            form.resetFields()
        }
        else {
            notification.error({
                message: message || "Failed",
                // description: JSON.stringify(data)?.replace('[', '')?.replace('{', '')?.replace(']', '')?.replace('}', '')

            })
        }
        setLoader(false)
    }
    const onChange = (value) => {
        form.setFieldsValue({ countrycode: value });
    };
    const getFunction = async () => {
        let { user, token, success, data } = await Request.getCountries()
        if (success) {
            let setOnlyCountries = []
            async.forEach(data, (value, cb) => {
                if (!value?.default_blocked) {
                    setOnlyCountries.push(value)
                }
                cb()
            }, () => {
                setCountries(() => setOnlyCountries)
            })
        } else {
            setCountries(() => [])

        }
    }
    useEffect(() => {
        getFunction()
        form.setFieldsValue({ country: 'IN' });
    }, [])
    const requiredRule = [{ required: true, message: 'Please input field' }]
    const fNameRule = [{ min: 2, message: 'minimum 2 characters' }, { required: true, message: 'Please input field' }]
    const mobileRule = [{ required: true, message: 'Please input field' }]
    const emailRule = [{ type: 'email', message: 'The input is not valid E-mail!' }, { required: true, message: 'Please input field' }]
    const passRule = [{ min: 6, message: 'The input should be minimum 6 characters' }, { required: true, message: 'Please input field' }]



    const prefixSelector = (
        <Form.Item name="country" noStyle={true} >
            <Select defaultValue='IN' style={{ width: '70%' }} className="formFieldUnderline" onChange={onChange}>
                {_.map(countries, data => {
                    return <Option value={data?.countrycode}>{data?.countryname}</Option>
                })}
                {/* <Option value="US">(United States)US</Option> */}
                {/* <Option value="87">+87</Option> */}
            </Select>
        </Form.Item>

    );


    return (
        <div className='signupExpand'>
            <Form className='form' form={form} onFinish={onFinish} size='large' hideRequiredMark={false} layout='vertical'>
                <div className='inputWidth '>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ width: "45%" }}>
                            <Form.Item style={{ marginBottom: '1.45rem' }} name='firstname' rules={fNameRule}>
                                <Input className="formFieldUnderline" prefix={<UserOutlined className="login-signup-icon" />} placeholder='Enter Name' />
                            </Form.Item>
                        </div>
                        <div style={{ width: "50%" }}>

                            <Form.Item style={{ marginBottom: '1.45rem' }} name='lastname' rules={requiredRule}>
                                <Input className="formFieldUnderline" prefix={<UserOutlined className="login-signup-icon" />} placeholder='Last Name' />
                            </Form.Item>
                        </div>

                    </div>
                    <Form.Item style={{ marginBottom: '1.45rem' }} name='businessname' rules={requiredRule}>
                        <Input className="formFieldUnderline" prefix={<WalletOutlined className="login-signup-icon" />} placeholder='Business Name' />
                    </Form.Item>

                    <Form.Item style={{ marginBottom: '1.45rem' }} className="signInMobile" name='mobile' rules={mobileRule}>
                        <Input className="formFieldUnderline" prefix={<MobileOutlined className="login-signup-icon" />} type='number' placeholder='Enter Mobile No.' />
                    </Form.Item>
                    {/* <Form.Item name='initialextension' rules={requiredRule}>
                        <Input placeholder='Enter Initial extension ' />
                    </Form.Item> */}
                    <Form.Item style={{ marginBottom: '1.45rem' }} name='email' rules={emailRule}>
                        <Input className="formFieldUnderline" prefix={<MailOutlined className="login-signup-icon" />} type="email" placeholder='Enter Email ID' />
                    </Form.Item>
                    <Form.Item style={{ marginBottom: '1.45rem' }} name='password' rules={passRule} hasFeedback>
                        <Input className="formFieldUnderline" prefix={<LockOutlined className="login-signup-icon" />} maxLength={16} type="password" placeholder='Enter Password' />
                    </Form.Item>
                </div>
                {prefixSelector}

                <Form.Item style={{ marginBottom: '1.45rem', width: '80%' }}>
                    <Button size='large' loading={loader} htmlType='submit' type='secondary' style={{ backgroundColor: '#EA5B21', color: 'white', marginTop: '2rem', border: 0, borderRadius: '7px' }}>Get Started</Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default Login
