let initialState=[""];

const putTheDept =(state=initialState, action)=>{

    switch(action.type) {

        case "DEPTNAME": return state= action.payload;

        default: return state; 
    }

}
export default putTheDept;