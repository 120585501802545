import { useState, lazy, useEffect } from 'react';
import { Card, Space, Tooltip, Image, Radio, message, Table, Tabs, Button, Input, Tag } from 'antd';
import { useSelector } from 'react-redux';
import './styles.less'
import { AudioOutlined } from '@ant-design/icons';
import { InfoCircleOutlined, UserOutlined, SearchOutlined } from '@ant-design/icons';
import Request from '../../request';
import moment from 'moment'
import FilterTable from '../../components/Table'
import async from 'async'
const { Search } = Input;


const { TabPane } = Tabs;
const data = [
  {
    key: '1',
    name: 'Mike',
    age: 32,
    address: '10 Downing Street',
  },
  {
    key: '2',
    name: 'John',
    age: 42,
    address: '10 Downing Street',
  },
];

const HomeView = (props) => {

  const [state, setState] = useState({
    data: [],
    loading: false,
    hasMore: true,
    orderStatus: 'PENDING',
    showQr: false
  })
  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser
  }))
  const [dataSource, setDataSource] = useState([])
  const [loading, setLoader] = useState(false)


  const suffix = (
    <AudioOutlined
      style={{
        fontSize: 16,
        color: '#1890ff',
      }}
    />
  );

  const onSearch = value => console.log(value);
  useEffect(() => {
    apiFunction()
  }, [])
  const apiFunction = async () => {
    setLoader(true)
    let newData = []
    let { user, error, message, data } = await Request.ListCallBack({ completed: true })
    setLoader(false)
    if (!error && data) {
      async.forEach(data, (val, cb) => {
        let obj = {
          ...val?.caller?.auth,
          ...val,
        }
        newData.push(obj)
        cb()
      }, () => {
        setDataSource(newData)
      })
    }
    else {
      setDataSource(() => [])

    }


  }
  const columns = [
    {
      title: 'Caller',
      dataIndex: 'firstname',
      key: 'firstname',
      // search: true,
      fixed: 'left',
      render: (val, record) => {
        // console.log(val, record, "valandrecord")
        return <>{val} {record.lastname}</>
      }
    },
    {
      title: 'Called Phone',
      dataIndex: 'calledphone',
      key: 'calledphone',
      search: true,
    },
    {
      title: 'Callback Type',
      dataIndex: 'callbacktype',
      key: 'callbacktype',
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
    },
    {
      title: 'Callback Time',
      dataIndex: 'callbacktime',
      key: 'callbacktime',
      // defaultSortOrder: 'descend',
      // sorter: (a, b) => a.createdon - b.createdon,  
      render: (val, r) => {
        return (moment.utc(val).format('DD-MM-YYYY'))
      }
    },
    {
      title: 'Created At',
      dataIndex: 'createdon',
      key: 'createdon',
      // defaultSortOrder: 'descend',
      // sorter: (a, b) => a.createdon - b.createdon,  
      render: (val, r) => {
        return (moment.utc(val).format('DD-MM-YYYY'))
      }
    },


  ];
  return <div>
    <div className="marginTop" style={{ width: '100%' }}>
      <FilterTable bordered dataSource={dataSource} loading={loading} columns={columns} scroll={{ x: 'max-content' }} />

    </div>
  </div>
}

export default HomeView;