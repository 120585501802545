import React, { useState, useEffect } from 'react'
import { Button, Typography, Input, Space, notification, Select, Switch } from 'antd';
import './styles.less'
import { useDispatch } from 'react-redux';
import { Form } from 'antd';
import Request from '../../request';
import { push } from 'connected-react-router';
import { ArrowRightOutlined, UserOutlined } from '@ant-design/icons';
import _ from 'lodash'
import { isNormalInteger } from '../../utils';
import { ReactComponent as ImageThumb } from '../../assets/LoginPage.svg'

const { Text } = Typography
const { Option } = Select

const Login = () => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [loader, setLoader] = useState(false)
    const [countries, setCountries] = useState([])
    const [login, setLoginRun] = useState(true)

    const onFinish = async (valData) => {
        setLoader(true)
        let { user, token, success, message, data } = await Request.changePassword(valData)
        setLoader(false)
        if (success) {
            notification.success({
                message: message,
                description: 'Please Login Again !!'
            })
            dispatch({ type: 'LOGOUT' })
        }
        else {
            notification.error({
                message: message || "Failed",
                description: JSON.stringify(data)?.replace('[', '')?.replace('{', '')?.replace(']', '')?.replace('}', '')

            })
        }
        setLoader(false)
    }

    const requiredRule = [{ required: true, message: 'Please input field' }]


    return (<div>
        <div className="flexbox" >
            <div className="flex" style={{ marginTop: 10, marginBottom: 20, justifyContent: 'center' }}>
                <UserOutlined style={{ fontSize: '50px', color: '#293275' }} />
                <div style={{ marginLeft: 20, fontSize: '30px', color: '#293275' }}>
                    Change Password
                </div>
            </div>
            <Form className='profilePage' form={form} layout='vertical' onFinish={onFinish} hideRequiredMark={false}>
                <div className='inputWidthNew '>
                    <Form.Item
                        name="current_password"
                        label="Current Password"
                        maxLength={16}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        name="new_password"
                        label="New Password"
                        maxLength={16}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                        hasFeedback
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        name="confirm_new_password"
                        label="Confirm Password"
                        maxLength={16}
                        dependencies={['new_password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('new_password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                </div>
                <Form.Item >
                    <Button loading={loader} htmlType='submit' type='primary' style={{ width: 'fit-content', marginTop: '1rem' }}>Change Password</Button>
                </Form.Item>
            </Form>
        </div>
    </div>
    );
}

export default Login
