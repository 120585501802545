import { useState, useEffect } from 'react';
import { Card, Space, Tooltip, Image, Radio, Select, Table, Tabs, Button, Input } from 'antd';
import { useSelector } from 'react-redux';
import './styles.less'
import { AudioOutlined } from '@ant-design/icons';
import { InfoCircleOutlined, UserOutlined, SearchOutlined } from '@ant-design/icons';
import FilterTable from '../../components/Table'
import Request from '../../request'
import async from 'async'
import _ from 'lodash'
import UpgradePlan from '../settings/upgradePlan'

const HomeView = (props) => {

  const [state, setState] = useState({})
  const [callers, setCaller] = useState([])
  const [filters, setFilters] = useState({})

  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser
  }))
  const [dataSource, setDataSource] = useState([])
  const [loading, setLoader] = useState(false)

  const apiFunction = async (filter) => {
    setLoader(true)
    let { user, token, success, message, data } = await Request.getReport(filter)
    setLoader(false)
    let newData = []
    if (success && data.length) {
      async.forEach(data, (val, cb) => {
        let obj = {
          ...val,
          ...val?.caller?.auth,
        }
        newData.push(obj)
        cb()
      }, () => {
        setDataSource(newData)
      })
    } else {
      setDataSource([])
    }
  }
  const getCaller = async () => {
    let { user, token, success, message, data } = await Request.allCaller()
    if (success && data.length) {
      setCaller(data)
    } else {
      setCaller([])
    }
  }
  useEffect(() => {
    getCaller()
    apiFunction()
  }, [])

  const columns = [
    {
      title: 'Caller',
      dataIndex: 'firstname',
      key: 'firstname',
      width: 180,
      fixed: 'left',
      // search: true,
      render: (val, record) => {
        console.log(val, record, "valandrecord")
        return <>{val} {record.lastname}</>
      }
    },
    {
      title: 'Caller Email',
      dataIndex: 'email',
      key: 'email',
      width: 220,

      search: true
    },
    {
      title: 'Calls <30s',
      dataIndex: 'calls_lt_30',
      width: 120,
      key: 'calls_lt_30',
    },
    {
      title: 'Calls >=30s',
      dataIndex: 'calls_gte_30',
      key: 'calls_gte_30',
    },
    {
      title: 'Total Calls',
      dataIndex: 'total',
      key: 'total',

    },
    {
      title: 'Missed Calls',
      dataIndex: 'missed',
      key: 'missed',
    },

    {
      title: 'Outgoing Calls',
      dataIndex: 'outgoing',
      key: 'outgoing',
    },
    {
      title: 'Incoming Calls',
      dataIndex: 'incoming',
      key: 'incoming',

    },
    {
      title: 'Call Duration',
      dataIndex: 'sumcallerduration',
      key: 'sumcallerduration',
      fixed: 'right',
      render: (val, record) => {
        return val ? val : '00:00:00'
      }
    },
  ];
  const downloadXls = async (filters) => {
    setLoader(true)
    let { user, token, success, message, data } = await Request.downloadXlsForAgents(filters)
    setLoader(false)
  }
  if (!currentUser?.plan?.intelligentreporting) {
    return <UpgradePlan />
  }

  return <div>
    <h2>Agent Call Summary</h2>
    <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: '5px' }}>
      <div>
        <Select
          showSearch
          allowClear={true}
          onChange={(val) => setFilters((prev) => ({ ...prev, caller: val }))}
          style={{ width: 200, borderRadius: '4px', marginRight: '10px' }}
          placeholder="Select Caller"
          value={filters?.caller}
          options={_.map(callers, val => { return ({ label: val?.auth?.firstname + " " + val?.auth?.lastname, value: val?.id }) })}
        />
        <Button type="primary" className="buttonPrimaryStyle" loading={loading} onClick={() => apiFunction(filters)}>
          Search
        </Button>
      </div>
      <Button type="secondary" className="buttonPrimaryStyle" loading={loading} onClick={() => downloadXls(filters)}>
        Download .xls/.csv
      </Button>
    </div>
    <div className="marginTop" >
      <FilterTable size="large" bordered dataSource={dataSource} loading={loading} columns={columns} scroll={{ x: 'max-content' }} />
    </div>
  </div>
}

export default HomeView;