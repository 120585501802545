import { useState, lazy, useEffect } from 'react';
import { Card, DatePicker, Tooltip, Image, Select, message, Table, Tabs, Button, Input, Tag, notification } from 'antd';
import { useSelector } from 'react-redux';
import './styles.less'
import { AudioOutlined, DownloadOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { InfoCircleOutlined, UserOutlined, SearchOutlined } from '@ant-design/icons';
import Request from '../../request';
import moment from 'moment'
import _, { filter } from 'lodash'
import Modal from 'antd/lib/modal/Modal';
import async, { auto } from 'async'
import FilterTable from '../../components/Table'
import { AudioDownloadFormat, DateFormat } from '../../settings'
import UpgradePlan from '../settings/upgradePlan'

const { Search } = Input;
const { RangePicker } = DatePicker;

const HomeView = (props) => {

    const [loader, setLoader] = useState(false)
    const [dataSource, setDataSource] = useState([])
    const [number, setNumber] = useState([])
    const [filters, setFilters] = useState({})
    const [pageTotal, setPageTotal] = useState();

    const { currentUser } = useSelector((state) => ({
        currentUser: state.global.currentUser
    }))

    const apiFunction = async () => {
        setLoader(true)
        let { user, token, success, message, data } = await Request.getAllNumbers()
        setLoader(false)
        if (success) {
            setNumber(() => data)
        } else {
            setNumber(() => [])
        }
    }

    const filterFunction = async (filter) => {
        console.log(filter, typeof filter)
        if (filter != undefined && typeof filter != 'object') {
            if (filter?.createdon == null) {
                filter = undefined
            }
        }
        setLoader(true)
        let { user, token, success, message, data, total } = await Request.getDidCdr(filter)
        setLoader(false)
        let newData = []
        if (success && data.length) {
            async.forEach(data, (val, cb) => {
                let obj = {
                    ...val,
                }
                newData.push(obj)
                cb()
            }, () => {
                setDataSource(newData)
                setPageTotal(total)
            })
        } else {
            setDataSource([])
        }
    }

    useEffect(() => {
        apiFunction()
    }, [])

    const downloadXls = async (filters) => {
        setLoader(true)
        let { user, token, success, message, data } = await Request.downloadXlsForDidCdr(filters)
        setLoader(false)
    }

    const columns = [
        {
            title: 'DID Number',
            dataIndex: 'did_number',
            key: 'did_number',
        },
        {
            title: 'Caller',
            dataIndex: 'src_number',
            key: 'src_number',
        },
        {
            title: 'Call Start Time',
            dataIndex: 'time_start',
            key: 'time_start',
            render: (val, r) => {
                return <>{moment.utc(val).format("YYYY-MM-DD HH:mm:ss")}</>
            }
        },
        {
            title: 'Call End Time',
            dataIndex: 'time_end',
            key: 'time_end',
            render: (val, r) => {
                return <>{moment.utc(val).utc().format('YYYY-MM-DD HH:mm:ss')}</>
            }
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
        },
        {
            title: 'Connection Time',
            dataIndex: 'time_connect',
            key: 'time_connect',
            render: (val, r) => {
                return <>{(val) && moment.utc(val).utc().format('YYYY-MM-DD HH:mm:ss')}</>
            }
        },
        {
            title: 'Disconnect Initiator',
            dataIndex: 'disconnect_initiator',
            key: 'disconnect_initiator',
        },
        {
            title: 'Disconnection Code',
            dataIndex: 'internal_disconnect_code',
            key: 'internal_disconnect_code',
        },
        {
            title: 'Response',
            dataIndex: 'legb_disconnect_reason',
            key: 'legb_disconnect_reason',
        },
        {
            title: 'Device',
            dataIndex: 'user_agent',
            key: 'user_agent',
            render: (val, r) => {
                return <>{r.user_agent} / {r.trunk_destination}</>
            }
        },
    ];
    if (!currentUser?.plan?.planname) {
        return <UpgradePlan />
    }

    return <div>
        <h2>DID CDR</h2>
        <div style={{ gridGap: '5px', flexWrap: 'wrap', display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                <div className="marginRight">
                    <RangePicker onChange={(v) => setFilters((prev) => ({ ...prev, createdon: v }))} value={filters?.createdon} />
                </div>
                <div className="marginRight">
                    <Select
                        showSearch
                        allowClear={true}
                        onChange={(val) => setFilters((prev) => ({ ...prev, did_number: val }))}
                        style={{ width: 200, border: '1px solid #e1e3e8', borderRadius: '4px' }}
                        placeholder="Select DID Number"
                        value={filters?.did_number}
                        options={_.map(number, val => { return ({ label: (val.phonecode + val.phonenumber), value: (val.phonecode + val.phonenumber) }) })}

                    />
                </div>
                <Button type="primary" className="buttonPrimaryStyle" loading={loader} onClick={() => filterFunction(filters)}>
                    Search
                </Button>
            </div>
            <Button type="secondary" className="buttonPrimaryStyle" loading={loader} onClick={() => downloadXls(filters)}>
                Download .xls/.csv
            </Button>
        </div>
        <div className="marginTop" style={{ width: '100%' }}>
            <FilterTable pagination={{
                onChange: page => {
                    apiFunction({ ...filters, page: page, })
                },
                pageSize: 20,
                total: pageTotal,
                hideOnSinglePage: true,
                showSizeChanger: false
            }}
                bordered dataSource={dataSource} columns={columns} loading={loader} scroll={{ x: 'max-content' }} />
        </div>

    </div>
}

export default HomeView;
